/* eslint-disable no-useless-escape */
import { defineMessages } from 'react-intl';
export default defineMessages({
    TRUE: {
        id: 'true',
        defaultMessage: 'True',
        description: '',
    },
    FALSE: {
        id: 'false',
        defaultMessage: 'False',
        description: '',
    },
    WARNING: {
        id: 'warning',
        defaultMessage: 'Warning',
        description: '',
    },
    WARNINGS: {
        id: 'warnings',
        defaultMessage: 'Warnings',
        description: '',
    },
    ALL_COUNT: {
        id: 'all.count',
        defaultMessage: 'All ({count})',
        description: '',
    },
    DEVICE_TYPE: {
        id: 'device_type',
        defaultMessage: 'Device type',
        description: '',
    },
    MOBILE_APP: {
        id: 'mobile_app',
        defaultMessage: 'Mobile App',
        description: '',
    },
    DESKTOP_WEB: {
        id: 'desktop_web',
        defaultMessage: 'Desktop Web',
        description: '',
    },
    MOBILE_WEB: {
        id: 'mobile_web',
        defaultMessage: 'Mobile Web',
        description: '',
    },
    PUBLISHED_COUNT: {
        id: 'published.count',
        defaultMessage: 'Published ({count})',
        description: '',
    },
    CONTROLS_REMAINING: {
        id: 'controls.remaining',
        defaultMessage: '{remainingControls} (left)',
        description: '',
    },
    SCHEDULED_COUNT: {
        id: 'scheduled.count',
        defaultMessage: 'Scheduled ({count})',
        description: '',
    },
    EXPIRED_COUNT: {
        id: 'expired.count',
        defaultMessage: 'Expired ({count})',
        description: '',
    },
    ANALYTICS: {
        id: 'analytics',
        defaultMessage: 'Analytics',
        description: '',
    },
    AUDIT_LOG: {
        id: 'audit_log',
        defaultMessage: 'Audit Log',
        description: '',
    },
    AUDIT_LOG_DESCRIPTION: {
        id: 'audit_log.description',
        defaultMessage: 'View activity across your organization\'s areas',
        description: '',
    },
    AUDIT_EVENTS: {
        id: 'audit_events',
        defaultMessage: 'Audit Events',
        description: '',
    },
    RELEASE_NOTES_SEARCH: {
        id: 'release_notes_search',
        defaultMessage: 'Search',
        description: '',
    },
    RELEASE_NOTES: {
        id: 'release_notes',
        defaultMessage: 'Release Notes',
        description: '',
    },
    RELEASE_NOTES_DESCRIPTION: {
        id: 'release_notes.description',
        description: '',
        defaultMessage: 'What\'s New? See the latest Command Center feature releases, product improvements and bug fixes.',
    },
    RELEASE_NOTES_UPLOAD: {
        id: 'release_notes.upload',
        description: '',
        defaultMessage: 'Upload',
    },
    HOME: {
        id: 'home',
        defaultMessage: 'Home',
        description: 'Home link',
    },
    WELCOME_BACK: {
        id: 'welcome_back',
        description: '',
        defaultMessage: 'Welcome Back!',
    },
    TERM: {
        id: 'term',
        defaultMessage: 'Term',
        description: '',
    },
    SYNONYMS: {
        id: 'synonyms',
        defaultMessage: 'Synonyms',
        description: '',
    },
    REPLACEMENT_TERM: {
        id: 'replacement_term',
        description: '',
        defaultMessage: 'Replacement Term',
    },
    NEW_VERSION_REFRESH_PAGE: {
        id: 'new_version_refresh_page',
        description: '',
        defaultMessage: 'A new version of Command Center is now available. Refresh the page to continue.',
    },
    DO_NOT_ASSOCIATE_TERM: {
        id: 'do_not_associate.term',
        description: '',
        defaultMessage: 'Do Not Associate Term',
    },
    NEW: {
        id: 'new',
        description: '',
        defaultMessage: 'New',
    },
    UPLOAD: {
        id: 'upload',
        description: '',
        defaultMessage: 'Upload',
    },
    EDIT: {
        id: 'edit',
        description: '',
        defaultMessage: 'Edit',
    },
    CONFIRMATION: {
        id: 'confirmation',
        description: '',
        defaultMessage: 'Confirmation',
    },
    ERROR: {
        id: 'error',
        description: '',
        defaultMessage: 'Error',
    },
    CONFIRM: {
        id: 'confirm',
        description: '',
        defaultMessage: 'Confirm',
    },
    PREVIOUS: {
        id: 'previous',
        description: '',
        defaultMessage: 'Previous',
    },
    NOT_IN_USE: {
        id: 'not.in.use',
        description: '',
        defaultMessage: 'Not In Use',
    },
    NEXT: {
        id: 'next',
        description: '',
        defaultMessage: 'Next',
    },
    NO_DATA_AVAILABLE: {
        id: 'no_data_available',
        description: '',
        defaultMessage: 'No data available',
    },
    NO_DATA_AVAILABLE_SHORT: {
        id: 'no_data_available_short',
        description: '',
        defaultMessage: 'N/A',
    },
    NO_RESULTS_FOUND: {
        id: 'no_results_found',
        description: '',
        defaultMessage: 'No results found',
    },
    NO_ANALYTICS_DATA_AVAILABLE: {
        id: 'no_analytics_data_available',
        description: '',
        defaultMessage: '<b>Sorry!</b><b>We were unable to load your analytics, please try refreshing your browser.</b>If that does not work, please contact us.',
    },
    PREVIEW_PANEL_SELECT_COLLECTION: {
        id: 'preview.panel_select_collection',
        description: '',
        defaultMessage: 'Please set up preview by selecting a collection',
    },
    RECOMMENDATIONS_PREVIEW_FALLBACK_TEXT: {
        id: 'recommendations.preview.fallback.text',
        description: '',
        defaultMessage: 'Please choose a Recommendation to start.',
    },
    PREVIEW: {
        id: 'preview',
        description: '',
        defaultMessage: 'Preview',
    },
    NUM_OF_PRODUCTS_SHOWN: {
        id: 'number.of.products.shown',
        description: '',
        defaultMessage: 'Number of products shown',
    },
    SHOW_PREVIEW: {
        id: 'preview_panel.show',
        description: '',
        defaultMessage: 'Show Preview',
    },
    HIDE_PREVIEW: {
        id: 'preview_panel.hide',
        description: '',
        defaultMessage: 'Hide Preview',
    },
    HIDE: {
        id: 'hide',
        description: '',
        defaultMessage: 'Hide',
    },
    PREVIEW_PANEL_DESCRIPTION_BIAS: {
        id: 'preview_panel.description_bias',
        description: '',
        defaultMessage: 'This preview panel is reflecting the defined bias you\'\'ve set. You can change the collection to see how your results may differ but it won\'\'t change the global collection.',
    },
    PREVIEW_PANEL_DESCRIPTION_RULE: {
        id: 'preview_panel.description_rule',
        description: '',
        defaultMessage: 'This preview panel is reflecting the defined rule you\'\'ve set. You can change the collection to see how your results may differ but it won\'\'t change the global collection.',
    },
    DEFINE_RANGE: {
        id: 'define_range',
        description: '',
        defaultMessage: 'Define Range',
    },
    HOME_WELCOME: {
        id: 'home.welcome',
        description: '',
        defaultMessage: 'Welcome to the new version of Command Center!',
    },
    DIALOG_CAUTION: {
        id: 'dialog.caution',
        defaultMessage: 'Please proceed with caution. You cannot undo this action.',
    },
    COMMAND_CENTER: {
        id: 'command_center',
        description: '',
        defaultMessage: 'Command Center',
    },
    DEPRECIATION_WARNING: {
        id: 'home.deprecation',
        description: '',
        defaultMessage: 'The old version of Command Center has been sunset at this point.',
    },
    FEEDBACK: {
        id: 'home.feedback',
        description: '',
        defaultMessage: 'Any feedback is welcome - just shoot us an email!',
    },
    IMPORTANT: {
        id: 'important',
        description: '',
        defaultMessage: 'Important',
    },
    CHANGE_NOTICE: {
        id: 'home.changesnotice',
        description: '',
        defaultMessage: 'All changes made here will be applied to your site.',
    },
    SORT: {
        id: 'sort',
        description: '',
        defaultMessage: 'Sort',
    },
    FILTER: {
        id: 'filter',
        description: '',
        defaultMessage: 'Filter',
    },
    DOWNLOAD: {
        id: 'download',
        description: '',
        defaultMessage: 'Download',
    },
    COPY_TO_OTHER_AREAS: {
        id: 'copy_to_other_areas',
        description: '',
        defaultMessage: 'Copyable to other areas',
    },
    FILTERS: {
        id: 'filters',
        description: '',
        defaultMessage: 'Filters',
    },
    SORT_ORDER: {
        id: 'sort_order',
        description: '',
        defaultMessage: 'Sort Order',
    },
    SEARCH_REVENUE: {
        id: 'search_revenue',
        description: '',
        defaultMessage: 'Search Revenue',
    },
    SEARCH_ANALYTICS: {
        id: 'search_analytics',
        description: '',
        defaultMessage: 'Search Analytics',
    },
    SEARCH_ATTRIBUTES: {
        id: 'search.attributes',
        description: '',
        defaultMessage: 'Search Attributes',
    },
    SEARCH_PERFORMANCE: {
        id: 'search_performance',
        description: '',
        defaultMessage: 'Search Performance',
    },
    SEARCH_PERFORMANCE_DESCRIPTION: {
        id: 'search_performance.description',
        description: '',
        defaultMessage: '[To be implemented]',
    },
    SEARCH_TERM_ANALYTICS: {
        id: 'search_term_analytics',
        description: '',
        defaultMessage: 'Search Term Analytics',
    },
    NAVIGATION_ENGAGEMENTS: {
        id: 'navigation_engagements',
        description: '',
        defaultMessage: 'Nav Engagements',
    },
    SEARCH_TERM_ANALYSIS: {
        id: 'search_term_analysis',
        description: '',
        defaultMessage: 'Search Term Analysis',
    },
    SEARCH_DATA_QUALITY: {
        id: 'search_data_quality',
        description: '',
        defaultMessage: 'Search Data Quality',
    },
    PIPELINE_HEALTH: {
        id: 'pipeline_health',
        description: '',
        defaultMessage: 'Pipeline Health',
    },
    PIPELINE_HEALTH_TOOLTIP_WEBSITE: {
        id: 'pipeline_health_tooltip_website',
        description: '',
        defaultMessage: 'From website/app',
    },
    PIPELINE_HEALTH_TOOLTIP_GOOGLE: {
        id: 'pipeline_health_tooltip_google',
        description: '',
        defaultMessage: 'To Google',
    },
    PIPELINE_HEALTH_TOOLTIP_WEBSITE_TEXT: {
        id: 'pipeline_health_tooltip_website_text',
        description: '',
        defaultMessage: 'Client data will feed into Groupby',
    },
    PIPELINE_HEALTH_TOOLTIP_GOOGLE_TEXT: {
        id: 'pipeline_health_tooltip_google_text',
        description: '',
        defaultMessage: 'GroupBy data will feed into Google',
    },
    SEARCH_DATA_QUALITY_DESCRIPTION: {
        id: 'search_data_quality.description',
        description: '',
        defaultMessage: 'Each of these tiers comes with distinct catalog data and event prerequisites that must be met. Offering our clients the ability to determine their current tier and providing guidelines to advance to the next tier.',
    },
    TOP_REFINEMENTS: {
        id: 'top_refinements',
        description: '',
        defaultMessage: 'Top Refinements',
    },
    MONITORING: {
        id: 'monitoring',
        description: '',
        defaultMessage: 'Monitoring',
    },
    API_RESPONSE_TIME: {
        id: 'api_response_time',
        description: '',
        defaultMessage: 'API Response Time',
    },
    CONTRACT_METRICS: {
        id: 'contract_metrics',
        description: '',
        defaultMessage: 'Contract Metrics',
    },
    API_USAGE: {
        id: 'api_usage',
        description: '',
        defaultMessage: 'API Usage',
    },
    RECORD_COUNT: {
        id: 'record_count',
        description: '',
        defaultMessage: 'Record Count',
    },
    RULES: {
        id: 'rules',
        description: '',
        defaultMessage: 'Rules',
    },
    EVALUATION_TOOL: {
        id: 'evaluation_tool',
        description: '',
        defaultMessage: 'Evaluation Tool',
    },
    EVALUATE_DESCRIPTION: {
        id: 'evaluate_description',
        description: '',
        defaultMessage: 'You can understand and evaluate your store and products here. You can preview products in their default order, lookup results based on the search query and filters, and understand what configurations, controls, and influencing factors (boost and bias) are affecting their ranking.',
    },
    EVALUATE_FACET_NAV: {
        id: 'evaluate_facet_nav',
        description: '',
        defaultMessage: 'Facet Nav',
    },
    REFAPP_SETTINGS_TITLE: {
        id: 'refapp_settings.title',
        description: '',
        defaultMessage: 'Configuration Applied',
    },
    LOAD_CONFIGURATIONS_ERROR: {
        id: 'load_configurations_error',
        description: '',
        defaultMessage: 'Failed to load configurations',
    },
    RULE_FIRED: {
        id: 'rule_fired',
        description: '',
        defaultMessage: 'Rule Fired',
    },
    NO_RULE: {
        id: 'no_rule',
        description: '',
        defaultMessage: 'No rule',
    },
    SETTINGS: {
        id: 'settings',
        description: '',
        defaultMessage: 'Settings',
    },
    SETTINGS_TOOLTIP: {
        id: 'settings_tooltip',
        description: '',
        defaultMessage: 'Visitor IDs allow you to see the results as they would be personalized for that particular user. To get visitor ID contact your TC.',
    },
    VISITOR_ID: {
        id: 'visitor_id',
        description: '',
        defaultMessage: 'Visitor id',
    },
    VISITOR_ID_PLACEHOLDER: {
        id: 'visitor_id_placeholder',
        description: '',
        defaultMessage: 'Enter Visitor id (optional)',
    },
    CLEAR: {
        id: 'clear',
        description: '',
        defaultMessage: 'Clear',
    },
    SITE_FILTER_APPLIED: {
        id: 'site_filter_applied',
        description: '',
        defaultMessage: 'Site Filter Applied',
    },
    NO_SITE_FILTER: {
        id: 'no_site_filter',
        description: '',
        defaultMessage: 'No site filter',
    },
    BIASING_APPLIED: {
        id: 'biasing_applied',
        description: '',
        defaultMessage: 'Biasing Applied',
    },
    NO_BIASING: {
        id: 'no_biasing',
        description: '',
        defaultMessage: 'No biasing',
    },
    AB_TEST_TRIGGERED_TITLE: {
        id: 'ab_test_triggered.title',
        description: '',
        defaultMessage: 'AB test triggered',
    },
    AB_TEST_TITLE: {
        id: 'ab_test.title',
        description: '',
        defaultMessage: 'AB test',
    },
    NO_AB_TEST_TRIGGERED: {
        id: 'no_ab_test_triggered',
        description: '',
        defaultMessage: 'No AB test',
    },
    SEARCH_CONTAINS: {
        id: 'search_contains',
        description: '',
        defaultMessage: 'Search Contains',
    },
    SEARCH_STARTS_WITH: {
        id: 'search_starts_with',
        description: '',
        defaultMessage: 'Search Starts With',
    },
    SEARCH_ENDS_WITH: {
        id: 'search_ends_with',
        description: '',
        defaultMessage: 'Search Ends With',
    },
    SEARCH_REGEX: {
        id: 'search_regex',
        description: '',
        defaultMessage: 'Search Regex',
    },
    SEARCH_MATCHES: {
        id: 'search_matches',
        description: '',
        defaultMessage: 'Search Matches',
    },
    BOTH: {
        id: 'both',
        description: '',
        defaultMessage: 'BOTH',
    },
    PRODUCT_ID: {
        id: 'product_id',
        description: '',
        defaultMessage: 'Product ID',
    },
    SEARCH_TERM: {
        id: 'search_term',
        description: '',
        defaultMessage: 'SEARCH TERM',
    },
    NAVIGATION_VALUE: {
        id: 'navigation_value',
        description: '',
        defaultMessage: 'Navigation Value',
    },
    NAVIGATION_RANGE: {
        id: 'navigation_range',
        description: '',
        defaultMessage: 'Navigation Range',
    },
    NAVIGATION_SELECTED: {
        id: 'navigation_selected',
        description: '',
        defaultMessage: 'Navigation Selected',
    },
    CUSTOM_PARAMETER: {
        id: 'custom_parameter',
        description: '',
        defaultMessage: 'Custom Parameter',
    },
    EVALUATE_TAB_PREVIEW: {
        id: 'evaluate_tab_preview',
        description: '',
        defaultMessage: 'Preview',
    },
    EVALUATE_TAB_REQUEST: {
        id: 'evaluate_tab_request',
        description: '',
        defaultMessage: 'Request',
    },
    EVALUATE_TAB_RESPONSE: {
        id: 'evaluate_tab_response',
        description: '',
        defaultMessage: 'Response',
    },
    EVALUATE_COPY_RESPONSE: {
        id: 'evaluate_copy_response',
        description: '',
        defaultMessage: 'Copy response',
    },
    EVALUATE_RESPONSE_SIZE: {
        id: 'evaluate_response_size',
        description: '',
        defaultMessage: 'JSON Response Size ({bytes} bytes)',
    },
    EVALUATE_COMPLETED_TIME: {
        id: 'evaluate_completed_time',
        description: '',
        defaultMessage: 'Query completed in {completedTime} ms',
    },
    EVALUATE_REQUEST_TITLE_CURL: {
        id: 'evaluate_request_title_curl',
        description: '',
        defaultMessage: 'curl',
    },
    EVALUATE_REQUEST_COPY_CURL: {
        id: 'evaluate_request_copy_curl',
        description: '',
        defaultMessage: 'Copy curl',
    },
    EVALUATE_REQUEST_DESCRIPTION_CURL: {
        id: 'evaluate_request_description_curl',
        description: '',
        defaultMessage: 'The curl is generally used to:',
    },
    EVALUATE_REQUEST_DESCRIPTION_CURL_REPLICATE: {
        id: 'evaluate_request_description_curl_replicate',
        description: '',
        defaultMessage: 'Replicate the request/process it on another tool for further troubleshooting.',
    },
    EVALUATE_REQUEST_DESCRIPTION_CURL_SEND: {
        id: 'evaluate_request_description_curl_send',
        description: '',
        defaultMessage: 'Send the curl to another user. For example, to a TC, who will use it to troubleshoot for the same query on their end.',
    },
    EVALUATE_REQUEST_TITLE_REQUEST: {
        id: 'evaluate_request_title_request',
        description: '',
        defaultMessage: 'Request',
    },
    EVALUATE_REQUEST_COPY_REQUEST_BODY: {
        id: 'evaluate_request_copy_request_body',
        description: '',
        defaultMessage: 'Copy Request Body',
    },
    EVALUATE_REQUEST_DESCRIPTION_REQUEST: {
        id: 'evaluate_request_description_request',
        description: '',
        defaultMessage: 'The request will show the key information the user needs to understand, primarily, the current page information in terms of what is being sent. For example, the included information would show:',
    },
    EVALUATE_REQUEST_DESCRIPTION_REQUEST_RULES: {
        id: 'evaluate_request_description_request_rules',
        description: '',
        defaultMessage: 'If any rules and configurations are applying and being triggered.',
    },
    EVALUATE_REQUEST_DESCRIPTION_REQUEST_TERM: {
        id: 'evaluate_request_description_request_term',
        description: '',
        defaultMessage: 'What the search term is.',
    },
    EVALUATE_REQUEST_DESCRIPTION_REQUEST_NAVIGATIONS: {
        id: 'evaluate_request_description_request_navigations',
        description: '',
        defaultMessage: 'What navigations are being applied.',
    },
    EVALUATE_COPY_SUCCESS: {
        id: 'evaluate_copy_success',
        description: '',
        defaultMessage: 'Data copied to clipboard',
    },
    EVALUATE_COPY_ERROR: {
        id: 'evaluate_copy_error',
        description: '',
        defaultMessage: 'Failed to copy data to clipboard',
    },
    EVALUATE_PARSE_JSON_ERROR: {
        id: 'evaluate_parse_json_error',
        description: '',
        defaultMessage: 'Error, can\'t parse JSON data',
    },
    ZONE: {
        id: 'zone',
        description: '',
        defaultMessage: 'Zone',
    },
    ZONES: {
        id: 'zones',
        description: '',
        defaultMessage: 'Zones',
    },
    ZONES_TO_SECTIONS: {
        id: 'rule_form.zones_to_sections',
        description: '',
        defaultMessage: 'Add Zones to Sections',
    },
    ZONE_NEW: {
        id: 'zone.new',
        description: '',
        defaultMessage: 'New Zone',
    },
    ZONE_INFO: {
        id: 'zone.info',
        description: '',
        defaultMessage: 'Zone Info',
    },
    ZONE_ADD: {
        id: 'zone.add.new',
        description: '',
        defaultMessage: 'Add New Zone',
    },
    ZONES_NUMSELECTED: {
        id: 'zones.numselected',
        description: '',
        defaultMessage: '{numSelected} Zones Selected',
    },
    ZONE_CREATE: {
        id: 'zone.create',
        description: '',
        defaultMessage: 'Create Zone',
    },
    ZONE_EDIT: {
        id: 'zone.edit',
        description: '',
        defaultMessage: 'Edit Zone',
    },
    ZONE_DELETE: {
        id: 'zone.delete',
        description: '',
        defaultMessage: 'Delete Zone',
    },
    ZONE_FIELDS_CUSTOM_VALUE_LABEL: {
        id: 'zone.fields.customValue.label',
        description: '',
        defaultMessage: 'Custom Value (required)',
    },
    ZONE_SECTION_FIELDS_TYPE_LABEL: {
        id: 'zone_section.fields.type.label',
        description: '',
        defaultMessage: 'Zone Type (required)',
    },
    ZONE_SECTION_FIELDS_WRAPPER_LABEL: {
        id: 'zone_section.fields.wrapper.label',
        description: '',
        defaultMessage: 'Select a Preview Wrapper (optional)',
    },
    ZONE_SECTION_FIELDS_NO_WRAPPER_SELECTED: {
        id: 'zone_section.fields.no_wrapper_selected',
        description: '',
        defaultMessage: 'No Wrapper Selected',
    },
    ZONE_FIELDS_TYPE_OPTION_CONTENT: {
        id: 'zone.fields.type.options.content',
        description: '',
        defaultMessage: 'Content',
    },
    ZONE_FIELDS_TYPE_CHOOSE_LABEL: {
        id: 'zone.fields.type.choose.label',
        description: '',
        defaultMessage: 'Choose zone from the list or create new custom one.',
    },
    ZONE_FIELDS_TYPE_FROM_LIST: {
        id: 'zone.fields.type.from_list',
        description: '',
        defaultMessage: 'Select zone from list',
    },
    ZONE_FIELDS_TYPE_CUSTOM_ZONE: {
        id: 'zone.fields.type.custom_zone',
        description: '',
        defaultMessage: 'Create custom zone',
    },
    CLEAR_CUSTOM_ZONE_VALUE: {
        id: 'zone.fields.zone_content.label',
        description: '',
        defaultMessage: 'Clear custom zone',
    },
    REMOVE_SELECTED_ZONE: {
        id: 'zone.fields.zone_content.remove_selected_zone',
        description: '',
        defaultMessage: 'Remove selected zone',
    },
    SITES: {
        id: 'sites',
        description: '',
        defaultMessage: 'Sites',
    },
    SITE_MANAGEMENT: {
        id: 'site_management',
        description: '',
        defaultMessage: 'Site Management',
    },
    SITE_DESCRIPTION: {
        id: 'site.description',
        description: '',
        defaultMessage: 'Sites define a group of products, which can be the entire or filtered version of the catalog.',
    },
    SITE_CREATE: {
        id: 'site.create',
        description: '',
        defaultMessage: 'Create Site',
    },
    SITE_EDIT: {
        id: 'site.edit',
        description: '',
        defaultMessage: 'Edit Site',
    },
    SITE_DELETE: {
        id: 'site.delete',
        description: '',
        defaultMessage: 'Delete Site',
    },
    SITE_FILTER_NO_MATCHING: {
        id: 'site.filter.no_matching',
        description: '',
        defaultMessage: 'There are no matching site filters.',
    },
    SITE_FILTER_NO_DATA: {
        id: 'site.filter.no_data',
        description: '',
        defaultMessage: 'There are no site filters at the moment. Create a filter below.',
    },
    SITE_COLUMN_NAME: {
        id: 'site.column.name',
        description: '',
        defaultMessage: 'Site Name',
    },
    SITE_COLUMN_VALUE: {
        id: 'site.column.value',
        description: '',
        defaultMessage: 'Filter',
    },
    SITE_FORM_FIELD_NAME: {
        id: 'site.form.field_name',
        description: '',
        defaultMessage: 'Site Name',
    },
    SITE_FORM_FIELD_NAME_DESCRIPTION: {
        id: 'site.form.field_name_description',
        description: '',
        defaultMessage: 'The site name must exactly match the site filter from your internal beacon.',
    },
    SITE_FORM_FIELD_NAME_CREATE_LABEL: {
        id: 'site.form.field_name_create_label',
        description: '',
        defaultMessage: 'Create a site name',
    },
    SITE_FORM_FIELD_NAME_EDIT_LABEL: {
        id: 'site.form.field_name_edit_label',
        description: '',
        defaultMessage: 'Edit a site name',
    },
    SITE_FORM_FIELD_FILTERS: {
        id: 'site.form.field_filters',
        description: '',
        defaultMessage: 'Site Filters',
    },
    SITE_FORM_FIELD_FILTERS_VISUAL_SUBTEXT: {
        id: 'site.form.field_filters_visual_subtext',
        description: '',
        defaultMessage: 'Any description for the site filters.',
    },
    SITE_FORM_FIELD_FILTERS_QUERY_SUBTEXT: {
        id: 'site.form.field_filters_query_subtext',
        description: '',
        defaultMessage: 'Write out the attributes that will comprise of your prefilter.',
    },
    SITE_FORM_FIELD_FILTERS_EMPTY: {
        id: 'site.form.field_filters_empty',
        description: '',
        defaultMessage: 'No filters added yet',
    },
    SITE_FORM_FIELD_FILTERS_DEFAULT_VALUE: {
        id: 'site.form.field_filters_default_value',
        description: '',
        defaultMessage: 'Write query to add filter...',
    },
    SITE_FORM_BUTTON_CLEAR_ALL: {
        id: 'site.form.button_clear_all',
        description: '',
        defaultMessage: 'Clear All',
    },
    SITE_FORM_BUTTON_NEW_FILTER: {
        id: 'site.form.button_new_filter',
        description: '',
        defaultMessage: 'New Filter',
    },
    SITE_FORM_TOGGLE_QUERY: {
        id: 'site.form.toggle_query',
        description: '',
        defaultMessage: 'Query',
    },
    SITE_FORM_TOGGLE_VISUAL: {
        id: 'site.form.toggle_visual',
        description: '',
        defaultMessage: 'Visual',
    },
    TASK_TEMPLATE_LOADONE_ERROR: {
        id: 'task.template.loadone.error',
        description: '',
        defaultMessage: 'Task "Load template" failed',
    },
    TASK_TEMPLATE_BULK_REMOVE_SUCCESS: {
        id: 'task.template.bulk.remove.success',
        description: '',
        defaultMessage: 'Task "Remove templates" completed!',
    },
    TASK_TEMPLATE_BULK_REMOVE_ERROR: {
        id: 'task.template.bulk.remove.error',
        description: '',
        defaultMessage: 'Task "Remove templates" failed',
    },
    TASK_TEMPLATE_REMOVE_SUCCESS: {
        id: 'task.template.remove.success',
        description: '',
        defaultMessage: 'Task "Remove template {modelName}" completed!',
    },
    TASK_TEMPLATE_REMOVE_ERROR: {
        id: 'task.template.remove.error',
        description: '',
        defaultMessage: 'Task "Remove template {modelName}" failed',
    },
    TASK_TEMPLATE_UPDATE_SUCCESS: {
        id: 'task.template.update.success',
        description: '',
        defaultMessage: 'Task "Update template" completed!',
    },
    TASK_TEMPLATE_UPDATE_ERROR: {
        id: 'task.template.update.error',
        description: '',
        defaultMessage: 'Task "Update template" failed',
    },
    TASK_TEMPLATE_CREATE_SUCCESS: {
        id: 'task.template.create.success',
        description: '',
        defaultMessage: 'Task "Create template" completed!',
    },
    TASK_TEMPLATE_UPLOAD_IMAGE_SUCCESS: {
        id: 'task.template.upload.image.success',
        description: '',
        defaultMessage: 'Image successfully uploaded',
    },
    TASK_TEMPLATE_UPLOAD_IMAGE_ERROR: {
        id: 'task.template.upload.image.error',
        description: '',
        defaultMessage: 'Task "Upload image" failed',
    },
    TASK_TEMPLATE_DELETE_IMAGE_ERROR: {
        id: 'task.template.delete.image.error',
        description: '',
        defaultMessage: 'Task "Delete image" failed',
    },
    TASK_TEMPLATE_DELETE_IMAGE_EXISTS_IN_TEMPLATE_ERROR: {
        id: 'task.template.delete.image.exists.in.template.error',
        description: '',
        defaultMessage: 'Task "Delete image" failed: image exists in another template',
    },
    TASK_TEMPLATE_DELETE_IMAGE_SUCCESS: {
        id: 'task.template.delete.image.success',
        description: '',
        defaultMessage: 'Task "Delete image" completed!',
    },
    TASK_TEMPLATE_CREATE_MISSING_THUMBNAIL_ERROR: {
        id: 'task.template.create.missing.thumbnail.error',
        description: '',
        defaultMessage: 'Selected thumbnail image required',
    },
    TASK_TEMPLATE_UPLOAD_IMAGE_SIZE_ERROR: {
        id: 'task.template.upload.image.size.error',
        description: '',
        defaultMessage: 'Task "Upload image" failed: file size must be 1MB or less',
    },
    TASK_TEMPLATE_CREATE_ERROR: {
        id: 'task.template.create.error',
        description: '',
        defaultMessage: 'Task "Create template" failed',
    },
    TEMPLATE_EDIT: {
        id: 'template.edit',
        description: '',
        defaultMessage: 'Edit Template',
    },
    TEMPLATE_CREATE: {
        id: 'template.create',
        description: '',
        defaultMessage: 'Create Template',
    },
    TEMPLATE_FIELDS_THUMBNAIL_LABEL: {
        id: 'template.fields.thumbnail.label',
        description: '',
        defaultMessage: 'Thumbnail',
    },
    TEMPLATE_FIELDS_UPLOAD_IMAGE: {
        id: 'template.fields.upload.image',
        description: '',
        defaultMessage: 'Upload a new image',
    },
    TEMPLATE_FIELDS_FILE_SIZE_LIMIT: {
        id: 'template.fields.file.size.limit',
        description: '',
        defaultMessage: 'Maximum file size: 1MB',
    },
    TEMPLATE_FIELDS_THUMBNAIL_HEADING: {
        id: 'template.fields.thumbnail.heading',
        description: '',
        defaultMessage: 'Add Thumbnail',
    },
    TEMPLATE_FIELDS_ZONE_SECTIONS_LABEL: {
        id: 'template.fields.zone_sections.label',
        description: '',
        defaultMessage: 'Sections',
    },
    TEMPLATE_FIELDS_ZONE_SECTIONS_SUBLABEL: {
        id: 'template.fields.zone_sections.sublabel',
        description: '',
        defaultMessage: 'Use sections to add zones to your template when rules conditions are met.',
    },
    TEMPLATE_BUTTON_ADD_SECTION: {
        id: 'template.fields.section.add',
        description: '',
        defaultMessage: 'Add section',
    },
    TEMPLATE_NAME_FIELD_REQUIRED: {
        id: 'template.fields.zone_section.add',
        description: '',
        defaultMessage: 'Section Name (required)',
    },
    TEMPLATE_IMAGE_DISPLAY_HEADING: {
        id: 'template.image.display.heading',
        description: '',
        defaultMessage: 'Or choose a new image from the list',
    },
    TEMPLATE_IMAGE_SEARCH_PLACEHOLDER: {
        id: 'template.image.search.placeholder',
        description: '',
        defaultMessage: 'Image name',
    },
    TEMPLATE_SEARCH_PLACEHOLDER: {
        id: 'template.search.placeholder',
        description: '',
        defaultMessage: 'Template name',
    },
    ZONE_FIELDS_TYPE_OPTION_RICH_CONTENT: {
        id: 'zone.fields.type.options.richContent',
        description: '',
        defaultMessage: 'Rich Content',
    },
    ZONE_FIELDS_TYPE_OPTION_HTML_CONTENT: {
        id: 'zone.fields.type.options.htmlContent',
        description: '',
        defaultMessage: 'HTML Content',
    },
    RECALL_AND_RELEVANCE: {
        id: 'recall_and_relevancy',
        description: '',
        defaultMessage: 'Recall & Relevancy',
    },
    BIASING: {
        id: 'nav.biasing',
        description: 'Biasing sub nav link',
        defaultMessage: 'Biasing',
    },
    NAVIGATIONAL_ELEMENTS: {
        id: 'navigational_elements',
        description: '',
        defaultMessage: 'Navigational Elements',
    },
    NULL_SEARCH_ANALYTICS: {
        id: 'null_search_analytics',
        description: '',
        defaultMessage: 'Null Search Analytics',
    },
    LOAD_ANALYTICS_ERROR: {
        id: 'load_analytics_error',
        description: '',
        defaultMessage: 'Failed to load analytics',
    },
    RULE_ANALYTICS: {
        id: 'rule_analytics',
        description: '',
        defaultMessage: 'Rule Analytics',
    },
    BIASING_PROFILE: {
        id: 'biasing_profile',
        description: '',
        defaultMessage: 'Biasing Profile',
    },
    BIASING_PROFILES: {
        id: 'biasing_profiles',
        description: '',
        defaultMessage: 'Biasing Strategies',
    },
    MERCHANDISING: {
        id: 'nav.merchandising',
        description: 'Merchandising nav link',
        defaultMessage: 'Merchandising',
    },
    ROLES_MERCHANDISER: {
        id: 'roles.merchandiser',
        description: '',
        defaultMessage: 'Merchandiser',
    },
    ROLES_GUEST: {
        id: 'roles.guest',
        description: '',
        defaultMessage: 'Guest',
    },
    ROLES_SUPERADMIN: {
        id: 'roles.super_administrator',
        description: '',
        defaultMessage: 'Super Administrator',
    },
    AREA_PROTECTED_DELETELABEL: {
        id: 'area_protected_deletelabel',
        description: '',
        defaultMessage: 'The protected area(s) cannot be delete: {protectedAreaNames}',
    },
    AREA_FILTERS: {
        id: 'area_filters',
        description: '',
        defaultMessage: 'Area Filters',
    },
    AREAS: {
        id: 'areas',
        description: '',
        defaultMessage: 'Areas',
    },
    AREA: {
        id: 'area',
        description: '',
        defaultMessage: 'Area',
    },
    AREA_SELECTION: {
        id: 'area_selection',
        description: '',
        defaultMessage: 'Area Selection',
    },
    ALL_AREAS: {
        id: 'all_areas',
        description: '',
        defaultMessage: 'All Areas',
    },
    COPY_ALL_TO_AREA: {
        id: 'area.menu_item.copy_all_to_area',
        description: '',
        defaultMessage: 'Copy All to Area',
    },
    COPY_ALL_CONFIGS_TO_AREA_NOT_COPIED_CONFIGS: {
        id: 'area.copy_all_to_area.no_copied_configs',
        description: '',
        defaultMessage: 'The following configs will not be copied:',
    },
    COPY_ALL_CONFIGS_TO_AREA_ERROR_FETCHING_CONFIGS: {
        id: 'area.copy_all_to_area.error.fetch_configs',
        description: '',
        defaultMessage: 'Unable to get configuration list',
    },
    COPY_ALL_CONFIGS_TO_AREA_SUCCESS: {
        id: 'area.success.copy_all_to_area',
        description: '',
        defaultMessage: 'Successfully copied all configs to area',
    },
    COPY_ALL_CONFIGS_TO_AREA_FAIL: {
        id: 'area.error.copy_all_to_area',
        description: '',
        defaultMessage: 'Unable to copy all configs to area',
    },
    SEARCH_AREAS: {
        id: 'search_areas',
        description: '',
        defaultMessage: 'Search Areas',
    },
    AREA_NAME: {
        id: 'area_name',
        description: '',
        defaultMessage: 'Area Name',
    },
    AREAS_CHECKBOX_LIST: {
        id: 'areas_checkbox_list',
        description: '',
        defaultMessage: 'Checkbox List of Areas',
    },
    TYPE_SELECTION: {
        id: 'type_selection',
        description: '',
        defaultMessage: 'Type Selection',
    },
    ALL_TYPES: {
        id: 'all_types',
        description: '',
        defaultMessage: 'All Types',
    },
    PROJECT_CONFIGURATION: {
        id: 'project_configuration',
        description: '',
        defaultMessage: 'Project Configuration',
    },
    ATTRIBUTES: {
        id: 'attributes',
        description: '',
        defaultMessage: 'Attributes',
    },
    ATTRIBUTES_NUM_SELECTED: {
        id: 'attributes.num_selected',
        description: '',
        defaultMessage: '{numSelected} Attributes selected (of {total} items)',
    },
    ITEMS_SELECTED: {
        id: 'items_selected',
        description: '',
        defaultMessage: '{count} selected (of {all} items)',
    },
    TEXTUAL: {
        id: 'textual',
        description: '',
        defaultMessage: 'Textual',
    },
    NUMERICAL: {
        id: 'numerical',
        description: '',
        defaultMessage: 'Numerical',
    },
    NUMERIC: {
        id: 'numeric',
        description: '',
        defaultMessage: 'Numeric',
    },
    UNKNOWN: {
        id: 'unknown',
        description: '',
        defaultMessage: 'Unknown',
    },
    TENANT: {
        id: 'tenant',
        description: '',
        defaultMessage: 'Tenant',
    },
    SEARCH_TYPES: {
        id: 'search_types',
        description: '',
        defaultMessage: 'Search Types',
    },
    REDIRECT: {
        id: 'redirect',
        description: '',
        defaultMessage: 'Redirect',
    },
    REDIRECTS: {
        id: 'redirects',
        description: '',
        defaultMessage: 'Redirects',
    },
    REDIRECT_DESCRIPTION: {
        id: 'redirect.description',
        description: '',
        defaultMessage: 'Redirect from a search term to a specific page. For example, shipping might redirect to /shipping.html.',
    },
    REDIRECT_NUM_SELECTED: {
        id: 'redirect.num_selected',
        description: '',
        defaultMessage: '{numSelected} Redirects Selected',
    },
    REDIRECT_URL: {
        id: 'redirect.url',
        description: '',
        defaultMessage: 'Redirect URL',
    },
    GUIDE: {
        id: 'guide',
        description: '',
        defaultMessage: 'Guide',
    },
    EMAIL: {
        id: 'email',
        description: '',
        defaultMessage: 'Email',
    },
    AUTH0_LOGIN_EMAIL_FIELD_HELPER_TEXT: {
        id: 'auth0.login.email.field.helper_text',
        description: '',
        defaultMessage: 'e.g. email@email.com',
    },
    FAQ: {
        id: 'faq',
        description: '',
        defaultMessage: 'FAQ',
    },
    HOME_LINKS: {
        id: 'home.links',
        description: '',
        defaultMessage: 'Looking for help? You can now find links to product documentation and support in the header.',
    },
    CONFIGURATIONS: {
        id: 'configurations',
        description: '',
        defaultMessage: 'Configurations',
    },
    CREATE: {
        id: 'create',
        description: '',
        defaultMessage: 'Create',
    },
    SAVE: {
        id: 'save',
        description: '',
        defaultMessage: 'Save',
    },
    SAVE_AS_DEFAULT: {
        id: 'save.as.default',
        description: '',
        defaultMessage: 'Save as Default',
    },
    RESET: {
        id: 'reset',
        description: '',
        defaultMessage: 'Reset',
    },
    TRIGGER_SET_ADD: {
        id: 'add_trigger_set',
        description: '',
        defaultMessage: 'Create a Trigger Set',
    },
    TASK_TEMPLATE_LOADALL_ERROR: {
        id: 'task.template.loadall.error',
        description: '',
        defaultMessage: 'Task "Load templates" failed',
    },
    PREVIEW_WRAPPER: {
        id: 'preview_wrappers',
        description: '',
        defaultMessage: 'Preview Wrappers',
    },
    CREATE_PREVIEW_WRAPPER: {
        id: 'create.preview_wrappers',
        description: '',
        defaultMessage: 'Create Preview Wrapper',
    },
    EDIT_PREVIEW_WRAPPER: {
        id: 'edit.preview_wrappers',
        description: '',
        defaultMessage: 'Edit Preview Wrapper',
    },
    PREVIEW_WRAPPER_FORM_DESCRIPTION: {
        id: 'preview_wrappers.form.description',
        description: '',
        defaultMessage: 'Manage Preview Wrappers to use within Templates to consistently view Zone content for Campaign Rules across your site. Within the HTML code editor, replace the template examples with your own prepared HTML snippet.',
    },
    PREVIEW_WRAPPER_INFO: {
        id: 'preview_wrappers.info',
        description: '',
        defaultMessage: 'Preview Wrappers Info',
    },
    PREVIEW_WRAPPER_HTML_INFO: {
        id: 'preview_wrappers.html.info',
        description: '',
        defaultMessage: 'Insert your HTML code here (required)',
    },
    PREVIEW_WRAPPER_HTML_ANCHOR: {
        id: 'preview_wrappers.html.anchor',
        description: '',
        defaultMessage: 'HTML should have "<!--ZONE_CONTENT-->" in it.',
    },
    PREVIEW_WRAPPER_HTML_ANCHOR_LENGTH: {
        id: 'preview_wrappers.html.anchor_length',
        description: '',
        defaultMessage: 'HTML should have "<!--ZONE_CONTENT-->" only once.',
    },
    PREVIEW_WRAPPER_DESCRIPTION: {
        id: 'preview_wrappers.description',
        description: '',
        defaultMessage: 'Manage HTML preview wrappers to view content specific to your Campaign Rules consistently across your sites.',
    },
    TASK_PREVIEW_WRAPPER_CREATE_SUCCESS: {
        id: 'task.preview_wrappers.create.success',
        description: '',
        defaultMessage: 'Task "Preview Wrappers" completed!',
    },
    TASK_PREVIEW_WRAPPER_CREATE_ERROR: {
        id: 'task.preview_wrappers.create.error',
        description: '',
        defaultMessage: 'Task "Preview Wrappers" failed',
    },
    TASK_PREVIEW_WRAPPER_LOAD_ALL_ERROR: {
        id: 'task.preview_wrappers.loadall.error',
        description: '',
        defaultMessage: 'Task "Load Preview Wrappers" failed',
    },
    TASK_PREVIEW_WRAPPER_LOAD_ONE_ERROR: {
        id: 'task.preview_wrappers.loadone.error',
        description: '',
        defaultMessage: 'Task "Load Preview Wrapper" failed',
    },
    TASK_PREVIEW_WRAPPER_REMOVE_ERROR: {
        id: 'task.preview_wrappers.remove.error',
        description: '',
        defaultMessage: 'Task "Remove Preview Wrappers {modelName}" failed',
    },
    TASK_PREVIEW_WRAPPER_BULK_REMOVE_SUCCESS: {
        id: 'task.preview_wrappers.bulk.remove.success',
        description: '',
        defaultMessage: 'Task "Remove Preview Wrappers" completed!',
    },
    TASK_PREVIEW_WRAPPER_BULK_REMOVE_ERROR: {
        id: 'task.preview_wrappers.bulk.remove.error',
        description: '',
        defaultMessage: 'Task "Remove Preview Wrappers" failed',
    },
    TASK_PREVIEW_WRAPPER_UPDATE_SUCCESS: {
        id: 'task.preview_wrappers.update.success',
        description: '',
        defaultMessage: 'Task "Update Preview Wrappers" completed!',
    },
    TASK_PREVIEW_WRAPPER_UPDATE_ERROR: {
        id: 'task.preview_wrappers.update.error',
        description: '',
        defaultMessage: 'Task "Update Preview Wrappers" failed',
    },
    PREVIEW_WRAPPER_DELETE: {
        id: 'preview_wrappers.delete',
        description: '',
        defaultMessage: 'Delete Preview Wrappers',
    },
    PREVIEW_WRAPPER_NUMSELECTED: {
        id: 'preview_wrappers.numselected',
        description: '',
        defaultMessage: '{numSelected} Preview Wrappers Selected',
    },
    TEMPLATE_ADD: {
        id: 'add_template',
        description: '',
        defaultMessage: 'Add Template',
    },
    TEMPLATE: {
        id: 'template',
        description: '',
        defaultMessage: 'Template',
    },
    TEMPLATES: {
        id: 'templates',
        description: '',
        defaultMessage: 'Templates',
    },
    TEMPLATE_NEW: {
        id: 'template.new',
        description: '',
        defaultMessage: 'New Template',
    },
    TEMPLATE_DELETE: {
        id: 'template.delete',
        description: '',
        defaultMessage: 'Delete Template',
    },
    TEMPLATES_NUMSELECTED: {
        id: 'templates.numselected',
        description: '',
        defaultMessage: '{numSelected} Templates Selected',
    },
    RECOMMENDATION: {
        id: 'recommendation',
        description: '',
        defaultMessage: 'Recommendation',
    },
    RECOMMENDATIONS: {
        id: 'recommendations',
        description: '',
        defaultMessage: 'Recommendations',
    },
    RECOMMENDATIONS_HELPER_TEXT: {
        id: 'recommendations.helper_text',
        description: '',
        defaultMessage: 'Recommendations',
    },
    RECOMMENDED_FOR_YOU_DESCRIPTION: {
        id: 'product_recommendations.models.recommended_for_you.description',
        description: '',
        defaultMessage: 'Predicts the next product that a user will engage with or purchase based on the shopping or viewing history of the specified user ID or visitor ID. <b>Typically used on home page.</b>',
    },
    FREQUENTLY_BOUGHT_TOGETHER_DESCRIPTION: {
        id: 'product_recommendations.models.frequently_bought_together.description',
        description: '',
        defaultMessage: 'Predicts items that have been bought together with one or more catalog items in the same shopping session. <b>Commonly displayed after an add-to-cart event, on product detail pages, or on the shopping cart page.</b>',
    },
    PAGE_OPTIMIZATION_DESCRIPTION: {
        id: 'product_recommendations.models.page_optimization.description',
        description: '',
        defaultMessage: 'Automatically optimizes the entire page and catalog item recommendations with multiple recommendation panels. <b>Typically used on detail page view, add to cart, shopping cart, category page view, and home page view.</b>',
    },
    SIMILAR_ITEMS_DESCRIPTION: {
        id: 'product_recommendations.models.similar_items.description',
        description: '',
        defaultMessage: 'Predicts other catalog items that have mostly similar attributes to the current item being considered. <b>Typically used on a product detail page or when an item being viewed is out of stock.</b>',
    },
    BUY_IT_AGAIN_DESCRIPTION: {
        id: 'product_recommendations.models.buy_it_again.description',
        description: '',
        defaultMessage: 'Predicts items for a user to buy again based on their purchase history. Typically used on detail page view, add to cart, shopping cart, category page view, and home page view.',
    },
    PRODUCT_RECOMMENDATIONS: {
        id: 'product_recommendations',
        description: '',
        defaultMessage: 'Product Recommendations',
    },
    PLACEMENTS: {
        id: 'placements',
        description: '',
        defaultMessage: 'Placements',
    },
    PLACEMENT_NEW: {
        id: 'placement.new',
        description: '',
        defaultMessage: 'New Placement',
    },
    PRODUCT_RECOMMENDATION_NEW: {
        id: 'product_recommendation.new',
        description: '',
        defaultMessage: 'New Product Recommendation',
    },
    PLACEMENTS_CREATE: {
        id: 'placements.create',
        description: '',
        defaultMessage: 'Create Placements',
    },
    PRODUCT_RECOMMENDATIONS_CREATE: {
        id: 'product_recommendations.create',
        description: '',
        defaultMessage: 'Product Recommendations',
    },
    ABOVE: {
        id: 'above',
        description: '',
        defaultMessage: 'Above',
    },
    BELOW: {
        id: 'below',
        description: '',
        defaultMessage: 'Below',
    },
    COPY: {
        id: 'copy',
        description: '',
        defaultMessage: 'Copy',
    },
    VALIDATING: {
        id: 'validation',
        description: '',
        defaultMessage: 'Validating...',
    },
    COPY_TO: {
        id: 'copy_to',
        description: '',
        defaultMessage: 'Copy {modelName} to:',
    },
    COPY_TO_AREA: {
        id: 'copy_to_area',
        description: '',
        defaultMessage: 'Copy to Area',
    },
    COPY_TO_AREA_DISABLED_TOOLTIP: {
        id: 'copy_to_area.disabled_tooltip',
        description: '',
        defaultMessage: 'Only rules marked as \'copyable to other areas\' can be copied',
    },
    COPY_RULE_TO_AREA: {
        id: 'copy_rule_to_area',
        description: '',
        defaultMessage: 'Copy Rule to Area',
    },
    BULK_COPY_RULE_TO_AREA: {
        id: 'bulk_copy_rule_to_area',
        description: '',
        defaultMessage: 'Bulk Copy Rules to Another Area',
    },
    COPY_RULE_TO_AREA_CURRENT_AREA: {
        id: 'copy_rule_to_area_current_area',
        description: '',
        defaultMessage: 'Current Area',
    },
    BULK_COPY_RULE_TO_AREA_RULE_COUNT: {
        id: 'bulk_copy_rule_to_area.rule_count',
        description: '',
        defaultMessage: '<b>{count}</b> Rules are currently selected',
    },
    COPY_RULE_TO_AREA_DESTINATION_AREA: {
        id: 'copy_rule_to_area_destination_area',
        description: '',
        defaultMessage: 'Destination Area',
    },
    COPY_RULE_TO_AREA_COLLECTION_NAME: {
        id: 'copy_rule_to_area_collection_name',
        description: '',
        defaultMessage: 'Collection: {name}',
    },
    COPY_RULE_TO_AREA_INFO_SECTION: {
        id: 'copy_rule_to_area_info_section',
        description: '',
        defaultMessage: 'The following configurations will be carried over to destination area with the current rule:',
    },
    COPY_RULE_TO_AREA_BULK_INFO_SECTION: {
        id: 'copy_rule_to_area_bulk_info_section',
        description: '',
        defaultMessage: 'This action will overwrite all the existing configurations:',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_RULE_NAME: {
        id: 'copy_rule_to_area_info_section_rule_name',
        description: '',
        defaultMessage: 'Rule Name',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_SCHEDULER: {
        id: 'copy_rule_to_area_info_scheduler',
        description: '',
        defaultMessage: 'Scheduled date/time',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_TAGS: {
        id: 'copy_rule_to_area_info_tags',
        description: '',
        defaultMessage: 'Tags',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_TRIGGERS: {
        id: 'copy_rule_to_area_info_triggers',
        description: '',
        defaultMessage: 'Trigger sets',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_BIASING: {
        id: 'copy_rule_to_area_info_biasing',
        description: '',
        defaultMessage: 'Biasing strategy',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_FILTERS: {
        id: 'copy_rule_to_area_info_filters',
        description: '',
        defaultMessage: 'Filters',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_PIN: {
        id: 'copy_rule_to_area_info_pin',
        description: '',
        defaultMessage: 'Pin, boost, bury',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_NAVIGATION: {
        id: 'copy_rule_to_area_info_navigation',
        description: '',
        defaultMessage: 'Navigation',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_TEMPLATES: {
        id: 'copy_rule_to_area_info_templates',
        description: '',
        defaultMessage: 'Templates',
    },
    COPY_RULE_TO_AREA_INFO_SECTION_ZONE: {
        id: 'copy_rule_to_area_info_zone',
        description: '',
        defaultMessage: 'Zone',
    },
    BIASING_PROFILE_EXIST_IN_DESTINATION_AREA_TITLE: {
        id: 'biasing_profile_exist_in_destination_area_title',
        description: '',
        defaultMessage: 'Biasing strategy exists in the destination area.',
    },
    BIASING_PROFILE_EXIST_IN_DESTINATION_AREA_TEXT: {
        id: 'biasing_profile_exist_in_destination_area_text',
        description: '',
        defaultMessage: 'Copying will override the existing biasing strategy in the destination area. These rules will be impacted:',
    },
    ZONE_EXIST_IN_DESTINATION_AREA_TITLE: {
        id: 'zone_exist_in_destination_area_title',
        description: '',
        defaultMessage: 'The zone already exists in the destination area.',
    },
    ZONE_EXIST_IN_DESTINATION_AREA_TEXT: {
        id: 'zone_exist_in_destination_area_text',
        description: '',
        defaultMessage: 'Copying will override the existing zone in the destination area. These rules will be impacted:',
    },
    RULE_PROMOTION_ERRORS: {
        id: 'rule_promotion_errors',
        description: '',
        defaultMessage: 'Errors',
    },
    RULE_PROMOTION_WARNINGS: {
        id: 'rule_promotion_warnings',
        description: '',
        defaultMessage: 'Warnings',
    },
    RULE_CANNOT_BE_COPIED_OVER: {
        id: 'rule_cannot_be_copied_over',
        description: '',
        defaultMessage: 'Rule cannot be copied over.',
    },
    RULE_NAME_EXISTS_IN_DESTINATION_AREA_TITLE: {
        id: 'rule_name_exist_in_destination_area_title',
        description: '',
        defaultMessage: 'The rule name already exists in the destination area.',
    },
    RULE_NAME_EXISTS_IN_DESTINATION_AREA_TEXT: {
        id: 'rule_name_exist_in_destination_area_text',
        description: '',
        defaultMessage: 'Copying will override the existing Rule in the destination area.',
    },
    RULE_NAVIGATION_MISSED_ATTRIBUTES: {
        id: 'rule_navigation_missed_attributes',
        description: '',
        defaultMessage: 'Attribute/value used in navigation does not exist in the destination area.',
    },
    RULE_FILTER_MISSED_ATTRIBUTES: {
        id: 'rule_filter_missed_attributes',
        description: '',
        defaultMessage: 'Attribute/value used in filter does not exist in the destination area.',
    },
    RULE_BIASING_PROFILE_ATTRIBUTE_MISSED: {
        id: 'rule_biasing_missed_attributes',
        description: '',
        defaultMessage: 'Attribute/value used in the biasing strategy does not exists in the destination area.',
    },
    RULE_TRIGGER_SET_MISSED_ATTRIBUTES: {
        id: 'rule_trigger_missed_attributes',
        description: '',
        defaultMessage: 'Attribute/value used in trigger sets does not exist in the destination area.',
    },
    RULE_TRIGGER_WARNING_MESSAGE: {
        id: 'rule_trigger_warning_message',
        description: '',
        defaultMessage: 'This trigger set conflicts with Rule ‘{ruleName}’ with a priority of {priority}',
    },
    RULE_EMPTY_TRIGGER_WARNING_MESSAGE: {
        id: 'rule_empty_trigger_warning_message',
        description: '',
        defaultMessage: 'The trigger set conflicts with all rules',
    },
    RULE_IN_ACTIVE_EXPERIMENT: {
        id: 'rule_in_active_experiment',
        description: '',
        defaultMessage: 'The rule is in the experiment.',
    },
    ZONE_EXIST_IN_TARGET_WITH_DIFFERENT_TYPE: {
        id: 'zone_exist_in_target_with_dfferent_type',
        description: '',
        defaultMessage: 'Promoting static content is not possible if the target area contains a zone with the same name but a different type.',
    },
    BIASING_PROFILE_IS_AREA_DEFAULT: {
        id: 'biasing_profile_is_area_default',
        description: '',
        defaultMessage: 'This biasing strategy has the same name but different biases in the destination area. Also, this biasing strategy is used as a default in the destination area.',
    },
    COPY_CONFIGS_TO_AREA_DESCRIPTION: {
        id: 'copy_configs_to_area.description',
        description: '',
        defaultMessage: 'Copy all configurations from {modelName} to:',
    },
    BULK_COPY_CONFIGS_TO_AREA: {
        id: 'copy_configs_to_area.bulk',
        description: '',
        defaultMessage: 'Copy selected configurations to Area',
    },
    COPY_CONFIGS_TO_AREA_TITLE: {
        id: 'copy_configs_to_area.title',
        description: '',
        defaultMessage: 'Copy All Configurations',
    },
    COPY_CONFIGS_TO_AREA_WARNING: {
        id: 'copy_configs_to_area.warning',
        description: '',
        defaultMessage: 'CAUTION! All configurations in the destination area will be overwritten with configuration from this area. Please make absolutely sure this is what you want. This action is irreversible!',
    },
    PAGINATION_PER_PAGE_LABEL: {
        id: 'pagination.per_page_label',
        description: '',
        defaultMessage: 'PAGE SIZE',
    },
    PAGINATION_PER_PAGE_ERROR: {
        id: 'pagination.per_page.error',
        description: '',
        defaultMessage: 'Work with localStorage failed',
    },
    PAGINATION_PAGE_LABEL: {
        id: 'pagination.page_label',
        description: '',
        defaultMessage: 'PAGE',
    },
    PAGINATION_COUNT_LABEL: {
        id: 'pagination.count_label',
        description: '',
        defaultMessage: 'of {numPages} pages',
    },
    PAGINATION_COUNT_COMMON_LABEL: {
        id: 'pagination.count_common_label',
        description: '',
        defaultMessage: 'of {numPages}',
    },
    PAGINATION_CURRENT_RANGE_LABEL: {
        id: 'pagination.current_range_label',
        description: '',
        defaultMessage: '{startRange} - {endRange} of {total}',
    },
    PAGINATION_CURRENT_OF_LABEL: {
        id: 'pagination.current_of_label',
        description: '',
        defaultMessage: 'of {total}',
    },
    PAGINATION_CURRENT_RANGE_COMMON_LABEL: {
        id: 'pagination.current_range_common_label',
        description: '',
        defaultMessage: '{startRange} - {endRange} of {total}',
    },
    SELECT_AREA: {
        id: 'select_area',
        description: '',
        defaultMessage: 'Select Area',
    },
    SELECT_ALL: {
        id: 'select_all',
        description: '',
        defaultMessage: 'Select All',
    },
    DESELECT_ALL: {
        id: 'deselect_all',
        description: '',
        defaultMessage: 'Deselect All',
    },
    CLEAR_ALL: {
        id: 'clear_all',
        description: '',
        defaultMessage: 'Clear All',
    },
    DELETE: {
        id: 'delete',
        description: '',
        defaultMessage: 'Delete',
    },
    DELETE_ANYWAY: {
        id: 'delete_anyway',
        description: '',
        defaultMessage: 'Delete Anyway',
    },
    DELETE_SUBTEXT: {
        id: 'delete.subtext',
        description: '',
        defaultMessage: 'Are you sure you want to delete {NameModelLabel}?',
    },
    DUPLICATE: {
        id: 'duplicate',
        description: '',
        defaultMessage: 'Duplicate',
    },
    DUPLICATE_WITH_NAME: {
        id: 'duplicate_with_name',
        description: '',
        defaultMessage: 'Duplicate {modelName}',
    },
    PRIORITY_NUMBER: {
        id: 'priority_number',
        description: '',
        defaultMessage: 'Priority number',
    },
    PRIORITY_NUMBER_ERROR: {
        id: 'priority_number.error',
        description: '',
        defaultMessage: 'Must be a valid priority value.',
    },
    MOVE: {
        id: 'move',
        description: '',
        defaultMessage: 'Move',
    },
    CANCEL: {
        id: 'cancel',
        description: '',
        defaultMessage: 'Cancel',
    },
    OK: {
        id: 'ok',
        description: '',
        defaultMessage: 'OK',
    },
    NOT_OK: {
        id: 'not_ok',
        description: '',
        defaultMessage: 'Not OK',
    },
    CUSTOMER_ID: {
        id: 'customer_id',
        description: '',
        defaultMessage: 'Customer Id',
    },
    MOVE_ABOVE_BELOW: {
        id: 'move_above_below',
        description: '',
        defaultMessage: 'Move Above/Below',
    },
    MOVE_ANOTHER_HERE: {
        id: 'move_another_here',
        description: '',
        defaultMessage: 'Move Another Here',
    },
    MOVE_LABEL: {
        id: 'move.label',
        defaultMessage: 'Move {NameModelLabel}:',
        description: "Label to notify user of the object they're moving",
    },
    ADMIN: {
        id: 'admin',
        description: '',
        defaultMessage: 'Admin',
    },
    AREA_CREATE_NEW: {
        id: 'area_create.new',
        description: '',
        defaultMessage: 'New Area',
    },
    AREA_CREATE_TITLE: {
        id: 'area_create.title',
        description: '',
        defaultMessage: 'Create an Area',
    },
    AREA_CREATE_NAME: {
        id: 'area_create.name',
        description: '',
        defaultMessage: 'Name Your Area',
    },
    AREA_CREATE_NAME_LABEL: {
        id: 'area_create.name_label',
        description: '',
        defaultMessage: 'Area Name (required)',
    },
    AREA_CREATE_NAME_SUBHEADER: {
        id: 'area_create.name_subheader',
        description: '',
        defaultMessage: 'Choose a memorable name that will appear in Area dropdown menus.',
    },
    AREA_CREATE_DEFAULT_SITE: {
        id: 'area_create.default_site',
        description: '',
        defaultMessage: 'Default Site',
    },
    AREA_DELETE: {
        id: 'area.delete',
        description: '',
        defaultMessage: 'Delete Area',
    },
    AREA_DELETE_DISABLED: {
        id: 'area.delete.disabled',
        description: '',
        defaultMessage: 'You are unable to delete this because there must be at least one area associated with each collection.',
    },
    MUST_CONTAIN_ONLY_LETTERS: {
        id: 'only_letters',
        description: '',
        defaultMessage: 'Must contain only letters',
    },
    MUST_CONTAIN_ONLY_LETTERS_NUMBERS_SYMBOLS: {
        id: 'only.letters.numbers.symbols',
        description: '',
        defaultMessage: 'Must contain only letters, numbers, or symbols',
    },
    MUST_CONTAIN_ONLY_LETTERS_SYMBOLS: {
        id: 'only.letters.symbols',
        description: '',
        defaultMessage: 'Must contain only letters or symbols',
    },
    MUST_CONTAIN_ONLY_NUMBERS_LETTERS: {
        id: 'only_numbers_letters',
        description: '',
        defaultMessage: 'Must contain only letters and numbers',
    },
    MUST_CONTAIN_ONLY_LETTERS_AT_LEAST_NUMBER: {
        id: 'only_numbers_letters_at_least_number',
        description: '',
        defaultMessage: 'Must contain only letters. Minimal length - {number} characters',
    },
    MUST_CONTAIN_ONLY_NUMBERS_LETTERS_SPACES_UNDERSCORES: {
        id: 'only_numbers_letters_spaces_underscores',
        description: '',
        defaultMessage: 'Must contain only letters, numbers, spaces and underscores',
    },
    MUST_CONTAIN_ONLY_NUMBERS_LETTERS_SPACES_UNDERSCORES_DASHES: {
        id: 'only_numbers_letters_spaces_underscores_dashes',
        description: '',
        defaultMessage: 'Must contain only letters, numbers, spaces, underscores and dashes',
    },
    MUST_CONTAIN_ONLY_NUMBERS_LETTERS_SPACES: {
        id: 'only_numbers_letters_spaces',
        description: '',
        defaultMessage: 'Must contain only letters, numbers, spaces',
    },
    MUST_CONTAIN_ONLY_NUMBERS_LETTERS_UNDERSCORES_PERIODS_DASHES: {
        id: 'only_numbers_letters_underscores_periods_dashes',
        description: '',
        defaultMessage: 'Must contain only letters, numbers, periods, dashes, and underscores (no spaces)',
    },
    AREA_MANAGEMENT: {
        id: 'area_management',
        description: '',
        defaultMessage: 'Area Management',
    },
    AREA_CREATE_AREA_DEFAULTS: {
        id: 'area_create.area_defaults',
        description: '',
        defaultMessage: 'Area defaults',
    },
    EDIT_AREA_TITLE: {
        id: 'area_edit.title',
        description: '',
        defaultMessage: 'Edit Area',
    },
    AREA_MANAGEMENT_DESCRIPTION: {
        id: 'area_management.description',
        description: '',
        defaultMessage: 'Each Area is a completely stand alone set of business rule configurations for your site experience.',
    },
    AREA_MANAGEMENT_NUMSELECTED: {
        id: 'area_management.numselected',
        description: '',
        defaultMessage: '{numSelected} Areas Selected',
    },
    SWITCH_TO_AREA: {
        id: 'switch_to_area',
        description: '',
        defaultMessage: 'Switch to this area',
    },
    AUTH0_USER_AUTH_NO_PERMISSIONS_MESSAGE: {
        id: 'auth0.user.auth.no.permissions.message',
        description: '',
        defaultMessage: 'You do not have permissions to access Command Center. Please contact an administrator.',
    },
    USER_CREDENTIALS: {
        id: 'user.credentials',
        description: '',
        defaultMessage: 'User Credentials',
    },
    USER_CREDENTIALS_CONNECTION_FIELD_LABEL: {
        id: 'user.credentials.connection.field.label',
        description: '',
        defaultMessage: 'What would you like to sign up with?',
    },
    USER_MANAGEMENT: {
        id: 'user_management',
        description: '',
        defaultMessage: 'User Management',
    },
    USER: {
        id: 'user',
        description: '',
        defaultMessage: 'User',
    },
    USERS: {
        id: 'users',
        description: '',
        defaultMessage: 'Users',
    },
    SEARCH_USERS: {
        id: 'search_users',
        description: '',
        defaultMessage: 'Search Users',
    },
    USER_SELECTION: {
        id: 'user_selection',
        description: '',
        defaultMessage: 'User Selection',
    },
    ALL_USERS: {
        id: 'all_users',
        description: '',
        defaultMessage: 'All Users',
    },
    USERS_DESCRIPTION: {
        id: 'users_description',
        description: '',
        defaultMessage: 'Create users and manage access to the Command Center.',
    },
    USERS_NUMSELECTED: {
        id: 'users.numselected',
        description: '',
        defaultMessage: '{numSelected} Users Selected',
    },
    USER_NEW: {
        id: 'users.new',
        description: '',
        defaultMessage: 'New User',
    },
    USER_CREATE: {
        id: 'users.create',
        description: '',
        defaultMessage: 'Create User',
    },
    USER_DELETE: {
        id: 'user.delete',
        description: '',
        defaultMessage: 'Delete User',
    },
    USER_EDIT: {
        id: 'users.edit',
        description: '',
        defaultMessage: 'Edit User',
    },
    USER_CREATE_EMAIL_LABEL: {
        id: 'users.create.email_label',
        description: '',
        defaultMessage: 'User Email (required)',
    },
    USER_CREATE_EMAIL_HELPER: {
        id: 'users.create.email_helper',
        description: '',
        defaultMessage: 'Must be a valid email',
    },
    USER_CREATE_PASSWORD_LABEL: {
        id: 'users.create.password_label',
        description: '',
        defaultMessage: 'Password (required)',
    },
    USER_CREATE_PASSWORD_HELPER: {
        id: 'users.create.password_helper',
        description: '',
        defaultMessage: 'Must be between 8-50 characters, and include at least 3 of: lowercase, uppercase, numeric, or symbols (from !@#$%^&*()_+)',
    },
    USER_IS_ADMINISTRATOR: {
        id: 'users.edit.inform_is_admin',
        description: '',
        defaultMessage: 'You are an Administrator',
    },
    EDIT_PASSWORD: {
        id: 'edit_password',
        description: '',
        defaultMessage: 'Edit password',
    },
    PASSWORD: {
        id: 'password',
        description: '',
        defaultMessage: 'Password',
    },
    KEY_MANAGEMENT: {
        id: 'key_management',
        description: '',
        defaultMessage: 'Key Management',
    },
    HISTORY: {
        id: 'history',
        description: '',
        defaultMessage: 'History',
    },
    BIASING_STRATEGY_WARNINGS: {
        id: 'biasing_strategy.warnings',
        description: '',
        defaultMessage: 'Warnings',
    },
    BIASING_MAIN_TITLE: {
        id: 'biasing_main_title',
        description: '',
        defaultMessage: 'Biasing',
    },
    BIASING_STRATEGY: {
        id: 'biasing_strategy',
        description: '',
        defaultMessage: 'Biasing Strategy',
    },
    BIASING_CREATE: {
        id: 'biasing_create',
        description: '',
        defaultMessage: 'Create Biasing',
    },
    BIASING_STRATEGIES: {
        id: 'biasing_strategies',
        description: '',
        defaultMessage: 'Biasing Strategies',
    },
    BIASING_STRATEGY_NUMSELECTED: {
        id: 'biasing_strategy.numselected',
        description: '',
        defaultMessage: '{numSelected} Biasing Strategies Selected',
    },
    BIASING_STRATEGY_DESCRIPTION: {
        id: 'biasing_strategy.description',
        description: '',
        defaultMessage: 'Change the order of results in search and browse states.',
    },
    BIASING_STRATEGY_CREATE_DESCRIPTION: {
        id: 'biasing_strategy.create_description',
        description: '',
        defaultMessage: 'Create a Biasing Strategy here to change the order of results in search and browse at the Area or Rule-level.',
    },
    BIASING_STRATEGY_CHOOSE: {
        id: 'biasing_strategy.choose',
        description: '',
        defaultMessage: 'Choose a Biasing Strategy.',
    },
    BIASING_STRATEGY_EDIT_TITLE: {
        id: 'biasing_strategy.edit_title',
        description: '',
        defaultMessage: 'Edit Biasing Strategy',
    },
    BIASING_STRATEGY_EDIT_TITLE_SHORT: {
        id: 'biasing_strategy.edit_title_short',
        description: '',
        defaultMessage: 'Edit Biasing',
    },
    BIASING_STRATEGY_CREATE_NEW: {
        id: 'biasing_strategy.create.new',
        description: '',
        defaultMessage: 'New Biasing Strategy',
    },
    BIASING_STRATEGY_ADD: {
        id: 'biasing_strategy.add.new',
        description: '',
        defaultMessage: 'Add New Biasing Strategy',
    },
    BIASING_STRATEGY_CREATE_NAME_TITLE: {
        id: 'biasing_strategy.create_name',
        description: '',
        defaultMessage: 'Name Your Biasing Strategy',
    },
    MEGAMENU_CREATE_NAME_TITLE: {
        id: 'mega_menu.create_name',
        description: '',
        defaultMessage: 'Mega Menu Name',
    },
    MEGAMENU: {
        id: 'mega_menu',
        description: '',
        defaultMessage: 'Mega Menu',
    },
    CATEGORIES_DESCRIPTION: {
        id: 'categories.description',
        description: '',
        defaultMessage: 'Upload category data to generate or adjust your category hierarchy/mega menu.',
    },
    CATEGORIES_CREATE_DESCRIPTION: {
        id: 'categories.create.description',
        description: '',
        defaultMessage: 'Upload category data to generate your category hierarchy/mega menu.',
    },
    CATEGORIES_EDIT_DESCRIPTION: {
        id: 'categories.edit.description',
        description: '',
        defaultMessage: 'Upload category data to adjust your category hierarchy/mega menu.',
    },
    CATEGORIES_ADD: {
        id: 'categories.add',
        description: '',
        defaultMessage: 'Add Category Set',
    },
    CATEGORIES_JSON_SCHEMA: {
        id: 'categories.json.schema',
        description: '',
        defaultMessage: 'Categories JSON schema example',
    },
    CATEGORIES_JSON_ADD: {
        id: 'categories.json.add',
        description: '',
        defaultMessage: 'Add newline JSON file',
    },
    CATEGORIES_FILE_ADDED_DATE: {
        id: 'categories.file.added_date',
        description: '',
        defaultMessage: 'File added: {date}',
    },
    CATEGORIES_SAVE_FORM_NOTE: {
        id: 'categories.save_form_note',
        description: '',
        defaultMessage: 'Please save the form to complete the file upload.',
    },
    TASK_MEGAMENU_DELETE_SUCCESS: {
        id: 'categories.mega_menu.delete.success',
        description: '',
        defaultMessage: 'Task "Delete Mega Menu {modelName}" completed!',
    },
    TASK_MEGAMENU_DELETE_ERROR: {
        id: 'categories.mega_menu.delete.error',
        description: '',
        defaultMessage: 'Task "Delete Mega Menu {modelName}" failed',
    },
    BIASING_STRATEGY_CREATE_NAME_DESCRIPTION: {
        id: 'biasing_strategy.create_name_description',
        description: '',
        defaultMessage: 'Choose a memorable name that will appear in the Rule and Area dropdown menus',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_ABSOLUTEDECREASE: {
        id: 'biasing_strategy.form.influence_slider.absolute_decrease',
        description: '',
        defaultMessage: 'Absolute Decrease',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_STRONGDECREASE: {
        id: 'biasing_strategy.form.influence_slider.strong_decrease',
        description: '',
        defaultMessage: 'Strong Decrease',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_MEDIUMDECREASE: {
        id: 'biasing_strategy.form.influence_slider.medium_decrease',
        description: '',
        defaultMessage: 'Medium Decrease',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_WEAKDECREASE: {
        id: 'biasing_strategy.form.influence_slider.weak_decrease',
        description: '',
        defaultMessage: 'Weak Decrease',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_UNCHANGED: {
        id: 'biasing_strategy.form.influence_slider.unchanged',
        description: '',
        defaultMessage: 'Leave Unchanged',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_ABSOLUTEINCREASE: {
        id: 'biasing_strategy.form.influence_slider.absolute_increase',
        description: '',
        defaultMessage: 'Absolute Increase',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_STRONGINCREASE: {
        id: 'biasing_strategy.form.influence_slider.strong_increase',
        description: '',
        defaultMessage: 'Strong Increase',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_MEDIUMINCREASE: {
        id: 'biasing_strategy.form.influence_slider.medium_increase',
        description: '',
        defaultMessage: 'Medium Increase',
    },
    BIASING_STRATEGY_FORM_INFLUENCESLIDER_WEAKINCREASE: {
        id: 'biasing_strategy.form.influence_slider.weak_increase',
        description: '',
        defaultMessage: 'Weak Increase',
    },
    BIASING_STRATEGY_DEFINE: {
        id: 'biasing_strategy.define',
        description: '',
        defaultMessage: 'Define Bias',
    },
    BIASING_STRATEGY_DEFINE_INSTRUCTIONS: {
        id: 'biasing_strategy.define_instructions',
        description: '',
        defaultMessage: 'You can define exact content value or blank content biases here. Add an attribute name to bias against (or leave it blank) and choose the influence of boost or bury.',
    },
    BIASING_STRATEGY_DEFINE_ADD: {
        id: 'biasing_strategy.define_add',
        description: '',
        defaultMessage: 'Add Bias',
    },
    BIASING_STRATEGY_DEFINE_ADD_VALUE: {
        id: 'biasing_strategy.define_add_value',
        description: '',
        defaultMessage: 'Add Value',
    },
    BIASING_STRATEGY_DEFINE_ATTRIBUTE_NAME: {
        id: 'biasing_strategy.define_attribute_name',
        description: '',
        defaultMessage: 'Attribute',
    },
    BIASING_STRATEGY_DEFINE_DELETE: {
        id: 'biasing_strategy.define.delete',
        description: '',
        defaultMessage: 'Delete bias',
    },
    BIASING_STRATEGY_DEFINE_DELETE_VALUE: {
        id: 'biasing_strategy.define.delete_value',
        description: '',
        defaultMessage: 'Delete value',
    },
    BIASING_STRATEGY_DEFINE_MATCHES: {
        id: 'biasing_strategy.define_matches',
        description: '',
        defaultMessage: 'Value',
    },
    BIASING_STRATEGY_DEFINE_MATCHES_VALUE: {
        id: 'biasing_strategy.define_matches_value',
        description: '',
        defaultMessage: 'Value',
    },
    BIASING_STRATEGY_DEFINE_MATCHES_LOW: {
        id: 'biasing_strategy.define_matches_low',
        description: '',
        defaultMessage: 'Low',
    },
    BIASING_STRATEGY_DEFINE_MATCHES_HIGH: {
        id: 'biasing_strategy.define_matches_high',
        description: '',
        defaultMessage: 'High',
    },
    BIASING_STRATEGY_SELECT_ATTRIBUTE: {
        id: 'biasing_strategy.select_attribute',
        description: '',
        defaultMessage: 'You should select attribute',
    },
    BIASING_STRATEGY_SELECT_VALID_ATTRIBUTE: {
        id: 'biasing_strategy.select_valid_attribute',
        description: '',
        defaultMessage: 'You should select valid attribute',
    },
    TAG: {
        id: 'tag',
        description: '',
        defaultMessage: 'Tag',
    },
    TAGS: {
        id: 'tags',
        description: '',
        defaultMessage: 'Tags',
    },
    TAGS_DESCRIPTION: {
        id: 'tags.description',
        description: '',
        defaultMessage: 'Tags can be any word or acronym that is meaningful to your organization for grouping. E.g. Department/Categories, Landing Page etc.',
    },
    TAGS_NUMSELECTED: {
        id: 'tags.numselected',
        description: '',
        defaultMessage: '{numSelected} Tags Selected',
    },
    TAG_CREATE: {
        id: 'tag.create',
        description: '',
        defaultMessage: 'Create Tag',
    },
    TAG_EDIT: {
        id: 'tag.edit',
        description: '',
        defaultMessage: 'Edit Tag',
    },
    TAG_DELETE: {
        id: 'tag.delete',
        description: '',
        defaultMessage: 'Delete Tag',
    },
    NO_DASHBOARDS: {
        id: 'analytics.no_dashboards',
        description: '',
        defaultMessage: 'Your organization has no dashboards - please contact support.',
    },
    NO_TAGS: {
        id: 'tags.select.none',
        description: '',
        defaultMessage: 'No Tags',
    },
    TAG_MANAGEMENT: {
        id: 'tag_management',
        description: '',
        defaultMessage: 'Tag Management',
    },
    ATTRIBUTE_MANAGEMENT: {
        id: 'attribute_management',
        description: '',
        defaultMessage: 'Attribute Management',
    },
    LINGUISTIC_CONTROLS: {
        id: 'linguistic.controls',
        description: '',
        defaultMessage: 'Linguistic Controls',
    },
    LINGUISTIC_CONTROLS_BULK_DELETE_DIALOG_TITLE: {
        id: 'linguistic_controls.bulk_delete.title',
        description: '',
        defaultMessage: 'It\'s not you, it\'s us.',
    },
    LINGUISTIC_CONTROLS_BULK_DELETE_DIALOG_MESSAGE: {
        id: 'linguistic_controls.bulk_delete.message',
        description: '',
        defaultMessage: 'The following controls were not able to be deleted at this time. <b>Please try again shortly.</b>',
    },
    LINGUISTIC_CONTROLS_LIMIT_EXCEEDED: {
        id: 'linguistic_controls.limit_exceeded.message',
        description: '',
        defaultMessage: 'You have reached the maximum limit for available linguistic controls. Please contact your GroupBy Representative.',
    },
    ONE_WAY_SYNONYMS: {
        id: 'oneway.synonyms',
        description: '',
        defaultMessage: 'ONE-WAY SYNONYMS',
    },
    TWO_WAY_SYNONYMS: {
        id: 'twoway.synonyms',
        description: '',
        defaultMessage: 'TWO-WAY SYNONYMS',
    },
    DO_NOT_ASSOCIATE: {
        id: 'do.not.associate',
        description: '',
        defaultMessage: 'Do Not Associate',
    },
    IGNORE_ACTION: {
        id: 'ignore.action',
        description: '',
        defaultMessage: 'Ignore Action',
    },
    IGNORE: {
        id: 'ignore',
        description: '',
        defaultMessage: 'Ignore',
    },
    IGNORE_ACTION_FIELD_DESCRIPTION: {
        id: 'ignore.action.field.description',
        description: '',
        defaultMessage: 'Add Ignore Action term to remove from the search query.',
    },
    IGNORE_ACTION_CREATE: {
        id: 'ignore.action.create',
        description: '',
        defaultMessage: 'Create Ignore Action',
    },
    IGNORE_ACTION_EDIT: {
        id: 'ignore.action.edit',
        description: '',
        defaultMessage: 'Edit Ignore Action',
    },
    IGNORE_ACTION_DESCRIPTION: {
        id: 'ignore.action.description',
        description: '',
        defaultMessage: '<b>Ignore Actions</b> are removed from the search query before further processing is carried out unless there are double quotes in the search query.',
    },
    REPLACEMENT: {
        id: 'replacement',
        description: '',
        defaultMessage: 'Replacement',
    },
    REPLACEMENT_CREATE: {
        id: 'replacement.create',
        description: '',
        defaultMessage: 'Create Replacement',
    },
    REPLACEMENT_EDIT: {
        id: 'replacement.edit',
        description: '',
        defaultMessage: 'Edit Replacement',
    },
    REPLACEMENT_TRIGGER_TERM_SUBTITLE: {
        id: 'replacement.trigger_term.subtitle',
        description: '',
        defaultMessage: 'Add the search term(s) you want to redirect from, separated by commas.',
    },
    REPLACEMENT_TRIGGER_TERM_HELPER_TEXT: {
        id: 'replacement.trigger_term.helper_text',
        description: '',
        defaultMessage: 'Ex. blazers, coats, etc.',
    },
    REPLACEMENT_REPLACEMENT_TERM_SUBTITLE: {
        id: 'replacement.replacement_term.subtitle',
        description: '',
        defaultMessage: 'Then, add the replacement for the term your customer wants to search for.',
    },
    LINGUISTIC_CONTROLS_DESCRIPTION: {
        id: 'linguistic.controls.description',
        description: '',
        defaultMessage: 'Linguistic controls allows users to create sets of actions when the required condition is triggered.',
    },
    LINGUISTIC_CONTROLS_BULK_DELETE_TITLE: {
        id: 'linguistic.controls.bulk.delete.title',
        description: '',
        defaultMessage: 'Are you sure you want to delete ALL of the selected controls?',
    },
    LINGUISTIC_CONTROLS_DELETE_TITLE: {
        id: 'linguistic.controls.delete.title',
        description: '',
        defaultMessage: 'Are you sure you want to delete this control?',
    },
    LINGUISTIC_CONTROLS_NEW: {
        id: 'linguistic.controls.new',
        description: '',
        defaultMessage: 'Create {controlType}',
    },
    LINGUISTIC_CONTROLS_NUMSELECTED: {
        id: 'linguistic.controls.numselected',
        description: '',
        defaultMessage: '{numSelected} Controls Selected',
    },
    TASK_LINGUISTIC_CONTROL_LOAD_ONE_ERROR: {
        id: 'task.linguistic.control.load_one.error',
        description: '',
        defaultMessage: 'Task "Load linguistic control" failed',
    },
    TASK_LINGUISTIC_CONTROL_UPDATE_SUCCESS: {
        id: 'task.linguisitc.control.update.success',
        description: '',
        defaultMessage: 'Task "Update linguistic control" completed!',
    },
    TASK_LINGUISTIC_CONTROL_UPDATE_ERROR: {
        id: 'task.linguistic.control.update.error',
        description: '',
        defaultMessage: 'Task "Update linguistic control" failed',
    },
    TASK_LINGUISTIC_CONTROL_REMOVE_SUCCESS: {
        id: 'task.linguistic.control.remove.success',
        description: '',
        defaultMessage: 'Successfully deleted a linguistic control',
    },
    TASK_LINGUISTIC_CONTROL_REMOVE_BULK_SUCCESS: {
        id: 'task.linguistic.control.bulk.remove.success',
        description: '',
        defaultMessage: 'Successfully deleted {numOfSuccess} linguistic controls',
    },
    TASK_LINGUISTIC_CONTROL_REMOVE_ERROR: {
        id: 'task.linguistic.control.remove.error',
        description: '',
        defaultMessage: 'Task "Remove linguistic control" failed.',
    },
    TASK_LINGUISTIC_CONTROL_REMOVE_SUCCESS_WITH_INFO: {
        id: 'task.linguistic.control.remove.success.with.info',
        description: '',
        defaultMessage: '{numOfSuccess} successfully deleted.',
    },
    TASK_LINGUISTIC_CONTROL_REMOVE_ERROR_WITH_INFO: {
        id: 'task.linguistic.control.remove.error.with.info',
        description: '',
        defaultMessage: '{numOfFailed} failed to delete.',
    },
    TOTAL_CONTROLS_CREATED: {
        id: 'total_controls.creaetd',
        description: '',
        defaultMessage: 'Total Controls Created:',
    },
    ONE_WAY_SYNONYM_CREATE: {
        id: 'one_way_synonym.create',
        description: '',
        defaultMessage: 'Create one-way synonym',
    },
    ONE_WAY_SYNONYM_EDIT: {
        id: 'one_way_synonym.edit',
        description: '',
        defaultMessage: 'Edit one-way synonym',
    },
    DO_NOT_ASSOCIATE_CANNOT_CONTAIN_TRIGGER_TERM_VALUES: {
        id: 'do_not_associate.cannot.contain.trigger.term.values',
        description: '',
        defaultMessage: 'Do Not Associate Term(s) can not have values from Trigger Term(s)',
    },
    TASK_SYNONYM_CREATE_SUCCESS: {
        id: 'task.synonym.create.success',
        description: '',
        defaultMessage: 'Task "Create synonym" completed!',
    },
    TASK_LINGUISTIC_CONTROL_CREATE_SUCCESS: {
        id: 'task.linguistic_control.create.success',
        description: '',
        defaultMessage: 'Task "Create Linguistic Control" completed!',
    },
    TASK_SYNONYM_CREATE_ERROR: {
        id: 'task.synonym.create.error',
        description: '',
        defaultMessage: 'Task "Create synonym" failed',
    },
    TASK_LINGUISTIC_CONTROL_CREATE_ERROR: {
        id: 'task.linguistic_control.create.error',
        description: '',
        defaultMessage: 'Task "Create Linguistic Control" failed',
    },
    TASK_LINGUISTIC_CONTROL_CREATE_ERROR_EXISTS: {
        id: 'task.linguistic_control.create.error_exists',
        description: '',
        defaultMessage: 'Linguistic control with the same name already exists',
    },
    CONTROLS: {
        id: 'controls',
        description: '',
        defaultMessage: 'Controls',
    },
    SYNONYMS_NAME_FIELD_HELPER_TEXT: {
        id: 'synonyms.name.field.helper.text',
        description: '',
        defaultMessage: 'Name is not editable after creation',
    },
    ATTRIBUTE_NAME: {
        id: 'attribute.name',
        description: '',
        defaultMessage: 'Attribute Name',
    },
    DYNAMIC_FACETING: {
        id: 'dynamic_faceting',
        description: '',
        defaultMessage: 'Dynamic Faceting',
    },
    INSIGHTS: {
        id: 'insights',
        description: '',
        defaultMessage: 'Daily Insights',
    },
    INSIGHTS_SUGGESTION: {
        id: 'insights_Suggestion',
        description: '',
        defaultMessage: 'Suggestion',
    },
    INSIGHTS_QUERIES_DROP_IN_REVENUE: {
        id: 'queries_drop_in_revenue',
        description: '',
        defaultMessage: 'Revenue drops can result from stock changes, rule updates, or seasonality. Review any rules that may be impacting these queries or see if there have been catalog changes that may be impacting them.',
    },
    INSIGHTS_QUERIES_DROP_IN_REVENUE_TITLE: {
        id: 'queries_drop_in_revenue_title',
        description: '',
        defaultMessage: 'Queries with a revenue drop (last 7 days)',
    },
    INSIGHTS_QUERIES_DROP_IN_CONVERSION_RATE: {
        id: 'queries_drop_in_conversion_rate',
        description: '',
        defaultMessage: 'Conversion rate drops can result from stock changes, rule updates, or seasonality. Review any rules that may be impacting these queries or see if there have been catalog changes that may be impacting them.',
    },
    INSIGHTS_QUERIES_DROP_IN_CONVERSION_RATE_TITLE: {
        id: 'queries_drop_in_conversion_rate_title',
        description: '',
        defaultMessage: 'Queries with a conversion rate drop (last 7 days)',
    },
    INSIGHTS_PRODUCTS_DROP_IN_REVENUE: {
        id: 'products_drop_in_revenue',
        description: '',
        defaultMessage: 'Revenue drops can result from stock changes, rule updates, or seasonality. Review any rules that may be impacting these products or see if there have been catalog changes that may be impacting them.',
    },
    INSIGHTS_PRODUCTS_DROP_IN_REVENUE_TITLE: {
        id: 'products_drop_in_revenue_title',
        description: '',
        defaultMessage: 'Products with a revenue drop (last 7 days)',
    },
    INSIGHTS_PRODUCTS_DROP_IN_CONVERSION_RATE: {
        id: 'products_drop_in_conversion_rate',
        description: '',
        defaultMessage: 'Conversion rate drops can result from stock changes, rule updates, or seasonality. Review any rules that may be impacting these products or see if there have been catalog changes that may be impacting them.',
    },
    INSIGHTS_PRODUCTS_DROP_IN_CONVERSION_RATE_TITLE: {
        id: 'products_drop_in_conversion_rate_title',
        description: '',
        defaultMessage: 'Products with a conversion rate drop (last 7 days)',
    },
    INSIGHTS_QUERIES_HIGH_SEARCHES_LOW_REVENUE: {
        id: 'queries_high_searches_low_revenue',
        description: '',
        defaultMessage: 'These queries often indicate unmet searcher needs. Review the results and consider ordering more products or adjusting what is displayed.',
    },
    INSIGHTS_QUERIES_HIGH_SEARCHES_LOW_REVENUE_TITLE: {
        id: 'queries_high_searches_low_revenue_title',
        description: '',
        defaultMessage: 'Queries with substantial searches but lower than average revenue',
    },
    INSIGHTS_PRODUCTS_HIGH_REVENUE_LOW_POSITION: {
        id: 'products_high_revenue_low_position',
        description: '',
        defaultMessage: 'Review if there are any rules that may be negatively impacting this product\'s rankings.',
    },
    INSIGHTS_PRODUCTS_HIGH_REVENUE_LOW_POSITION_TITLE: {
        id: 'products_high_revenue_low_position_title',
        description: '',
        defaultMessage: 'These top-selling products rank low in search results',
    },
    INSIGHTS_PRODUCTS_HIGH_SEARCH_LOW_REVENUE: {
        id: 'products_high_search_low_revenue',
        description: '',
        defaultMessage: 'Investigate if there are any rules boosting these product and if those rules are still relevant given the low revenue.',
    },
    INSIGHTS_PRODUCTS_HIGH_SEARCH_LOW_REVENUE_TITLE: {
        id: 'products_high_search_low_revenue_title',
        description: '',
        defaultMessage: 'Products that are frequently shown in search results but are yielding low revenue',
    },
    INSIGHTS_QUERIES_MULTI_RULE: {
        id: 'queries_multi_rule',
        description: '',
        defaultMessage: 'Confirm if it is intended that these queries are impacted by multiple rules.',
    },
    INSIGHTS_QUERIES_MULTI_RULE_TITLE: {
        id: 'queries_multi_rule_title',
        description: '',
        defaultMessage: 'Queries with multiple rules impacting them',
    },
    INDEXABLE: {
        id: 'indexable',
        description: '',
        defaultMessage: 'Indexable',
    },
    SEARCHABLE: {
        id: 'searchable',
        description: '',
        defaultMessage: 'Searchable',
    },
    FILTERABLE: {
        id: 'filterable',
        description: '',
        defaultMessage: 'Filterable',
    },
    EXACT_MATCH: {
        id: 'exact_match',
        description: '',
        defaultMessage: 'Exact Match',
    },
    RETRIEVABLE: {
        id: 'retrievable',
        description: '',
        defaultMessage: 'Retrievable',
    },
    PART_NUMBER: {
        id: 'part_number',
        description: '',
        defaultMessage: 'Part Number',
    },
    IN_USE: {
        id: 'in_use',
        description: '',
        defaultMessage: 'In Use',
    },
    TAG_MANAGEMENT_DESCRIPTION: {
        id: 'tag_management.description',
        description: '',
        defaultMessage: 'Tags can be used on any configuration (rules, biasing strategies, redirects etc…) to organize and more easily find related groups of configurations. Create and use meaningful tags for a smoother configuration management experience.',
    },
    TAG_MANAGEMENT_NEW: {
        id: 'tag_management.new',
        description: '',
        defaultMessage: 'New Tag',
    },
    PRODUCT_LIBRARY: {
        id: 'product_library',
        description: '',
        defaultMessage: 'Product library',
    },
    BY_CATEGORY: {
        id: 'by_category',
        description: '',
        defaultMessage: 'By category',
    },
    BY_TAXONOMY: {
        id: 'by_taxonomy',
        description: '',
        defaultMessage: 'By taxonomy',
    },
    TAXONOMY: {
        id: 'taxonomy',
        description: '',
        defaultMessage: 'Taxonomy',
    },
    SELECT_ORDER: {
        id: 'select_order',
        description: '',
        defaultMessage: 'Select Order',
    },
    ALL_ACTIONS: {
        id: 'all_actions',
        description: '',
        defaultMessage: 'All Actions',
    },
    SEARCH_ACTIONS: {
        id: 'search_actions',
        description: '',
        defaultMessage: 'Search Actions',
    },
    ACTION_SELECTION: {
        id: 'action_selection',
        description: '',
        defaultMessage: 'Action Selection',
    },
    ENABLE_MULTISELECT: {
        id: 'enable_multiselect',
        description: '',
        defaultMessage: 'Enable Multi-select',
    },
    FIELDS_PROPERTYNAME_REQUIRED: {
        id: 'fields.property_name.required',
        description: '',
        defaultMessage: 'Property Name (required)',
    },
    FIELDS_DISPLAYNAME_REQUIRED: {
        id: 'fields.displayname.required',
        description: '',
        defaultMessage: 'Display Name (required)',
    },
    DISPLAY_NAME: {
        id: 'display.name',
        description: '',
        defaultMessage: 'Display Name',
    },
    SELECT_PARENT: {
        id: 'select_parent',
        description: '',
        defaultMessage: 'Select Parent',
    },
    PARENT_CATEGORY: {
        id: 'parent_category',
        description: '',
        defaultMessage: 'Parent',
    },
    FIELDS_NAME_REQUIRED: {
        id: 'fields.name.required',
        description: '',
        defaultMessage: 'Name (required)',
    },
    MUST_CONTAIN_ONLY_LETTERS_NUMBERS_UNDERSCORES: {
        id: 'only_letters_numbers_underscores',
        description: '',
        defaultMessage: 'Must contain only letters, numbers and underscores',
    },
    MUST_CONTAIN_ONLY_LOWERCASE_LETTERS_NUMBERS_UNDERSCORES_DASHES: {
        id: 'only.lowercase.letters.numbers.underscores.dashes',
        description: '',
        defaultMessage: 'Must contain only lowercase letters, numbers, underscores, or dashes.',
    },
    NAME: {
        id: 'name',
        description: '',
        defaultMessage: 'Name',
    },
    ADMINISTRATOR: {
        id: 'administrator',
        description: '',
        defaultMessage: 'Administrator',
    },
    ADMINISTRATOR_NO_ROLE_CHANGE: {
        id: 'administrator_no_role_change',
        description: '',
        defaultMessage: 'The Administrator can\'t change the role',
    },
    LAST_MODIFIED: {
        id: 'last_modified',
        description: '',
        defaultMessage: 'Last Modified',
    },
    MODIFIED_BY: {
        id: 'modified_by',
        description: '',
        defaultMessage: 'Modified By',
    },
    CREATED_BY: {
        id: 'created_by',
        description: '',
        defaultMessage: 'Created By',
    },
    CREATED_DATE: {
        id: 'created_date',
        description: '',
        defaultMessage: 'Created date',
    },
    TYPE: {
        id: 'type',
        description: '',
        defaultMessage: 'Type',
    },
    MULTI_SELECT: {
        id: 'multi_select',
        description: '',
        defaultMessage: 'Multi-Select',
    },
    KEY: {
        id: 'key',
        description: '',
        defaultMessage: 'Key',
    },
    VALUE: {
        id: 'value',
        description: '',
        defaultMessage: 'Value',
    },
    PRIORITY: {
        id: 'priority',
        description: '',
        defaultMessage: 'Priority',
    },
    GLOBAL_TAXONOMY: {
        id: 'global_taxonomy',
        description: '',
        defaultMessage: 'Global taxonomy',
    },
    GLOBAL: {
        id: 'global',
        description: '',
        defaultMessage: 'Global',
    },
    RULE_LIBRARY: {
        id: 'rule_library',
        description: '',
        defaultMessage: 'Rule library',
    },
    REPORTING: {
        id: 'reporting',
        description: '',
        defaultMessage: 'Reporting',
    },
    DASHBOARD: {
        id: 'dashboard',
        description: '',
        defaultMessage: 'Dashboard',
    },
    DESCENDING: {
        id: 'descending',
        description: '',
        defaultMessage: 'Descending',
    },
    ASCENDING: {
        id: 'ascending',
        description: '',
        defaultMessage: 'Ascending',
    },
    COUNT_ASCENDING: {
        id: 'count_ascending',
        description: '',
        defaultMessage: 'Count Ascending',
    },
    COUNT_DESCENDING: {
        id: 'count_descending',
        description: '',
        defaultMessage: 'Count Descending',
    },
    VALUE_ASCENDING: {
        id: 'value_ascending',
        description: '',
        defaultMessage: 'Value Ascending',
    },
    VALUE_DESCENDING: {
        id: 'value_descending',
        description: '',
        defaultMessage: 'Value Descending',
    },
    RANGE: {
        id: 'range',
        description: '',
        defaultMessage: 'Range',
    },
    RULES_DESCRIPTION: {
        id: 'rules.description',
        description: '',
        defaultMessage: 'Use Rules to curate search result sets for better shopping experiences. Rules can be triggered from search, navigation, and no-results states. Make sure to stack your high-priority Rules at the top.',
    },
    RULE_CREATE_NEW: {
        id: 'rule.create.new',
        description: '',
        defaultMessage: 'New Rule',
    },
    RULE_COPYTOAREA_TOOLTIP: {
        id: 'rule.copytoarea.tooltip',
        description: '',
        defaultMessage: 'This is the area you want copy your rule to',
    },
    RULE: {
        id: 'rule',
        description: '',
        defaultMessage: 'Rule',
    },
    RULE_NUMSELECTED: {
        id: 'rule.numselected',
        description: '',
        defaultMessage: '{numSelected} Rules Selected',
    },
    RULE_CREATE_TITLE: {
        id: 'rule.create.title',
        description: '',
        defaultMessage: 'Build A Rule',
    },
    RULE_CREATE_NAME_LABEL: {
        id: 'rule.create.name_label',
        description: '',
        defaultMessage: 'Rule Name (required)',
    },
    RULE_CREATE_NAME_HELPERTEXT: {
        id: 'rule.create.name_helperText',
        description: '',
        defaultMessage: 'Ex. Search > Brands > Nike > July Promote OS',
    },
    RULE_CREATE_DATE_RANGE_LABEL: {
        id: 'rule.create.date_range.label',
        description: '',
        defaultMessage: 'Add start & end date-time',
    },
    RULE_CREATE_DATE_RANGE_START_LABEL: {
        id: 'rule.create.date_range.start_label',
        description: '',
        defaultMessage: 'Start Date',
    },
    RULE_CREATE_DATE_RANGE_END_LABEL: {
        id: 'rule.create.date_range.end_label',
        description: '',
        defaultMessage: 'End Date',
    },
    RULE_CREATE_DATE_RANGE_END_TIME_LABEL: {
        id: 'rule.create.date_range.end_time_label',
        description: '',
        defaultMessage: 'End Time',
    },
    RULE_CREATE_DATE_RANGE_START_TIME_LABEL: {
        id: 'rule.create.date_range.start_time_label',
        description: '',
        defaultMessage: 'Start Time',
    },
    RULE_CREATE_SETUP: {
        id: 'rule.create.setup',
        description: '',
        defaultMessage: 'Rule Setup',
    },
    RULE_CREATE_TRIGGERS: {
        id: 'rule.create.triggers',
        description: '',
        defaultMessage: 'Triggers',
    },
    RULE_CREATE_TRIGGERS_DUPLICATE_TOOLTIP: {
        id: 'rule.create.triggers.duplicate_tooltip',
        description: '',
        defaultMessage: 'Duplicate this trigger type',
    },
    RULE_CREATE_TRIGGER_SET_DUPLICATE_TOOLTIP: {
        id: 'rule.create.triggers.duplicate_set_tooltip',
        description: '',
        defaultMessage: 'Duplicate this trigger set',
    },
    RULE_CREATE_REFINE_ADDITONAL_FILTERS_TITLE: {
        id: 'rule.create.refine_additional_filters_title',
        description: '',
        defaultMessage: 'Refine with Additional Filters',
    },
    RULE_CREATE_REFINE_ADDITONAL_FILTERS_DESCRIPTION: {
        id: 'rule.create.refine_additional_filters_description',
        description: '',
        defaultMessage: 'Add filters to restrict and reduce results at the rule level, without affecting your other searches.',
    },
    RULE_CREATE_NAVIGATIONS_DESCRIPTION: {
        id: 'rule.create.navigations_description',
        description: '',
        defaultMessage: 'Navigations let your customers refine their search or browse experience. Here, you can add the navigations you’d like them to see. When Pinned, these will override the Navigation Management set.',
    },
    RULE_CREATE_NAVIGATIONS_BROWSE: {
        id: 'rule.create.navigations_browse',
        description: '',
        defaultMessage: 'Browse All Navigations',
    },
    RULE_CREATE_NAVIGATIONS_TIP: {
        id: 'rule.create.navigations_help',
        description: '',
        defaultMessage: 'If you don’t pin any navigations here, the sets that are defined in Navigation Management will be returned.',
    },
    RULE_CREATE_NAVIGATIONS_NAVIGATION_MANAGEMENT: {
        id: 'rule.create.navigations_navigation_management',
        description: '',
        defaultMessage: 'Navigation Management',
    },
    RULE_CREATE_NAVIGATIONS_PINNED_REFINEMENTS_DESCRIPTION: {
        id: 'rule.create.navigations_pinned_refinements_description',
        description: '',
        defaultMessage: '<b>Pinned Refinements:</b> Refinements (facets) allow users to narrow down the set of products that they\'re shown. Refinements (facet values) can be pinned here to the Navigation (filters) that have been added to the rule. You can also drag and drop the pinned refinements here to specify the order that they will appear on your website.',
    },
    RULE_CREATE_NAVIGATIONS_INSTRUCTION: {
        id: 'rule.create.navigations_instructions',
        description: '',
        defaultMessage: '<b>Pin Navigations and Refinements:</b> When “Pin Navigation” checkbox is selected, start typing a navigation name to see options or browse and select navigations to add. The Navigation will be pinned in the order that it is added here and can be adjusted. The refinements added to the navigation will also be pinned in their specified order. Note: This option will override the Navigation Management set at the area level from Merchandizing → Navigations.',
    },
    RULE_CREATE_NO_NAVIGATIONS_ADDED: {
        id: 'rule.create.navigations_not_added',
        description: '',
        defaultMessage: 'No navigations added yet',
    },
    RULE_CREATE_NAVIGATIONS_ADD: {
        id: 'rule.create.navigations_add',
        description: '',
        defaultMessage: 'Add Navigation',
    },
    RULE_CREATE_NAVIGATIONS_ADD_MULTI: {
        id: 'rule.create.navigations_add_multi',
        description: '',
        defaultMessage: 'Add Navigations',
    },
    RULE_CREATE_NAVIGATIONS_COUNT: {
        id: 'rule.create.navigations_count',
        description: '',
        defaultMessage: '{count} Navigations',
    },
    RULE_CREATE_ENABLE_EXACT_MATCHING_LABEL: {
        id: 'rule.create.enable_exact_matching.label',
        description: '',
        defaultMessage: 'Stop content being shown as shopper selects additional filters.',
    },
    RULE_FORM_PAGE_LAYOUT_DESCRIPTION: {
        id: 'rule.form.page_layout.description',
        description: '',
        defaultMessage: 'Content management allows you to define the format of a search results page by choosing a template.',
    },
    RULE_FORM_PAGE_LAYOUT_DESC2: {
        id: 'rule.form.page_layout.description2',
        description: '',
        defaultMessage: 'Each page layout is made up of a template and zones. A template is a pre-made framework that brings additional content to the results page, while zones allow you to customize the content.',
    },
    FORM_CHOOSE_FROM_DROPDOWN: {
        id: 'form.choose_dropdown',
        description: '',
        defaultMessage: 'Select a pre-existing zone',
    },
    CUSTOM_VALUE_PLACEHOLDER: {
        id: 'custom_value.placeholder',
        description: '',
        defaultMessage: 'Write content here...',
    },
    RULE_DELETE: {
        id: 'rule.delete',
        description: '',
        defaultMessage: 'Delete Rule',
    },
    RULE_EDIT_TITLE: {
        id: 'rule.edit.title',
        description: '',
        defaultMessage: 'Edit Rule',
    },
    TRIGGER_TYPE: {
        id: 'trigger_type',
        defaultMessage: 'Trigger type',
        description: '',
    },
    COPY_TRIGGER_TYPE_TOOLTIP: {
        id: 'trigger_type.copy.tooltip',
        defaultMessage: 'Copy this trigger type...',
        description: '',
    },
    DELETE_TRIGGER_TYPE_TOOLTIP: {
        id: 'trigger_type.delete.tooltip',
        defaultMessage: 'Delete this trigger type...',
        description: '',
    },
    TRIGGER_SET_DEFAULT: {
        id: 'triggers.trigger_set.default',
        description: '',
        defaultMessage: 'Trigger Set 1',
    },
    TRIGGER: {
        id: 'trigger',
        description: '',
        defaultMessage: 'Trigger',
    },
    TRIGGERS: {
        id: 'triggers',
        description: '',
        defaultMessage: 'Triggers',
    },
    TRIGGER_SET_INDEX: {
        id: 'triggers.trigger_set.index',
        description: '',
        defaultMessage: 'Trigger Set {index}',
    },
    TRIGGER_SET: {
        id: 'trigger_set',
        description: '',
        defaultMessage: 'Trigger Set',
    },
    RULE_CREATE_TRIGGER_SET_DELETE_TOOLTIP: {
        id: 'rule.create.triggers.delete_set_tooltip',
        description: '',
        defaultMessage: 'Delete this trigger set',
    },
    RULE_CREATE_TRIGGERS_DELETE_TOOLTIP: {
        id: 'rule.create.delete_tooltip',
        description: '',
        defaultMessage: 'Delete this trigger type',
    },
    RULE_CREATE_TRIGGERS_SEARCH_MATCHES: {
        id: 'rule.create.triggers.search_matches',
        description: '',
        defaultMessage: 'Search Matches',
    },
    RULE_CREATE_TRIGGERS_NAVIGATION_SELECTED: {
        id: 'rule.create.triggers.navigation_selected',
        description: '',
        defaultMessage: 'Navigation Selected',
    },
    RULE_CREATE_TRIGGERS_NAVIGATION_VALUE: {
        id: 'rule.create.triggers.navigation_value',
        description: '',
        defaultMessage: 'Navigation Value',
    },
    RULE_CREATE_TRIGGERS_NAVIGATION_VALUE_LABEL: {
        id: 'rule.create.triggers.navigation_value.label',
        description: '',
        defaultMessage: 'Selected with',
    },
    RULE_CREATE_TRIGGERS_NAVIGATION_VALUE_VALUE: {
        id: 'rule.create.triggers.navigation_value.value',
        description: '',
        defaultMessage: 'and the value',
    },
    RULE_CREATE_TRIGGERS_CUSTOM_PARAMETER: {
        id: 'rule.create.triggers.custom_parameter',
        description: '',
        defaultMessage: 'Custom Parameter',
    },
    RULE_CREATE_TRIGGERS_SEARCH_MATCHES_LABEL: {
        id: 'rule.create.triggers.search_matches_label',
        description: '',
        defaultMessage: 'One of these value(s)',
    },
    RULE_CREATE_TRIGGERS_SEARCH_MATCHES_HELPERTEXT: {
        id: 'rule.create.triggers.search_matches_helpertext',
        description: '',
        defaultMessage: 'E.g. red cashmere sweater',
    },
    RULE_CREATE_TRIGGERS_SEARCH_CONTAINS: {
        id: 'rule.create.triggers.search_contains',
        description: '',
        defaultMessage: 'Search Contains',
    },
    RULE_CREATE_TRIGGERS_SEARCH_STARTS_WITH: {
        id: 'rule.create.triggers.search_starts_with',
        description: '',
        defaultMessage: 'Search Starts With',
    },
    RULE_CREATE_TRIGGERS_SEARCH_STARTS_WITH_HELPER: {
        id: 'rule.create.triggers.search_starts_with.helper',
        description: '',
        defaultMessage: 'E.g. if the query is Nike, then the rule will trigger when the user searches for Nike Games',
    },
    RULE_CREATE_TRIGGERS_SEARCH_ENDS_WITH: {
        id: 'rule.create.triggers.search_ends_with',
        description: '',
        defaultMessage: 'Search Ends With',
    },
    RULE_CREATE_TRIGGERS_SEARCH_REGEX: {
        id: 'rule.create.triggers.search_regex',
        description: '',
        defaultMessage: 'Search Regex',
    },
    RULE_CREATE_TRIGGERS_DESCRIPTION: {
        id: 'rule.create.triggers.description',
        description: '',
        defaultMessage: 'You should add Triggers to define when a rule will fire based on <accent>if this then that</accent> conditions (ex. if the query is Nike, then the Rule will be triggered when the customer searches Nike shoes).',
    },
    RULE_CREATE_TRIGGERS_TIP: {
        id: 'rule.create.triggers.tip',
        description: '',
        defaultMessage: 'Keep in mind that triggers are <accent>and scenarios</accent>, while trigger sets are <accent>or scenarios</accent>.',
    },
    RULE_CREATE_RESULT_MANAGEMENT: {
        id: 'rule.create.result_management',
        description: '',
        defaultMessage: 'Result Management',
    },
    RULE_CREATE_RESULT_MANAGEMENT_DESCRIPTION: {
        id: 'rule.create.result_management.description',
        description: '',
        defaultMessage: 'Control how your products are returned, ranked and sorted, and refine with filters to narrow down results.',
    },
    RULE_RECALL_DESCRIPTION: {
        id: 'rule.recall.description',
        description: '',
        defaultMessage: 'Build your page with:',
    },
    RULE_RECALL_DESCRIPTION_OLD: {
        id: 'rule.recall.description_old',
        description: '',
        defaultMessage: 'Curate results by including or excluding products/attributes.',
    },
    TIERED_BASED_BOOST: {
        id: 'tiered_based_boost',
        description: '',
        defaultMessage: 'Add Products per Tier',
    },
    TIERED_BASED_BOOST_HINT: {
        id: 'tiered_based_boost.hint',
        description: '',
        defaultMessage: 'Tiers are ordered from highest boosted products to the lowest boost.',
    },
    TIERED_BASED_BURY: {
        id: 'tiered_based_bury',
        description: '',
        defaultMessage: 'Select Product IDs To Bury',
    },
    BURY: {
        id: 'bury',
        description: '',
        defaultMessage: 'Bury',
    },
    PRODUCT: {
        id: 'product',
        description: '',
        defaultMessage: 'Product',
    },
    PRODUCT_BOOST: {
        id: 'product_boost',
        description: '',
        defaultMessage: 'Boost Product',
    },
    PINNED_PRODUCT: {
        id: 'pinned_products',
        description: '',
        defaultMessage: 'Pinned Product',
    },
    PINNED_PRODUCT_TIP: {
        id: 'pinned_products_tip',
        description: '',
        defaultMessage: 'Pinning products guarantees the position you set and ensures this products inclusion in the recall. Input the product ID and specify the desired slot number from 1 to 24. When activating a rule utilizing Pin Product, a second query is fired, and this additional query is counted against your quota.',
    },
    PINNED_PRODUCT_OOS_TOOLTIP: {
        id: 'pinned_products_oos_tooltip',
        description: '',
        defaultMessage: 'When off, products are automatically unpinned when they go Out Of Stock (OOS). When on, products will remain pinned even when they go OOS.',
    },
    PINNED_PRODUCT_OUT_OF_STOCK: {
        id: 'pinned_products_out_of_stock',
        description: '',
        defaultMessage: 'Pin when out of stock',
    },
    PINNED_PRODUCT_POSITION_TITLE: {
        id: 'pinned_products_position_title',
        description: '',
        defaultMessage: 'Position',
    },
    PINNED_PRODUCT_PRODUCT_ID_TITLE: {
        id: 'pinned_products_product_id_title',
        description: '',
        defaultMessage: 'Product ID',
    },
    PINNED_PRODUCT_ADD_PRODUCT: {
        id: 'pinned_products_add_product',
        description: '',
        defaultMessage: 'Add Product',
    },
    PRODUCT_BURY: {
        id: 'product_bury',
        description: '',
        defaultMessage: 'Bury Product',
    },
    PRODUCT_BOOST_AND_BURY: {
        id: 'product_boost_and_bury',
        description: '',
        defaultMessage: 'Boost & Bury',
    },
    RANKING: {
        id: 'ranking',
        description: '',
        defaultMessage: 'Ranking',
    },
    RANKING_TOOLTIP: {
        id: 'ranking_tooltip',
        description: '',
        defaultMessage: 'Customize what order the results set comes back using pinning, boosting, biasing, and burying',
    },
    PRODUCT_IDS: {
        id: 'product_ids',
        description: '',
        defaultMessage: 'Product IDs',
    },
    SEARCH_PRODUCT: {
        id: 'search_product',
        description: '',
        defaultMessage: 'Product',
    },
    SEARCH_PRODUCT_OLD: {
        id: 'search_product_old',
        description: '',
        defaultMessage: 'Search Product',
    },
    NO_PRODUCT_FOUND: {
        id: 'no_product_found',
        description: '',
        defaultMessage: 'No product found',
    },
    PRODUCT_BOOST_TIP: {
        id: 'product_boost.tip',
        description: '',
        defaultMessage: 'Products are boosted based on their tier as shown below (8 tiers). Product IDs in the first tier will receive the highest boost.',
    },
    PRODUCT_BURY_TIP: {
        id: 'product_bury.tip',
        description: '',
        defaultMessage: 'Buried Product IDs receive a negative boost, causing them to be lowered in the ranking.',
    },
    PRODUCT_BOOST_TIP_2: {
        id: 'product_boost.tip.2',
        description: '',
        defaultMessage: 'Max 20 products per tier.',
    },
    TIER: {
        id: 'tier',
        description: '',
        defaultMessage: 'Tier',
    },
    TIER_NUM: {
        id: 'tier_num',
        description: '',
        defaultMessage: 'Tier {num}',
    },
    PRODUCT_BOOST_TIER_INPUT_HELPER: {
        id: 'product_boost_tier.input_helper',
        description: '',
        defaultMessage: 'Use commas to separate values {Link} Max 20 products per tier.',
    },
    RULE_CREATE_REFINEMENT_DELETE_TOOLTIP: {
        id: 'rule.create.delete_refinement_tooltip',
        description: '',
        defaultMessage: 'Delete this refinement',
    },
    RULE_CREATE_REFINEMENT_ADD_FILTER: {
        id: 'rule.create.add_filter',
        description: '',
        defaultMessage: 'Add Filter Type',
    },
    RULE_CREATE_SEARCH_REFINEMENT: {
        id: 'rule.create.search_refinement',
        description: '',
        defaultMessage: 'Search',
    },
    RULE_CREATE_FILTERED_BY_REFINEMENT: {
        id: 'rule.create.filtered_by_refinement',
        description: '',
        defaultMessage: 'Filtered By',
    },
    RULE_CREATE_RANGE_REFINEMENT: {
        id: 'rule.create.range_refinement',
        description: '',
        defaultMessage: 'Range is between',
    },
    RULE_CREATE_FILTERED_BY_EXCLUSION_REFINEMENT: {
        id: 'rule.create.filtered_by_exclusion_refinement',
        description: '',
        defaultMessage: 'Filtered by exclusion',
    },
    RULE_CREATE_ADD_TRIGER_TYPE: {
        id: 'rule.create.add_trigger_type',
        description: '',
        defaultMessage: 'Add Trigger Type',
    },
    SEARCH_INPUT_RESULTS_LABEL: {
        id: 'search_input.results_label',
        description: '',
        defaultMessage: '{numberOfResults} {modelType}',
    },
    THE_VALUE: {
        id: 'the_value',
        description: '',
        defaultMessage: 'The Value',
    },
    THE_VALUES: {
        id: 'the_values',
        description: '',
        defaultMessage: 'The Values',
    },
    TRIGGER_SEARCH_ENDS_HELPER_TEXT: {
        id: 'triggers.search_ends.helper_text',
        description: '',
        defaultMessage: 'E.g. if the query is shoes, then the rule will trigger when the user searches for Nike shoe',
    },
    TRIGGER_SEARCH_REGEX_HELPER_TEXT: {
        id: 'triggers.search_regex.helper_text',
        description: '',
        defaultMessage: 'Regex must match the entire search string',
    },
    TRIGGER_SEARCH_HELPER_TEXT: {
        id: 'triggers.search.helper_text',
        description: '',
        defaultMessage: 'Enter search value',
    },
    TRIGGER_ATTRIBUTE_FIELD_LABEL: {
        id: 'triggers.attribute_field_label',
        description: '',
        defaultMessage: 'Selected With',
    },
    TRIGGER_ATTRIBUTE_FIELD_HELPER_TEXT: {
        id: 'triggers.attribute_field.helper_text',
        description: '',
        defaultMessage: 'Select an attribute',
    },
    TRIGGER_NAVIGATION_VALUE_HELPER_TEXT: {
        id: 'triggers.navigaton_value.helper_text',
        description: '',
        defaultMessage: 'E.g. Hugo Boss, red, women\'s, etc.',
    },
    TRIGGER_NAVIGATION_RANGE: {
        id: 'triggers.navigaton_range',
        description: '',
        defaultMessage: 'Navigation Range',
    },
    TRIGGER_NAVIGATION_NAME: {
        id: 'triggers.navigaton_name',
        description: '',
        defaultMessage: 'Navigation Name',
    },
    TRIGGER_NAVIGATION_EXAMPLE: {
        id: 'triggers.navigaton_example',
        description: '',
        defaultMessage: 'Ex. Color',
    },
    TRIGGER_NAVIGATION_SELECTED: {
        id: 'triggers.navigaton_selected',
        description: '',
        defaultMessage: 'Navigation Selected',
    },
    TRIGGER_REFINEMENT_LABEL: {
        id: 'triggers.refinement_label',
        description: '',
        defaultMessage: 'Refinement Name',
    },
    FILTER_REFINEMENT_VALUE_LABEL: {
        id: 'triggers.refinement_value_label',
        description: '',
        defaultMessage: 'Refinement Value',
    },
    FILTER_REFINEMENT_TYPE: {
        id: 'filters.refinement_type',
        description: '',
        defaultMessage: 'Refinement Type',
    },
    FILTER_REFINEMENT_VALUE_EXAMPLE: {
        id: 'filters.refinement_value_example',
        description: '',
        defaultMessage: 'Ex. 20',
    },
    TRIGGER_REFINEMENT_EXAMPLE: {
        id: 'triggers.refinement_example',
        description: '',
        defaultMessage: 'Ex. Red',
    },
    TRIGGER_NO_NAVIGATION_EVENT: {
        id: 'triggers.no_navigation_event',
        description: '',
        defaultMessage: 'No Navigation Event',
    },
    TRIGGER_NO_NAVIGATION_EVENT_COPY: {
        id: 'triggers.no_navigation_event.copy',
        description: '',
        defaultMessage: 'No navigation event occurs',
    },
    TRIGGER_NO_RESULT_EVENT: {
        id: 'triggers.no_result_event',
        description: '',
        defaultMessage: 'No Result Event',
    },
    TRIGGER_NO_RESULT_EVENT_COPY: {
        id: 'triggers.no_result_event.copy',
        description: '',
        defaultMessage: 'No result event occurs',
    },
    TRIGGER_CUSTOM_PARAMETER_KEY_LABEL: {
        id: 'triggers.custom_parameter.key_label',
        description: '',
        defaultMessage: 'Occurs with the key',
    },
    TRIGGER_CUSTOM_PARAMETER_KEY_HELPER: {
        id: 'triggers.custom_parameter.helper',
        description: '',
        defaultMessage: 'E.g. URL Parameter Key',
    },
    APPLY_TO_PREVIEW: {
        id: 'triggers.apply_to_preview',
        description: '',
        defaultMessage: 'Apply to preview',
    },
    FROM: {
        id: 'from',
        description: '',
        defaultMessage: 'From',
    },
    TO: {
        id: 'to',
        description: '',
        defaultMessage: 'To',
    },
    DATE_RANGE_SEPARATE_LABEL: {
        id: 'date_range.separate_label',
        description: '',
        defaultMessage: '<col>{dateFrom}</col> <label>To</label> <col>{dateTo}</col>',
    },
    LANGUAGE_SELECTION: {
        id: 'user_menu.language_selection',
        description: '',
        defaultMessage: '<label>Language</label> <lang>{language}</lang>',
    },
    DEFAULT_RELEVANCY: {
        id: 'default_relevancy',
        description: '',
        defaultMessage: 'Default Relevancy',
    },
    NO_BIASING_STRATEGY: {
        id: 'no_biasing_strategy',
        description: '',
        defaultMessage: 'No Biasing Strategy',
    },
    DEFAULT_STRATEGY: {
        id: 'default_strategy',
        description: '',
        defaultMessage: 'Default Strategy',
    },
    BIASING_STRATEGY_DELETE: {
        id: 'biasing_strategy.delete',
        description: '',
        defaultMessage: 'Delete Biasing Strategy',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE: {
        id: 'biasing_strategy.verification_delete',
        description: '',
        defaultMessage: 'Are you sure you want to delete the biasing strategy?',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE_FAIL: {
        id: 'biasing_strategy.verification_delete_fail',
        description: '',
        defaultMessage: 'Task "Delete Biasing Strategy" failed',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE_DESCRIPTION: {
        id: 'biasing_strategy.verification_delete.description',
        description: '',
        defaultMessage: '<b>{name}</b> is currently in use in <b>{count}</b> Rules',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE_DESCRIPTION_WITH_AREA: {
        id: 'biasing_strategy.verification_delete.description_with_area',
        description: '',
        defaultMessage: '<b>{name}</b> is currently in use as an area default and it is being used in <b>{count}</b> Rules',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE_WARNING_RULES: {
        id: 'biasing_strategy.verification_delete.description_warning_rules',
        description: '',
        defaultMessage: '<b>{name}</b> will be removed from the following <b>{count}</b> Rule(s):',
    },
    BIASING_STRATEGY_VERIFICATION_DELETE_WARNING_AREAS: {
        id: 'biasing_strategy.verification_delete.description_warning_areas',
        description: '',
        defaultMessage: '<b>{name}</b> will be removed from the following <b>{count}</b> Area(s)',
    },
    BIASINGPROFILE_COPYTOAREA_TOOLTIP: {
        id: 'biasing_profile.copytoarea.tooltip',
        description: '',
        defaultMessage: 'This is the area you want copy your biasing strategy to',
    },
    NAVIGATIONS: {
        id: 'navigations',
        description: '',
        defaultMessage: 'Navigations',
    },
    DYNAMIC_NAVIGATION: {
        id: 'dynamic_navigation',
        description: '',
        defaultMessage: 'Navigations',
    },
    DYNAMIC_NAVIGATION_DESCRIPTION: {
        id: 'dynamic_navigation.description',
        description: '',
        defaultMessage: 'Navigations allow your user to filter their results by providing possible filters, along with count of available products.',
    },
    DYNAMIC_NAVIGATION_NUMSELECTED: {
        id: 'dynamic_navigation.numselected',
        description: '',
        defaultMessage: '{numSelected} Navigations Selected',
    },
    DYNAMIC_NAVIGATION_NAMEYOUR: {
        id: 'dynamic_navigation.name_your_navigation',
        description: '',
        defaultMessage: 'Name Your Navigation',
    },
    DYNAMIC_NAVIGATION_NAMEMEMORABLE: {
        id: 'dynamic_navigation.choose_memorable_name',
        description: '',
        defaultMessage: 'Choose a memorable name that will appear in the Rules and Area Management dropdown menus.',
    },
    DYNAMIC_NAVIGATION_PROPERTY_NAME_HELP: {
        id: 'dynamic_navigation.namehelp',
        description: '',
        defaultMessage: "The exact match of a record's property name from which to make a navigation entry (case-sensitive).",
    },
    DYNAMIC_NAVIGATION_PROPERTY_DISPLAYNAME_HELP: {
        id: 'dynamic_navigation.displaynamehelp',
        description: '',
        defaultMessage: 'The display-friendly name that should appear in navigation components and breadcrumbs.',
    },
    DYNAMIC_NAVIGATION_EDIT: {
        id: 'dynamic_navigation.edit',
        description: '',
        defaultMessage: 'Edit Navigation',
    },
    DYNAMIC_NAVIGATION_DELETE: {
        id: 'dynamic_navigation.delete',
        description: '',
        defaultMessage: 'Delete Navigation',
    },
    DYNAMIC_NAVIGATION_CREATE_NEW: {
        id: 'dynamic_navigation.create.new',
        description: '',
        defaultMessage: 'New Navigation',
    },
    DYNAMIC_NAVIGATION_CREATEONE: {
        id: 'dynamic_navigation.create',
        description: '',
        defaultMessage: 'Create a Navigation',
    },
    DYNAMIC_NAVIGATION_DEFINETYPE: {
        id: 'dynamic_navigation.definetypeandsortorder',
        description: '',
        defaultMessage: 'Define the Type & Sort Order',
    },
    DYNAMIC_NAVIGATION_CHOOSETYPE: {
        id: 'dynamic_navigation.choosetypeandsortorder',
        description: '',
        defaultMessage: 'Choose the type of navigation and its sorting order.',
    },
    PIN_NAVIGATIONS: {
        id: 'dynamic_navigation.pin_navigations',
        description: '',
        defaultMessage: 'Pin Navigations',
    },
    PIN_REFINEMENTS: {
        id: 'dynamic_navigation.pin_refinements',
        description: '',
        defaultMessage: 'Pin Refinements',
    },
    DYNAMIC_NAVIGATION_REFINEMENTS_DESCRIPTION: {
        id: 'dynamic_navigation.refinements_description',
        description: '',
        defaultMessage: 'Refinements (facet values) can be pinned here to this Navigation (filter). You can also drag and drop the pinned refinements here to specify the order that they will appear on your website.',
    },
    DYNAMIC_NAVIGATION_REFINEMENT_PLACEHOLDER: {
        id: 'dynamic_navigation.refinement_placeholder',
        description: '',
        defaultMessage: 'Add refinement value to pin it',
    },
    DYNAMIC_NAVIGATION_REFINEMENT_BUTTON: {
        id: 'dynamic_navigation.refinement_button',
        description: '',
        defaultMessage: 'Add refinement',
    },
    DYNAMIC_NAVIGATION_NO_REFINEMENTS: {
        id: 'dynamic_navigation.no_refinements',
        description: '',
        defaultMessage: 'No refinement added yet',
    },
    DYNAMIC_NAVIGATION_EMPTY_REFINEMENT_VALUE: {
        id: 'dynamic_navigation.empty_refinement_value',
        description: '',
        defaultMessage: 'Value of refinement can\'t be empty',
    },
    DYNAMIC_NAVIGATION_DUPLICATE_REFINEMENT: {
        id: 'dynamic_navigation.duplicate_refinement',
        description: '',
        defaultMessage: 'This refinement has already been added',
    },
    NAVIGATION_COPYTOAREA_TOOLTIP: {
        id: 'navigation.copytoarea.tooltip',
        description: '',
        defaultMessage: 'This is the area you want copy your navigation to',
    },
    REDIRECT_DELETE: {
        id: 'redirect.delete',
        description: '',
        defaultMessage: 'Delete Redirect',
    },
    REDIRECT_CREATE_NEW: {
        id: 'redirect.create.new',
        description: '',
        defaultMessage: 'New Redirect',
    },
    REDIRECT_CREATE_TITLE: {
        id: 'redirect.create_title',
        description: '',
        defaultMessage: 'Build a Redirect',
    },
    REDIRECT_CREATE_NAME: {
        id: 'redirect.create_name',
        description: '',
        defaultMessage: 'Name Your Redirect',
    },
    REDIRECT_CREATE_NAME_LABEL: {
        id: 'redirect.create_name_label',
        description: '',
        defaultMessage: 'Redirect Name (required)',
    },
    REDIRECT_CREATE_NAME_DESCRIPTION: {
        id: 'redirect.create_name_description',
        description: '',
        defaultMessage: 'Choose a name for your redirect strategy.',
    },
    REDIRECT_CREATE_TRIGGER_TERMS_HELPER: {
        id: 'redirect.create.trigger_terms.helper',
        description: '',
        defaultMessage: 'Add the search term(s) you want to redirect from.',
    },
    REDIRECT_CREATE_TRIGGER_TERMS: {
        id: 'redirect.create.trigger_terms',
        description: '',
        defaultMessage: 'Trigger terms (from)',
    },
    REDIRECT_CREATE_TRIGGER_TERMS_LABEL: {
        id: 'redirect.create.trigger_terms.label',
        description: '',
        defaultMessage: 'Trigger Terms',
    },
    SEPARATE_VALUES_HELPER: {
        id: 'separate_values.input_helper',
        description: '',
        defaultMessage: 'Use commas to separate values.',
    },
    FORMAT_SPECIFIC_VALUES_LINK: {
        id: 'link.format_specific_values',
        description: '',
        defaultMessage: 'See how to format specific values.',
    },
    REDIRECT_CREATE_URL_TITLE: {
        id: 'redirect.create.url.title',
        description: '',
        defaultMessage: 'Redirect URL (to)',
    },
    REDIRECT_CREATE_URL_HELPER: {
        id: 'redirect.create.url.helper',
        description: '',
        defaultMessage: 'Then, add the location you want to redirect your customers to.',
    },
    REDIRECT_CREATE_URL: {
        id: 'redirect.create.url',
        description: '',
        defaultMessage: 'Redirect URL',
    },
    REDIRECT_CREATE_URL_INPUT_HELPER: {
        id: 'redirect.create.url.input_helper',
        description: '',
        defaultMessage: 'Tip: Only enter the part of the URL that comes after the domain (ex. “/shipping.html”)',
    },
    REDIRECT_EDIT_TITLE: {
        id: 'redirect.edit_title',
        description: '',
        defaultMessage: 'Edit a Redirect',
    },
    REDIRECT_CREATE_DESCRIPTION: {
        id: 'redirect.create_description',
        description: '',
        defaultMessage: 'Create a Redirect here to redirect the user when specific terms are searched.',
    },
    REMEMBER_ME: {
        id: 'remember.me',
        defaultMessage: 'Remember me',
        description: '',
    },
    REDIRECT_COPYTOAREA_TOOLTIP: {
        id: 'redirect.copytoarea.tooltip',
        description: '',
        defaultMessage: 'This is the area you want copy your redirect to',
    },
    TASK_LOADANALYTICS_REMOVE_ERROR: {
        id: 'redirect.analytics.load.error',
        description: '',
        defaultMessage: 'Task "Load analytics" failed',
    },
    TASK_AREA_LOAD_ALL_ERROR: {
        id: 'task.area.load_all.error',
        description: '',
        defaultMessage: 'Task "Load areas" failed',
    },
    TASK_AREA_LOAD_ONE_ERROR: {
        id: 'task.area.load_one.error',
        description: '',
        defaultMessage: 'Task "Load area" failed',
    },
    TASK_LOAD_AUDIT_LOGS_ERROR: {
        id: 'task.audit_log.load_all.error',
        description: '',
        defaultMessage: 'Task "Load audit logs" failed',
    },
    TASK_LOAD_ATTRIBUTES_ERROR: {
        id: 'task.attributes.load_all.error',
        description: '',
        defaultMessage: 'Task "Load attributes" failed',
    },
    TASK_UPDATE_ATTRIBUTES_ERROR: {
        id: 'task.attributes.update.error',
        description: '',
        defaultMessage: 'Task "Update attributes" failed',
    },
    TASK_UPDATE_ATTRIBUTES_SUCCESS: {
        id: 'task.attributes.update.success',
        description: '',
        defaultMessage: 'Task "Update attributes" successful',
    },
    TASK_RULE_LOAD_ALL_ERROR: {
        id: 'task.rule.load_all.error',
        description: '',
        defaultMessage: 'Task "Load rules" failed',
    },
    TASK_RULE_LOAD_ONE_ERROR: {
        id: 'task.rule.load_one.error',
        description: '',
        defaultMessage: 'Task "Load rule" failed',
    },
    TASK_RULE_LOAD_ANALYTICS_ERROR: {
        id: 'task.rule.load_analytics.error',
        description: '',
        defaultMessage: 'Task "Load rule analytics" failed',
    },
    TASK_NAVIGATION_LOAD_ALL_ERROR: {
        id: 'task.navigation.load_all.error',
        description: '',
        defaultMessage: 'Task "Load navigations" failed',
    },
    TASK_BIASINGPROFILE_LOAD_ALL_ERROR: {
        id: 'task.biasingprofile.load_all.error',
        description: '',
        defaultMessage: 'Task "Load strategies" failed',
    },
    TASK_BIASINGPROFILE_LOAD_ONE_ERROR: {
        id: 'task.biasingprofile.load_one.error',
        description: '',
        defaultMessage: 'Task "Load strategy" failed',
    },
    TASK_REDIRECT_LOAD_ALL_ERROR: {
        id: 'task.redirect.load_all.error',
        description: '',
        defaultMessage: 'Task "Load redirects" failed',
    },
    TASK_REDIRECT_LOAD_ONE_ERROR: {
        id: 'task.redirect.load_one.error',
        description: '',
        defaultMessage: 'Task "Load redirect" failed',
    },
    TASK_TAG_LOAD_ALL_ERROR: {
        id: 'task.tag.load_all.error',
        description: '',
        defaultMessage: 'Task "Load tags" failed',
    },
    TASK_USER_LOAD_ALL_ERROR: {
        id: 'task.user.load_all.error',
        description: '',
        defaultMessage: 'Task "Load users" failed',
    },
    TASK_LINGUISTIC_CONTROLS_LOAD_ALL_ERROR: {
        id: 'task.linguistic.controls.load_all.error',
        description: '',
        defaultMessage: 'Task "Load linguistic controls" failed',
    },
    TASK_SITE_LOAD_ALL_ERROR: {
        id: 'task.site.load_all.error',
        description: '',
        defaultMessage: 'Task "Load sites" failed',
    },
    TASK_CATEGORIES_LOAD_ALL_ERROR: {
        id: 'task.categories.load_all.error',
        description: '',
        defaultMessage: 'Task "Load Categories" failed',
    },
    TASK_CATEGORIES_LOAD_ONE_ERROR: {
        id: 'task.categories.load_one.error',
        description: '',
        defaultMessage: 'Task "Load Megamenu Categories" failed',
    },
    TASK_CUSTOM_CATEGORY_UPDATE_ERROR: {
        id: 'task.custom_category.update.error',
        description: '',
        defaultMessage: 'Task "Update Custom Category" failed',
    },
    TASK_CUSTOM_CATEGORY_UPDATE_SUCCESS: {
        id: 'task.custom_category.update.success',
        description: '',
        defaultMessage: 'Task "Update Custom Category" completed!',
    },
    TASK_CUSTOM_CATEGORY_CREATE_ERROR: {
        id: 'task.custom_category.create.error',
        description: '',
        defaultMessage: 'Task "Update Custom Category" failed',
    },
    TASK_CUSTOM_CATEGORY_CREATE_SUCCESS: {
        id: 'task.custom_category.create.success',
        description: '',
        defaultMessage: 'Task "Create Custom Category" completed!',
    },
    TASK_CUSTOM_CATEGORY_LOAD_ONE_ERROR: {
        id: 'task.custom_category.load_one.error',
        description: '',
        defaultMessage: 'Task "Load Custom Category" failed',
    },
    TASK_CUSTOM_CATEGORY_DELETE_ERROR: {
        id: 'task.custom_category.delete.error',
        description: '',
        defaultMessage: 'Task "Delete Custom Category" failed',
    },
    TASK_CUSTOM_CATEGORY_DELETE_SUCCESS: {
        id: 'task.custom_category.delete.success',
        description: '',
        defaultMessage: 'Task "Delete Custom Category" completed!',
    },
    TASK_CANONICAL_CATEGORY_LOAD_ONE_ERROR: {
        id: 'task.canonical_category.load_one.error',
        description: '',
        defaultMessage: 'Task "Load Canonical Category" failed',
    },
    TASK_CATEGORY_HIERARCHY_LOAD_ERROR: {
        id: 'task.category_hierarchy.load.error',
        description: '',
        defaultMessage: 'Task "Load Category Hierarchy" failed',
    },
    PRODUCTS_AUTOCOMPLETE_SEARCH_INPUT_LOAD_ERROR: {
        id: 'products_autocomplete_search_input.load.error',
        description: '',
        defaultMessage: 'Products autocomplete failed',
    },
    PRODUCTS_AUTOCOMPLETE_SEARCH_IMAGE_META_DATA_ERROR: {
        id: 'products_autocomplete_search_input.image_meta_data.error',
        description: '',
        defaultMessage: 'Image metadata failed',
    },
    TASK_CATEGORY_DATA_LOAD_ERROR: {
        id: 'task.category_data.load.error',
        description: '',
        defaultMessage: 'Task "Load Category Data" failed',
    },
    CHOOSE_A_SYNONYM_TYPE: {
        id: 'choose.a.synonym.type',
        description: '',
        defaultMessage: 'Choose a Synonym Type',
    },
    TRIGGER_TERMS: {
        id: 'trigger.terms',
        description: '',
        defaultMessage: 'Trigger Terms',
    },
    TRIGGER_TERMS_DESCRIPTION: {
        id: 'trigger.terms.description',
        description: '',
        defaultMessage: 'Add the term you want to expand, separated by commas.',
    },
    DO_NOT_ASSOCIATE_TRIGGER_TERMS_DESCRIPTION: {
        id: 'do.not.associate.trigger.terms.description',
        description: '',
        defaultMessage: 'Add the trigger term(s) you want to prevent from being associated, separated by commas.',
    },
    DO_NOT_ASSOCIATE_TERM_FIELD_TITLE: {
        id: 'do.not.associate.term.field.title',
        description: '',
        defaultMessage: 'Do Not Associate Term',
    },
    DO_NOT_ASSOCIATE_TERM_FIELD_TITLE_DESCRIPTION: {
        id: 'do.not.associate.term.field.title.description',
        description: '',
        defaultMessage: 'Then, add the Do Not Associate term(s) that the trigger term should not be associated with, separated by commas.',
    },
    TRIGGER_TERM: {
        id: 'trigger.term',
        description: '',
        defaultMessage: 'Trigger Term',
    },
    IGNORE_ACTION_TERM: {
        id: 'ignore_action.term',
        description: '',
        defaultMessage: 'Ignore Action Term',
    },
    TRIGGER_TERM_SINGULAR_OR_PLURAL: {
        id: 'trigger.term.singular.or.plural',
        description: '',
        defaultMessage: 'Trigger Term(s)',
    },
    TRIGGER_TERM_FIELD_HELPER_TEXT: {
        id: 'trigger.term.field.helper.text',
        description: '',
        defaultMessage: 'Ex: jackets',
    },
    ONE_WAY_SYNONYM_FIELD_TITLE: {
        id: 'one.way.synonym.field.title',
        description: '',
        defaultMessage: 'One-Way Synonym(s)',
    },
    TWO_WAY_SYNONYM_CREATE: {
        id: 'two_way_synonym.create',
        description: '',
        defaultMessage: 'Create two-way synonym',
    },
    TWO_WAY_SYNONYM_EDIT: {
        id: 'two_way_synonym.edit',
        description: '',
        defaultMessage: 'Edit two-way synonym',
    },
    TWO_WAY_SYNONYM_FIELD_TITLE: {
        id: 'two.way.synonym.field.title',
        description: '',
        defaultMessage: 'Two-Way Synonyms',
    },
    ONE_WAY_SYNONYM_FIELD_TITLE_DESCRIPTION: {
        id: 'one.way.synonym.field.title.description',
        description: '',
        defaultMessage: 'Then, add the synonym(s) that the trigger term should expand to, separated by commas.',
    },
    TWO_WAY_SYNONYM_FIELD_TITLE_DESCRIPTION: {
        id: 'two.way.synonym.field.title.description',
        description: '',
        defaultMessage: 'Add the synonyms you want to expand, separated by commas. At least two synonyms are required to create two-way synonyms.',
    },
    SYNONYM_SINGULAR_OR_PLURAL: {
        id: 'synonym.singular.or.plural',
        description: '',
        defaultMessage: 'Synonym(s)',
    },
    SYNONYM_SINGULAR_OR_PLURAL_HELPER_TEXT: {
        id: 'synonym.singular.or.plural.helper.text',
        description: '',
        defaultMessage: 'Ex: blazers, coats, etc.',
    },
    DO_NOT_ASSOCIATE_SINGULAR_OR_PLURAL: {
        id: 'do_nopt_associate.singular.or.plural',
        description: '',
        defaultMessage: 'Do Not Associate Term(s)',
    },
    DO_NOT_ASSOCIATE_SINGULAR_OR_PLURAL_HELPER_TEXT: {
        id: 'do_nopt_associate.singular.or.plural.helper.text',
        description: '',
        defaultMessage: 'Ex: blazers, coats, etc.',
    },
    TOTAL_CREATED: {
        id: 'total.created',
        description: '',
        defaultMessage: 'Total Created',
    },
    ONE_WAY: {
        id: 'one.way',
        description: '',
        defaultMessage: 'One-Way',
    },
    ONE_WAY_INFO_DESCRIPTION: {
        id: 'one.way.info.description',
        description: '',
        defaultMessage: 'A one-way synonym lets you expand a search (ex: jackets) to also include additional items (ex: blazers), but not the other way around.',
    },
    TWO_WAY: {
        id: 'two.way',
        description: '',
        defaultMessage: 'Two-Way',
    },
    TASK_ZONE_LOAD_ALL_ERROR: {
        id: 'task.zone.loadall.error',
        description: '',
        defaultMessage: 'Task "Load zones" failed',
    },
    TASK_ZONE_LOAD_ONE_ERROR: {
        id: 'task.zone.loadone.error',
        description: '',
        defaultMessage: 'Task "Load zone" failed',
    },
    TASK_ZONE_CREATE_SUCCESS: {
        id: 'task.zone.create.success',
        description: '',
        defaultMessage: 'Task "Create zone" completed!',
    },
    TASK_ZONE_CREATE_ERROR: {
        id: 'task.zone.create.error',
        description: '',
        defaultMessage: 'Task "Create zone" failed',
    },
    TASK_ZONE_REMOVE_SUCCESS: {
        id: 'task.zone.remove.success',
        description: '',
        defaultMessage: 'Task "Remove zone {modelName}" completed!',
    },
    TASK_ZONE_REMOVE_ERROR: {
        id: 'task.zone.remove.error',
        description: '',
        defaultMessage: 'Task "Remove zone {modelName}" failed',
    },
    TASK_ZONE_BULK_REMOVE_SUCCESS: {
        id: 'task.zone.bulk.remove.success',
        description: '',
        defaultMessage: 'Task "Remove zones" completed!',
    },
    TASK_ZONE_BULK_REMOVE_ERROR: {
        id: 'task.zone.bulk.remove.error',
        description: '',
        defaultMessage: 'Task "Remove zones" failed',
    },
    TASK_ZONE_UPDATE_SUCCESS: {
        id: 'task.zone.update.success',
        description: '',
        defaultMessage: 'Task "Update zone" completed!',
    },
    TASK_ZONE_UPDATE_ERROR: {
        id: 'task.zone.update.error',
        description: '',
        defaultMessage: 'Task "Update zone" failed',
    },
    TASK_ZONE_COPYTOAREA_SUCCESS: {
        id: 'task.zone.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy zone to {targetAreaName}" completed!',
    },
    TASK_ZONE_COPYTOAREA_ERROR: {
        id: 'task.zone.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy zone to {targetAreaName}" failed',
    },
    ZONE_COPYTOAREA_CONFIRM: {
        id: 'task.zone.copytoarea.confirm',
        description: '',
        defaultMessage: 'A zone with the name {modelName} exists. Would you like to overwrite it?',
    },
    ZONE_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.zone.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Zones with the names {modelName} exist. Would you like to overwrite them?',
    },
    TASK_AREA_CREATE_SUCCESS: {
        id: 'task.area.create.success',
        description: '',
        defaultMessage: 'Task "Create area" completed!',
    },
    TASK_RULE_CREATE_SUCCESS: {
        id: 'task.rule.create.success',
        description: '',
        defaultMessage: 'Task "Create rule" completed!',
    },
    TASK_NAVIGATION_CREATE_SUCCESS: {
        id: 'task.navigation.create.success',
        description: '',
        defaultMessage: 'Task "Create navigation" completed!',
    },
    TASK_BIASINGPROFILE_CREATE_SUCCESS: {
        id: 'task.biasingprofile.create.success',
        description: '',
        defaultMessage: 'Task "Create strategy" completed!',
    },
    TASK_MEGAMENU_UPDATE_SUCCESS: {
        id: 'task.megamenu.update.success',
        description: '',
        defaultMessage: 'Task "Update megamenu" completed!',
    },
    TASK_MEGAMENU_UPDATE_ERROR: {
        id: 'task.megamenu.update.error',
        description: '',
        defaultMessage: 'Task "Update megamenu" failed!',
    },
    TASK_MEGAMENU_CREATE_SUCCESS: {
        id: 'task.megamenu.create.success',
        description: '',
        defaultMessage: 'Task "Create megamenu" completed!',
    },
    TASK_MEGAMENU_CREATE_ERROR: {
        id: 'task.megamenu.create.error',
        description: '',
        defaultMessage: 'Task "Create megamenu" failed!',
    },
    TASK_REDIRECT_CREATE_SUCCESS: {
        id: 'task.redirect.create.success',
        description: '',
        defaultMessage: 'Task "Create redirect" completed!',
    },
    TASK_TAG_CREATE_SUCCESS: {
        id: 'task.tag.create.success',
        description: '',
        defaultMessage: 'Task "Create tag" completed!',
    },
    TASK_USER_CREATE_SUCCESS: {
        id: 'task.user.create.success',
        description: '',
        defaultMessage: 'Task "Create user" completed!',
    },
    TASK_SITE_CREATE_SUCCESS: {
        id: 'task.site.create.success',
        description: '',
        defaultMessage: 'Task "Create site" completed!',
    },
    TASK_AREA_CREATE_ERROR: {
        id: 'task.area.create.error',
        description: '',
        defaultMessage: 'Task "Create area" failed',
    },
    TASK_RULE_CREATE_ERROR: {
        id: 'task.rule.create.error',
        description: '',
        defaultMessage: 'Task "Create rule" failed',
    },
    TASK_NAVIGATION_CREATE_ERROR: {
        id: 'task.navigation.create.error',
        description: '',
        defaultMessage: 'Task "Create navigation" failed',
    },
    TASK_BIASINGPROFILE_CREATE_ERROR: {
        id: 'task.biasingprofile.create.error',
        description: '',
        defaultMessage: 'Task "Create strategy" failed',
    },
    TASK_REDIRECT_CREATE_ERROR: {
        id: 'task.redirect.create.error',
        description: '',
        defaultMessage: 'Task "Create redirect" failed',
    },
    TASK_TAG_CREATE_ERROR: {
        id: 'task.tag.create.error',
        description: '',
        defaultMessage: 'Task "Create tag" failed',
    },
    TASK_SITE_CREATE_ERROR: {
        id: 'task.site.create.error',
        description: '',
        defaultMessage: 'Task "Create site" failed',
    },
    TASK_USER_CREATE_ERROR: {
        id: 'task.user.create.error',
        description: '',
        defaultMessage: 'Task "Create user" failed',
    },
    TASK_AREA_REMOVE_SUCCESS: {
        id: 'task.area.remove.success',
        description: '',
        defaultMessage: 'Task "Remove area {modelName}" completed!',
    },
    TASK_RULE_REMOVE_SUCCESS: {
        id: 'task.rule.remove.success',
        description: '',
        defaultMessage: 'Task "Remove rule {modelName}" completed!',
    },
    TASK_NAVIGATION_REMOVE_SUCCESS: {
        id: 'task.navigation.remove.success',
        description: '',
        defaultMessage: 'Task "Remove navigation {modelName}" completed!',
    },
    TASK_PLACEMENT_REMOVE_SUCCESS: {
        id: 'task.placement.remove.success',
        description: '',
        defaultMessage: 'Task "Remove placement {modelName}" completed!',
    },
    TASK_PRODUCT_RECOMMENDATION_REMOVE_SUCCESS: {
        id: 'task.product_recommendation.remove.success',
        description: '',
        defaultMessage: 'Task "Remove product recommendation {modelName}" completed!',
    },
    TASK_BIASINGPROFILE_REMOVE_SUCCESS: {
        id: 'task.biasingprofile.remove.success',
        description: '',
        defaultMessage: 'Task "Remove strategy {modelName}" completed!',
    },
    TASK_REDIRECT_REMOVE_SUCCESS: {
        id: 'task.redirect.remove.success',
        description: '',
        defaultMessage: 'Task "Remove redirect {modelName}" completed!',
    },
    TASK_TAG_REMOVE_SUCCESS: {
        id: 'task.tag.remove.success',
        description: '',
        defaultMessage: 'Task "Remove tag {modelName}" completed!',
    },
    TASK_USER_REMOVE_SUCCESS: {
        id: 'task.user.remove.success',
        description: '',
        defaultMessage: 'Task "Remove user {modelName}" completed!',
    },
    TASK_SITE_REMOVE_SUCCESS: {
        id: 'task.site.remove.success',
        description: '',
        defaultMessage: 'Task "Remove site {modelName}" completed!',
    },
    TASK_AREA_REMOVE_ERROR: {
        id: 'task.area.remove.error',
        description: '',
        defaultMessage: 'Task "Remove area {modelName}" failed',
    },
    TASK_RULE_REMOVE_ERROR: {
        id: 'task.rule.remove.error',
        description: '',
        defaultMessage: 'Task "Remove rule {modelName}" failed',
    },
    TASK_NAVIGATION_REMOVE_ERROR: {
        id: 'task.navigation.remove.error',
        description: '',
        defaultMessage: 'Task "Remove navigation {modelName}" failed',
    },
    TASK_PLACEMENT_REMOVE_ERROR: {
        id: 'task.placement.remove.error',
        description: '',
        defaultMessage: 'Task "Remove placement {modelName}" failed',
    },
    TASK_PRODUCT_RECOMMENDATION_REMOVE_ERROR: {
        id: 'task.product_recommendation.remove.error',
        description: '',
        defaultMessage: 'Task "Remove product recommendation {modelName}" failed',
    },
    TASK_BIASINGPROFILE_REMOVE_ERROR: {
        id: 'task.biasingprofile.remove.error',
        description: '',
        defaultMessage: 'Task "Remove strategy {modelName}" failed',
    },
    TASK_REDIRECT_REMOVE_ERROR: {
        id: 'task.redirect.remove.error',
        description: '',
        defaultMessage: 'Task "Remove redirect {modelName}" failed',
    },
    TASK_TAG_REMOVE_ERROR: {
        id: 'task.tag.remove.error',
        description: '',
        defaultMessage: 'Task "Remove tag {modelName}" failed',
    },
    TASK_USER_REMOVE_ERROR: {
        id: 'task.user.remove.error',
        description: '',
        defaultMessage: 'Task "Remove user {modelName}" failed',
    },
    TASK_SITE_REMOVE_ERROR: {
        id: 'task.site.remove.error',
        description: '',
        defaultMessage: 'Task "Remove site {modelName}" failed',
    },
    TASK_AREA_UPDATE_SUCCESS: {
        id: 'task.area.update.success',
        description: '',
        defaultMessage: 'Task "Update area" completed!',
    },
    TASK_RULE_UPDATE_SUCCESS: {
        id: 'task.rule.update.success',
        description: '',
        defaultMessage: 'Task "Update rule" completed!',
    },
    TASK_IMAGE_LOAD_ALL_ERROR: {
        id: 'task.image.load_all.error',
        description: '',
        defaultMessage: 'Task "Load images" failed',
    },
    TASK_NAVIGATION_UPDATE_SUCCESS: {
        id: 'task.navigation.update.success',
        description: '',
        defaultMessage: 'Task "Update navigation" completed!',
    },
    TASK_BIASINGPROFILE_UPDATE_SUCCESS: {
        id: 'task.biasingprofile.update.success',
        description: '',
        defaultMessage: 'Task "Update strategy" completed!',
    },
    TASK_REDIRECT_UPDATE_SUCCESS: {
        id: 'task.redirect.update.success',
        description: '',
        defaultMessage: 'Task "Update redirect" completed!',
    },
    TASK_TAG_UPDATE_SUCCESS: {
        id: 'task.tag.update.success',
        description: '',
        defaultMessage: 'Task "Update tag" completed!',
    },
    TASK_PLACEMENT_UPDATE_SUCCESS: {
        id: 'task.placement.update.success',
        description: '',
        defaultMessage: 'Task "Update placement" completed!',
    },
    TASK_PRODUCT_RECOMMENDATION_UPDATE_SUCCESS: {
        id: 'task.product_recommendation.update.success',
        description: '',
        defaultMessage: 'Task "Update product recommendation" completed!',
    },
    TASK_USER_UPDATE_SUCCESS: {
        id: 'task.user.update.success',
        description: '',
        defaultMessage: 'Task "Update user" completed!',
    },
    TASK_USER_SETTINGS_UPDATE_SUCCESS: {
        id: 'task.user.settings.update.success',
        description: '',
        defaultMessage: 'Task "Update user settings" completed!',
    },
    TASK_SITE_UPDATE_SUCCESS: {
        id: 'task.site.update.success',
        description: '',
        defaultMessage: 'Task "Update site" completed!',
    },
    TASK_AREA_UPDATE_ERROR: {
        id: 'task.area.update.error',
        description: '',
        defaultMessage: 'Task "Update area" failed',
    },
    TASK_RULE_UPDATE_ERROR: {
        id: 'task.rule.update.error',
        description: '',
        defaultMessage: 'Task "Update rule" failed',
    },
    TASK_NAVIGATION_UPDATE_ERROR: {
        id: 'task.navigation.update.error',
        description: '',
        defaultMessage: 'Task "Update navigation" failed',
    },
    TASK_BIASINGPROFILE_UPDATE_ERROR: {
        id: 'task.biasingprofile.update.error',
        description: '',
        defaultMessage: 'Task "Update strategy" failed',
    },
    TASK_REDIRECT_UPDATE_ERROR: {
        id: 'task.redirect.update.error',
        description: '',
        defaultMessage: 'Task "Update redirect" failed',
    },
    TASK_TAG_UPDATE_ERROR: {
        id: 'task.tag.update.error',
        description: '',
        defaultMessage: 'Task "Update tag" failed',
    },
    TASK_PLACEMENT_UPDATE_ERROR: {
        id: 'task.placement.update.error',
        description: '',
        defaultMessage: 'Task "Update placement" failed',
    },
    TASK_PRODUCT_RECOMMENDATION_UPDATE_ERROR: {
        id: 'task.product_recommendation.update.error',
        description: '',
        defaultMessage: 'Task "Update product recommendation" failed',
    },
    TASK_USER_UPDATE_ERROR: {
        id: 'task.user.update.error',
        description: '',
        defaultMessage: 'Task "Update user" failed',
    },
    TASK_USER_SETTINGS_UPDATE_ERROR: {
        id: 'task.user.settings.update.error',
        description: '',
        defaultMessage: 'Task "Update user settings" failed!',
    },
    TASK_SITE_UPDATE_ERROR: {
        id: 'task.site.update.error',
        description: '',
        defaultMessage: 'Task "Update site" failed',
    },
    TASK_AREA_LOADONE_SUCCESS: {
        id: 'task.area.loadone.success',
        description: '',
        defaultMessage: 'Task "Load area" completed!',
    },
    TASK_RULE_LOADONE_SUCCESS: {
        id: 'task.rule.loadone.success',
        description: '',
        defaultMessage: 'Task "Load rule" completed!',
    },
    TASK_NAVIGATION_LOADONE_SUCCESS: {
        id: 'task.navigation.loadone.success',
        description: '',
        defaultMessage: 'Task "Load navigation" completed!',
    },
    TASK_BIASINGPROFILE_LOADONE_SUCCESS: {
        id: 'task.biasingprofile.loadone.success',
        description: '',
        defaultMessage: 'Task "Load strategy" completed!',
    },
    TASK_REDIRECT_LOADONE_SUCCESS: {
        id: 'task.redirect.loadone.success',
        description: '',
        defaultMessage: 'Task "Load redirect" completed!',
    },
    TASK_TAG_LOADONE_SUCCESS: {
        id: 'task.tag.loadone.success',
        description: '',
        defaultMessage: 'Task "Load tag" completed!',
    },
    TASK_USER_LOADONE_SUCCESS: {
        id: 'task.user.loadone.success',
        description: '',
        defaultMessage: 'Task "Load user" completed!',
    },
    TASK_AREA_LOADONE_ERROR: {
        id: 'task.area.loadone.error',
        description: '',
        defaultMessage: 'Task "Load area" failed',
    },
    TASK_RULE_LOADONE_ERROR: {
        id: 'task.rule.loadone.error',
        description: '',
        defaultMessage: 'Task "Load rule" failed',
    },
    TASK_NAVIGATION_LOADONE_ERROR: {
        id: 'task.navigation.loadone.error',
        description: '',
        defaultMessage: 'Task "Load navigation" failed',
    },
    TASK_BIASINGPROFILE_LOADONE_ERROR: {
        id: 'task.biasingprofile.loadone.error',
        description: '',
        defaultMessage: 'Task "Load strategy" failed',
    },
    TASK_REDIRECT_LOADONE_ERROR: {
        id: 'task.redirect.loadone.error',
        description: '',
        defaultMessage: 'Task "Load redirect" failed',
    },
    TASK_TAG_LOAD_ONE_ERROR: {
        id: 'task.tag.load_one.error',
        description: '',
        defaultMessage: 'Task "Load tag" failed',
    },
    TASK_PLACEMENT_LOAD_ONE_ERROR: {
        id: 'task.placement.load_one.error',
        description: '',
        defaultMessage: 'Task "Load placement" failed',
    },
    TASK_PRODUCT_RECOMMENDATION_LOAD_ONE_ERROR: {
        id: 'task.product_recommendation.load_one.error',
        description: '',
        defaultMessage: 'Task "Load product recommendation" failed',
    },
    TASK_USER_LOADONE_ERROR: {
        id: 'task.user.loadone.error',
        description: '',
        defaultMessage: 'Task "Load user" failed',
    },
    TASK_SITE_LOAD_ONE_ERROR: {
        id: 'task.site.load_one.error',
        description: '',
        defaultMessage: 'Task "Load site" failed',
    },
    TASK_AREA_REORDER_ERROR: {
        id: 'task.area.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder area" failed',
    },
    TASK_RULE_REORDER_ERROR: {
        id: 'task.rule.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder rule" failed',
    },
    TASK_NAVIGATION_REORDER_ERROR: {
        id: 'task.navigation.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder navigation" failed',
    },
    TASK_BIASINGPROFILE_REORDER_ERROR: {
        id: 'task.biasingprofile.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder strategy" failed',
    },
    TASK_REDIRECT_REORDER_ERROR: {
        id: 'task.redirect.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder redirect" failed',
    },
    TASK_TAG_REORDER_ERROR: {
        id: 'task.tag.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder tag" failed',
    },
    TASK_USER_REORDER_ERROR: {
        id: 'task.user.reorder.error',
        description: '',
        defaultMessage: 'Task "Reorder user" failed',
    },
    TASK_AREA_DUPLICATE_SUCCESS: {
        id: 'task.area.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate area" completed!',
    },
    TASK_RULE_DUPLICATE_SUCCESS: {
        id: 'task.rule.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate rule" completed!',
    },
    TASK_NAVIGATION_DUPLICATE_SUCCESS: {
        id: 'task.navigation.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate navigation" completed!',
    },
    TASK_BIASINGPROFILE_DUPLICATE_SUCCESS: {
        id: 'task.biasingprofile.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate strategy" completed!',
    },
    TASK_REDIRECT_DUPLICATE_SUCCESS: {
        id: 'task.redirect.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate redirect" completed!',
    },
    TASK_TAG_DUPLICATE_SUCCESS: {
        id: 'task.tag.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate tag" completed!',
    },
    TASK_USER_DUPLICATE_SUCCESS: {
        id: 'task.user.duplicate.success',
        description: '',
        defaultMessage: 'Task "Duplicate user" completed!',
    },
    TASK_AREA_DUPLICATE_ERROR: {
        id: 'task.area.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate area" failed',
    },
    TASK_RULE_DUPLICATE_ERROR: {
        id: 'task.rule.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate rule" failed',
    },
    TASK_NAVIGATION_DUPLICATE_ERROR: {
        id: 'task.navigation.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate navigation" failed',
    },
    TASK_BIASINGPROFILE_DUPLICATE_ERROR: {
        id: 'task.biasingprofile.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate strategy" failed',
    },
    TASK_REDIRECT_DUPLICATE_ERROR: {
        id: 'task.redirect.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate redirect" failed',
    },
    TASK_TAG_DUPLICATE_ERROR: {
        id: 'task.tag.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate tag" failed',
    },
    TASK_USER_DUPLICATE_ERROR: {
        id: 'task.user.duplicate.error',
        description: '',
        defaultMessage: 'Task "Duplicate user" failed',
    },
    TASK_AREA_COPYTOAREA_SUCCESS: {
        id: 'task.area.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Move area to {targetArea}" completed!',
    },
    TASK_RULE_COPYTOAREA_SUCCESS: {
        id: 'task.rule.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Move rule to {targetArea}" completed!',
    },
    TASK_RULE_BULK_COPYTOAREA_SUCCESS: {
        id: 'task.rule.bulk_copytoarea.success',
        description: '',
        defaultMessage: 'Task "Move {count} rules to {targetArea}" completed!',
    },
    TASK_RULE_BULK_COPYTOAREA_ERROR: {
        id: 'task.rule.bulk_copytoarea.error',
        description: '',
        defaultMessage: 'Task "Move {rulesNameList} rules to {targetArea}" failed!',
    },
    TASK_RULE_COPIED_TO_AREA_SUCCESS: {
        id: 'task.rule.copy_from_area_to_area.success',
        description: '',
        defaultMessage: 'Copied to {targetArea} Area',
    },
    TASK_NAVIGATION_COPYTOAREA_SUCCESS: {
        id: 'task.navigation.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy navigation to {targetArea}" completed!',
    },
    TASK_BIASINGPROFILE_COPYTOAREA_SUCCESS: {
        id: 'task.biasingprofile.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy biasing profile to {targetArea}" completed!',
    },
    TASK_REDIRECT_COPYTOAREA_SUCCESS: {
        id: 'task.redirect.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy redirect to {targetArea}" completed!',
    },
    TASK_TAG_COPYTOAREA_SUCCESS: {
        id: 'task.tag.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy tag to {targetArea}" completed!',
    },
    TASK_USER_COPYTOAREA_SUCCESS: {
        id: 'task.user.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy user to {targetArea}" completed!',
    },
    TASK_PERMISSIONROLE_COPYTOAREA_SUCCESS: {
        id: 'task.permissionrole.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy permission role to {targetArea}" completed!',
    },
    TASK_AREA_COPYTOAREA_ERROR: {
        id: 'task.area.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy area to {targetArea}" failed',
    },
    TASK_RULE_BULKCOPYTOAREA_SUCCESS: {
        id: 'task.rule.bulkcopytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy rules to {targetArea}" completed!',
    },
    TASK_NAVIGATION_BULKCOPYTOAREA_SUCCESS: {
        id: 'task.navigation.bulkcopytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy navigations to {targetArea}" completed!',
    },
    TASK_BIASINGPROFILE_BULKCOPYTOAREA_SUCCESS: {
        id: 'task.biasingprofile.bulkcopytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy strategies" completed to {targetArea}!',
    },
    TASK_REDIRECT_BULKCOPYTOAREA_SUCCESS: {
        id: 'task.redirect.bulkcopytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy redirects to {targetArea}" completed!',
    },
    TASK_RULE_COPYTOAREA_ERROR: {
        id: 'task.rule.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy rule to {targetArea}" failed',
    },
    TASK_GENERIC_COPYTOAREA_ERROR: {
        id: 'task.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy to area" failed',
    },
    TASK_NAVIGATION_COPYTOAREA_ERROR: {
        id: 'task.navigation.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy navigation to {targetArea}" failed',
    },
    TASK_BIASINGPROFILE_COPYTOAREA_ERROR: {
        id: 'task.biasingprofile.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy biasing profile to {targetArea}" failed',
    },
    TASK_REDIRECT_COPYTOAREA_ERROR: {
        id: 'task.redirect.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Move redirect to {targetArea}" failed',
    },
    TASK_TAG_COPYTOAREA_ERROR: {
        id: 'task.tag.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Move tag to {targetArea}" failed',
    },
    TASK_USER_COPYTOAREA_ERROR: {
        id: 'task.user.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Move user to {targetArea}" failed',
    },
    TASK_PERMISSIONROLE_COPYTOAREA_ERROR: {
        id: 'task.permissionrole.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Move permission to {targetArea}" failed',
    },
    AREA_COPYTOAREA_CONFIRM: {
        id: 'task.area.copytoarea.confirm',
        description: '',
        defaultMessage: 'A area with the name {modelName} exists. Would you like to overwrite it?',
    },
    TASK_RULE_BULKCOPYTOAREA_ERROR: {
        id: 'task.rule.bulkcopytoarea.error',
        description: '',
        defaultMessage: 'Task "Move rules to {targetArea}" failed',
    },
    TASK_NAVIGATION_BULKCOPYTOAREA_ERROR: {
        id: 'task.navigation.bulkcopytoarea.error',
        description: '',
        defaultMessage: 'Task "Move navigations to {targetArea}" failed',
    },
    TASK_BIASINGPROFILE_BULKCOPYTOAREA_ERROR: {
        id: 'task.biasingprofile.bulkcopytoarea.error',
        description: '',
        defaultMessage: 'Task "Move strategies" failed to {targetArea}',
    },
    TASK_REDIRECT_BULKCOPYTOAREA_ERROR: {
        id: 'task.redirect.bulkcopytoarea.error',
        description: '',
        defaultMessage: 'Task "Move redirects to {targetArea}" failed',
    },
    TASK_GET_TENANT_SETTINGS_ERROR: {
        id: 'task.gettenantsettings.error',
        description: '',
        defaultMessage: 'Task "Get tenant settings" failed',
    },
    TASK_PLACEMENTS_LOAD_ALL_ERROR: {
        id: 'task.placements.load_all.error',
        description: '',
        defaultMessage: 'Task "Get placements" failed',
    },
    TASK_PRODUCT_RECOMMENDATIONS_LOAD_ALL_ERROR: {
        id: 'task.product_recommendations.load_all.error',
        description: '',
        defaultMessage: 'Task "Get product recommendations" failed',
    },
    TASK_COLLECTIONS_LOAD_ALL_ERROR: {
        id: 'task.collections.load_all.error',
        description: '',
        defaultMessage: 'Task "Get collections" failed',
    },
    TASK_PREVIEW_PRODUCTS_LOAD_ERROR: {
        id: 'task.preview_products.load.error',
        description: '',
        defaultMessage: 'Task "Get preview products" failed',
    },
    FOUND_DUPLICATES_ERROR: {
        id: 'duplicatesfound.error',
        description: '',
        defaultMessage: 'An error occurred while searching for duplicates',
    },
    RULE_COPYTOAREA_CONFIRM: {
        id: 'task.rule.copytoarea.confirm',
        description: '',
        defaultMessage: 'A rule with the name {modelName} exists. Would you like to overwrite it?',
    },
    RULE_COPYTOAREA_WITH_FORCE_CONFIRM: {
        id: 'rule.copytoarea.withforce.confirm',
        description: '',
        defaultMessage: 'Changes to Biasing Strategies, Templates, and Zones will not be moved. Proceed anyway?',
    },
    RULE_BULK_PROMOTE_WITHOUT_FORCE_CONFIRM: {
        id: 'rule.copytoarea.withoutforce.confirm',
        description: '',
        defaultMessage: 'No conflicts in any rules.',
    },
    RULE_BULK_PROMOTE_CONFIGS_WILL_NOT_BE_MOVED: {
        id: 'rule.bulkcopytoarea.withforce.confirm',
        description: '',
        defaultMessage: 'Note that Changes to Biasing Strategies, Templates, and Zones will not be moved',
    },
    RULE_PROMOTE_WITH_FORCE_CONFIRM: {
        id: 'rule.promote.withforce.confirm',
        description: '',
        defaultMessage: 'The rule {ruleName} in area {targetAreaName} has the following changes:',
    },
    RULE_BULK_PROMOTE_WITH_FORCE_CONFIRM: {
        id: 'rule.bulk_promote.withforce.confirm',
        description: '',
        defaultMessage: 'WARNING: Some of these rules have conflicts. Promote anyway?',
    },
    NAVIGATION_COPYTOAREA_CONFIRM: {
        id: 'task.navigation.copytoarea.confirm',
        description: '',
        defaultMessage: 'A navigation with the name {modelName} exists. Would you like to overwrite it?',
    },
    BIASINGPROFILE_COPYTOAREA_CONFIRM: {
        id: 'task.biasingprofile.copytoarea.confirm',
        description: '',
        defaultMessage: 'A strategy with the name {modelName} exists. Would you like to overwrite it?',
    },
    REDIRECT_COPYTOAREA_CONFIRM: {
        id: 'task.redirect.copytoarea.confirm',
        description: '',
        defaultMessage: 'A redirect with the name {modelName} exists. Would you like to overwrite it?',
    },
    TAG_COPYTOAREA_CONFIRM: {
        id: 'task.tag.copytoarea.confirm',
        description: '',
        defaultMessage: 'A tag with the name {modelName} exists. Would you like to overwrite it?',
    },
    RULE_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.rule.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Rules with the names {modelName} exists. Would you like to overwrite them?',
    },
    NAVIGATION_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.navigation.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Navigations with the names {modelName} exists. Would you like to overwrite them?',
    },
    BIASINGPROFILE_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.biasingprofile.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Profiles with the names {modelName} exists. Would you like to overwrite them?',
    },
    REDIRECT_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.redirect.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Redericts with the names {modelName} exists. Would you like to overwrite them?',
    },
    TASK_BIASINGPROFILE_BULK_REMOVE_SUCCESS: {
        id: 'task.biasing-profile.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove biasing profiles" completed!',
    },
    TASK_BIASINGPROFILE_BULK_REMOVE_ERROR: {
        id: 'task.biasing-profile.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove biasing profiles" failed!',
    },
    TASK_NAVIGATION_BULK_REMOVE_SUCCESS: {
        id: 'task.navigation.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove navigations" completed!',
    },
    TASK_PLACEMENT_BULK_REMOVE_SUCCESS: {
        id: 'task.placement.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove placements" completed!',
    },
    TASK_PRODUCT_RECOMMENDATION_BULK_REMOVE_SUCCESS: {
        id: 'task.product_recommendation.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove product recommendations" completed!',
    },
    TASK_NAVIGATION_BULK_REMOVE_ERROR: {
        id: 'task.navigation.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove navigations" failed!',
    },
    TASK_PLACEMENT_BULK_REMOVE_ERROR: {
        id: 'task.placement.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove placements" failed!',
    },
    TASK_PRODUCT_RECOMMENDATION_BULK_REMOVE_ERROR: {
        id: 'task.product_recommendation.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove product recommendations" failed!',
    },
    TASK_REDIRECT_BULK_REMOVE_SUCCESS: {
        id: 'task.redirect.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove redirects" completed!',
    },
    TASK_REDIRECT_BULK_REMOVE_ERROR: {
        id: 'task.redirect.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove redirects" failed!',
    },
    TASK_RULE_BULK_REMOVE_SUCCESS: {
        id: 'task.rule.bulk-remove.success',
        description: '',
        defaultMessage: 'Task "Remove rules" completed!',
    },
    TASK_RULE_BULK_REMOVE_ERROR: {
        id: 'task.rule.bulk-remove.error',
        description: '',
        defaultMessage: 'Task "Remove rules" failed!',
    },
    NAVIGATION_LOG_OUT: {
        id: 'navigation.log_out',
        description: '',
        defaultMessage: 'Log Out',
    },
    NAVIGATION_LANGUAGE: {
        id: 'navigation.language',
        description: '',
        defaultMessage: 'Language: {language}',
    },
    SPANISH: {
        id: 'spanish',
        description: '',
        defaultMessage: 'Spanish',
    },
    ENGLISH: {
        id: 'english',
        description: '',
        defaultMessage: 'English',
    },
    SIMPLIFIED_CHINESE: {
        id: 'chinese',
        description: '',
        defaultMessage: 'Chinese',
    },
    PORTUGUESE_BRAZIL: {
        id: 'portuguese.brazil',
        description: '',
        defaultMessage: 'Portuguese (Brazil)',
    },
    FRENCH: {
        id: 'french',
        description: '',
        defaultMessage: 'French',
    },
    UNKNOWN_ERROR: {
        id: 'unknownerror',
        description: '',
        defaultMessage: 'Unknown error occurred',
    },
    VALIDATION_BETWEEN_CHARACTERS: {
        id: 'validation.between',
        description: '',
        defaultMessage: 'Must contain between {low}-{high} characters',
    },
    VALIDATION_REQUIRED: {
        id: 'validation.required',
        description: '',
        defaultMessage: 'This is a required field',
    },
    VALIDATION_REQUIRED_FILE: {
        id: 'validation.required.file',
        description: '',
        defaultMessage: 'A file must be added',
    },
    VALIDATION_EMAIL: {
        id: 'validation.email',
        description: '',
        defaultMessage: 'Must be a valid email',
    },
    VALIDATION_NOT_EXCEED_LIMIT_CHARACTERS: {
        id: 'validation.must_not_exceed',
        description: '',
        defaultMessage: 'Must not exceed {limit} characters',
    },
    VALIDATION_MINIMUM_CHARACTERS: {
        id: 'validation.minimum.characters',
        description: '',
        defaultMessage: 'Must contain at least {minimum} characters',
    },
    VALIDATION_PRODUCT_BOOST_PRODUCT_IDS_PER_TIER: {
        id: 'validation.product_boost.num_product_ids',
        description: '',
        defaultMessage: 'Cannot exceed {limit} product ids per tier',
    },
    VALIDATION_PINNED_PRODUCTS_POSITION: {
        id: 'validation.pinned_product_position',
        description: '',
        defaultMessage: 'Position should be defined',
    },
    VALIDATION_PINNED_PRODUCTS_MIN_POSITION: {
        id: 'validation.pinned_product_min_position',
        description: '',
        defaultMessage: 'Position should be greater than {number}',
    },
    VALIDATION_PINNED_PRODUCTS_MAX_POSITION: {
        id: 'validation.pinned_product_max_position',
        description: '',
        defaultMessage: 'Position should be less than {number}',
    },
    VALIDATION_PRODUCTS_UNIQUE_PER_PAGE: {
        id: 'validation.product_unique_per_page',
        description: '',
        defaultMessage: 'Product IDs must be unique',
    },
    VALIDATION_AT_LEAST_ONE_LOWERCASE_UPPERCASE_NUMERIC_SYMBOLS: {
        id: 'validation.at_least_one_lowercase_uppercase_numeric_symbols',
        description: '',
        defaultMessage: 'Must include 3 of: lowercase, uppercase, numeric, or symbols (from !@#$%^&*()_+)',
    },
    VALIDATION_AT_LEAST_LIMIT_CHARACTERS: {
        id: 'validation.at_least_characters',
        description: '',
        defaultMessage: 'Must be at least {limit} characters',
    },
    VALIDATION_AT_LEAST_ONE_UPPERCASE: {
        id: 'validation.at_least_one_uppercase',
        description: '',
        defaultMessage: 'Must contain at least one uppercase letter',
    },
    VALIDATION_AT_LEAST_ONE_LOWERCASE: {
        id: 'validation.at_least_one_lowercase',
        description: '',
        defaultMessage: 'Must contain at least one lowercase letter',
    },
    VALIDATION_AT_LEAST_ONE_NUMERIC: {
        id: 'validation.at_least_one_numeric',
        description: '',
        defaultMessage: 'Must contain at least one numeric value',
    },
    VALIDATION_RANGE_OVERLAP: {
        id: 'validation.range_overlap',
        description: '',
        defaultMessage: 'Ranges can\'t overlap',
    },
    VALIDATION_LIMIT_RANGES: {
        id: 'validation.ranges.max_limit',
        description: '',
        defaultMessage: 'Number of Ranges must not exceed {limit}',
    },
    VALIDATION_RULE_VARIANT_NAME_UNIQUE: {
        id: 'validation.rule_variant_name.unique',
        description: '',
        defaultMessage: '{name} has already been used for this rule. Please use a unique name.',
    },
    SEARCH: {
        id: 'search',
        description: '',
        defaultMessage: 'Search',
    },
    SEARCH_MODULES: {
        id: 'search_modules',
        description: '',
        defaultMessage: 'Search modules',
    },
    PRODUCTS: {
        id: 'products',
        description: '',
        defaultMessage: 'Products',
    },
    COUNT_SELECTED: {
        id: 'count_selected',
        description: '',
        defaultMessage: '{count} selected',
    },
    VALIDATION_PRODUCT_ID: {
        id: 'validation.product.id',
        description: '',
        defaultMessage: 'Product ID is required',
    },
    VALIDATION_AT_LEAST_ONE_TRIGGER: {
        id: 'validation.at_least_one_trigger',
        description: '',
        defaultMessage: 'Must have at least one trigger term',
    },
    VALIDATION_AT_LEAST_ONE_SYNONYM: {
        id: 'validation.at.least.one.synonym',
        description: '',
        defaultMessage: 'Must have at least one synonym',
    },
    VALIDATION_AT_LEAST_ONE_TERM: {
        id: 'validation.at_least_one_term',
        description: '',
        defaultMessage: 'Must have at least one term',
    },
    VALIDATION_AT_LEAST_TWO_SYNONYMS: {
        id: 'validation.at.least.two.synonyms',
        description: '',
        defaultMessage: 'Must have at least two synonyms',
    },
    VALIDATION_MUST_BE_UNIQUE: {
        id: 'validation.must_be_unique',
        description: '',
        defaultMessage: '{name} must be unique',
    },
    VALIDATION_NAVIGATION_FIELD_MUST_BE_UNIQUE: {
        id: 'validation.navigation.must_be_unique',
        description: '',
        defaultMessage: 'A navigation exists with this field',
    },
    VALIDATION_NOT_EXCEED_TRIGGERS_SET: {
        id: 'validation.must_not_exceed_triggers_set',
        description: '',
        defaultMessage: 'Must not exceed {limit} triggers in a set',
    },
    VALIDATION_NOT_EXCEED_TRIGGER_SETS: {
        id: 'validation.must_not_exceed_triggers_sets',
        description: '',
        defaultMessage: 'Must not exceed {limit} trigger sets',
    },
    VALIDATION_LIMIT_QUERY_PATTERNS_SET: {
        id: 'validation.must_not_exceed_query_patterns_set',
        description: '',
        defaultMessage: 'Must not exceed {limit} query pattern triggers (search CONTAINS, ENDS_WITH, REGEX, STARTS_WITH or MATCHES) in a set',
    },
    VALIDATION_LIMIT_REFINEMENTS_SET: {
        id: 'validation.must_not_exceed_refinements_set',
        description: '',
        defaultMessage: 'Must not exceed {limit} selected refinement triggers in a set',
    },
    VALIDATION_LIMIT_VALUE_FILTERS: {
        id: 'validation.must_not_exceed_value_filters',
        description: '',
        defaultMessage: 'Number of value filters (Exclusion, FilteredBy) must not exceed {limit}',
    },
    VALIDATION_LIMIT_RANGE_FILTERS: {
        id: 'validation.must_not_exceed_range_filters',
        description: '',
        defaultMessage: 'Number of range filters must not exceed {limit}',
    },
    VALIDATION_LIMIT_SEARCH_FILTERS: {
        id: 'validation.must_not_exceed_search_filters',
        description: '',
        defaultMessage: 'Number of search filters must not exceed {limit}',
    },
    VALIDATION_LIMIT_RULE_VARIANTS: {
        id: 'validation.must_not_exceed_rule_variants',
        description: '',
        defaultMessage: 'This Rule has reached the limit of 5 variants.',
    },
    VALIDATION_VALUE_GREATER_THAN: {
        id: 'validation.greater_than',
        description: '',
        defaultMessage: 'Value must be greater than {value}',
    },
    VALIDATION_VALUE_AT_LEAST: {
        id: 'validation.value_at_least',
        description: '',
        defaultMessage: 'Value must be at least {value}',
    },
    VALIDATION_RANGE_CONDITION: {
        id: 'validation.range_condition',
        description: '',
        defaultMessage: 'Upper range value must be greater than lower range value',
    },
    RECALL_VALIDATION_RANGE_LOW: {
        id: 'recall.validation.range_low',
        description: '',
        defaultMessage: 'Must be lower than upper bound',
    },
    RECALL_VALIDATION_RANGE_HIGH: {
        id: 'recall.validation.range_high',
        description: '',
        defaultMessage: 'Must be higher than lower bound',
    },
    VALIDATION_ONE_WORD: {
        id: 'validation.one_word',
        description: '',
        defaultMessage: 'Only one word permitted',
    },
    VALIDATION_VALID_CHARACTERS: {
        id: 'validation.valid_characters',
        description: '',
        defaultMessage: 'Must contain valid special characters only (-_!@#$%^&*()<>?)',
    },
    VALIDATION_AT_LEAST_ONE_RANGE_SHOULD_BE_DEFINED: {
        id: 'validation.at_least_one_range_should_be_defined',
        description: '',
        defaultMessage: 'At least one range should be defined',
    },
    VALIDATION_DATE_RANGE_SHOULD_BE_DEFINED: {
        id: 'validation.date_range_should_be_defined',
        description: '',
        defaultMessage: 'Must specify at least one date if active hours are enabled',
    },
    VALIDATION_DATE_START_BEFORE_END: {
        id: 'validation.date_start_before_end',
        description: '',
        defaultMessage: 'Start date and time must be before end date and time',
    },
    GOT_IT: {
        id: 'got_it',
        description: '',
        defaultMessage: 'Got it',
    },
    AUTH0_LOGIN_TENANT_HELPER_TEXT: {
        id: 'auth0.login.tenant.helper_text',
        description: '',
        defaultMessage: 'Only lowercase letters, numbers, and hyphens allowed (no spaces)',
    },
    LOGIN_SIGN_IN: {
        id: 'login.sign_in',
        description: '',
        defaultMessage: 'Sign In',
    },
    LOGIN_LEARN_MORE: {
        id: 'login.learn_more',
        description: '',
        defaultMessage: 'Not a member? Learn more at',
    },
    RESET_FILTERS: {
        id: 'reset_filters',
        description: '',
        defaultMessage: 'Reset filters',
    },
    LOGIN_RESET_PASSWORD: {
        id: 'login.reset_password',
        description: '',
        defaultMessage: 'Reset your password',
    },
    LOGIN_FAIL_ERROR: {
        id: 'login.fail_error',
        description: '',
        defaultMessage: 'Login Failed. Please try again.',
    },
    LOGIN_LOCKOUT_ERROR: {
        id: 'login.lockout_error',
        description: '',
        defaultMessage: 'This account has been locked after 5 failed login attempts. Contact an administrator from your organization to have your password reset.',
    },
    LOGIN_LOCKOUT_WARNING: {
        id: 'login.lockout_warning',
        description: '',
        defaultMessage: 'This account has had several failed login attempts and will be locked after 5 consecutive failures. If you have forgotten your password, contact an administrator from your organization for assistance.',
    },
    LOGIN_VALID_EMAIL: {
        id: 'login.valid_email',
        description: '',
        defaultMessage: 'Please enter a valid email.',
    },
    LOGIN_INACTIVE_SESSION_ERROR: {
        id: 'login.inactive_session_error',
        description: '',
        defaultMessage: 'You were logged out due to being inactive for 30 minutes. Please login again.',
    },
    LOGIN_SESSION_TIMEOUT_IN: {
        id: 'login.session_timeout_in',
        description: '',
        defaultMessage: 'Your session will timeout in',
    },
    LOGIN_SESSION_STAY_LOGGED_IN: {
        id: 'login.session_stay_logged_in',
        description: '',
        defaultMessage: 'Stay Logged In',
    },
    LOGIN_SESSION_LOGOUT: {
        id: 'login.session_logout',
        description: '',
        defaultMessage: 'Logout',
    },
    TIMESTAMP: {
        id: 'audit_log.timestamp',
        description: '',
        defaultMessage: 'Timestamp',
    },
    ID: {
        id: 'id',
        description: '',
        defaultMessage: 'ID',
    },
    COMMENT: {
        id: 'audit_log.comment',
        description: '',
        defaultMessage: 'Comment',
    },
    UPDATE: {
        id: 'update',
        description: '',
        defaultMessage: 'Update',
    },
    PROMOTE: {
        id: 'promote',
        description: '',
        defaultMessage: 'Promote',
    },
    SINGLE_PROMOTE: {
        id: 'single_promote',
        description: '',
        defaultMessage: 'Single Promote',
    },
    VIEW_AUDIT_LOG: {
        id: 'audit_log.view',
        description: '',
        defaultMessage: 'View Audit Log',
    },
    VIEW_MORE_AUDIT_LOGS: {
        id: 'audit_log.view_more',
        description: '',
        defaultMessage: 'View More Logs',
    },
    CHANGE_PRIORITY: {
        id: 'change_priority',
        description: '',
        defaultMessage: 'Change Priority',
    },
    COPYABLE: {
        id: 'copyable',
        description: '',
        defaultMessage: 'Copyable',
    },
    ACTION: {
        id: 'action',
        description: '',
        defaultMessage: 'Action',
    },
    LOW: {
        id: 'low',
        description: '',
        defaultMessage: 'Low',
    },
    HIGH: {
        id: 'high',
        description: '',
        defaultMessage: 'High',
    },
    ADD_NEW: {
        id: 'add_new',
        description: '',
        defaultMessage: 'Add new',
    },
    VIEW: {
        id: 'view',
        description: '',
        defaultMessage: 'View',
    },
    VIEW_ALL: {
        id: 'view_all',
        description: '',
        defaultMessage: 'View All',
    },
    SHOW_LESS: {
        id: 'show_less',
        description: '',
        defaultMessage: 'Show Less',
    },
    VALIDATION_RULE_TEMPLATES_ZONES_CONTENT: {
        id: 'validation.templates_contain_zones',
        description: '',
        defaultMessage: 'All template zone placements must have zone content. Please choose from the dropdown.',
    },
    CONTENT: {
        id: 'content',
        description: '',
        defaultMessage: 'Content',
    },
    HAS_CONTENT: {
        id: 'has_content',
        description: '',
        defaultMessage: 'Show content',
    },
    RICH_CONTENT: {
        id: 'rich_content',
        description: '',
        defaultMessage: 'Rich Content',
    },
    RULE_TEMPLATE_ZONES_SELECT: {
        id: 'rule.template_zone.select',
        description: '',
        defaultMessage: 'Please select a template',
    },
    PREVIEW_STATIC_CONTENT_MODAL_TITLE: {
        id: 'preview_static_content.modal.title',
        description: '',
        defaultMessage: 'Content view',
    },
    RECENTLY_UPDATED: {
        id: 'filter.recently_updated',
        description: '',
        defaultMessage: 'Recently updated',
    },
    ONLY_MY_STUFF: {
        id: 'filter.only_my_stuff',
        description: '',
        defaultMessage: 'Only my {modelType}',
    },
    REFRESH: {
        id: 'buttons.refresh',
        description: '',
        defaultMessage: 'Refresh',
    },
    REFRESHING: {
        id: 'buttons.refreshing',
        description: '',
        defaultMessage: 'Refreshing',
    },
    INGESTION_TASKS: {
        id: 'ingestion_tasks',
        description: '',
        defaultMessage: 'Ingestion Tasks',
    },
    INGESTION_TASK_STATUS_IN_QUEUE: {
        id: 'ingestion_tasks.status.in_queue',
        description: '',
        defaultMessage: 'In Queue',
    },
    INGESTION_TASK_STATUS_IN_PROGRESS: {
        id: 'ingestion_tasks.status.in_progress',
        description: '',
        defaultMessage: 'In Progress',
    },
    INGESTION_TASK_STATUS_FAILED: {
        id: 'ingestion_tasks.status.failed',
        description: '',
        defaultMessage: 'Upload Failed',
    },
    INGESTION_TASK_STATUS_COMPLETE: {
        id: 'ingestion_tasks.status.complete',
        description: '',
        defaultMessage: 'Complete',
    },
    INGESTION_TASK_TAB_FAILED: {
        id: 'ingestion_tasks.tab.failed',
        description: '',
        defaultMessage: 'Failed',
    },
    INGESTION_TASK_TAB_CANCELLED: {
        id: 'ingestion_tasks.tab.cancelled',
        description: '',
        defaultMessage: 'Cancelled',
    },
    INGESTION_TASK_TAB_IN_QUEUE: {
        id: 'ingestion_tasks.tab.in_queue',
        description: '',
        defaultMessage: 'In Queue',
    },
    INGESTION_TASK_TAB_IN_PROGRESS: {
        id: 'ingestion_tasks.tab.in_progress',
        description: '',
        defaultMessage: 'In Progress',
    },
    INGESTION_TASK_TAB_COMPLETE: {
        id: 'ingestion_tasks.tab.complete',
        description: '',
        defaultMessage: 'Complete',
    },
    COLUMN_STATUS: {
        id: 'column.status',
        description: '',
        defaultMessage: 'Status',
    },
    COLUMN_COLLECTION_NAME: {
        id: 'column.collection_name',
        description: '',
        defaultMessage: 'Collection Name/ID',
    },
    COLUMN_TASK_ID: {
        id: 'column.task.id',
        description: '',
        defaultMessage: 'Task ID',
    },
    COLUMN_UPLOAD_TYPE: {
        id: 'column.upload_type',
        description: '',
        defaultMessage: 'Upload Type',
    },
    COLUMN_UPLOAD_TIME: {
        id: 'column.start_time',
        description: '',
        defaultMessage: 'Initiated On',
    },
    COLUMN_PROCESSING_STARTED_TIME: {
        id: 'column.processing_started_time',
        description: '',
        defaultMessage: 'Processing Started On',
    },
    COLUMN_PROCESSING_COMPLETED_TIME: {
        id: 'column.processing_completed_time',
        description: '',
        defaultMessage: 'Processing Completed On',
    },
    DATA_QUALITY_COLUMN_STATUS: {
        id: 'search_data_quality.column.status',
        description: '',
        defaultMessage: 'Status',
    },
    DATA_QUALITY_COLUMN_METRIC: {
        id: 'search_data_quality.column.metric',
        description: '',
        defaultMessage: 'Metric',
    },
    DATA_QUALITY_COLUMN_TYPE: {
        id: 'search_data_quality.column.type',
        description: '',
        defaultMessage: 'Type',
    },
    DATA_QUALITY_COLUMN_VALUE: {
        id: 'search_data_quality.column.value',
        description: '',
        defaultMessage: 'Value',
    },
    DATA_QUALITY_COLUMN_THRESHOLD: {
        id: 'search_data_quality.column.threshold',
        description: '',
        defaultMessage: 'Threshold',
    },
    DATA_QUALITY_LOAD_ERROR: {
        id: 'search_data_quality.load.error',
        description: '',
        defaultMessage: 'Task "Load requirements" failed',
    },
    DATA_QUALITY_TAB_TIER: {
        id: 'search_data_quality.tab.tier',
        description: '',
        defaultMessage: 'TIER {index}',
    },
    DATA_QUALITY_TAB_BADGE_TOOLTIP: {
        id: 'search_data_quality.tab.badge_tooltip',
        description: '',
        defaultMessage: 'Your current tier',
    },
    DATA_QUALITY_TAB_BADGE_TITLE: {
        id: 'search_data_quality.tab.badge_title',
        description: '',
        defaultMessage: 'In Use',
    },
    DATA_QUALITY_TAB_RELEVANCE: {
        id: 'search_data_quality.tab.relevance',
        description: '',
        defaultMessage: 'Relevance',
    },
    DATA_QUALITY_TAB_POPULARITY: {
        id: 'search_data_quality.tab.popularity',
        description: '',
        defaultMessage: 'Relevance & Popularity',
    },
    DATA_QUALITY_TAB_REVENUE: {
        id: 'search_data_quality.tab.revenue',
        description: '',
        defaultMessage: 'Revenue Optimized',
    },
    DATA_QUALITY_TAB_PERSONALIZED: {
        id: 'search_data_quality.tab.personalized',
        description: '',
        defaultMessage: 'Personalized Revenue Optimized',
    },
    DATA_QUALITY_TAB_STATUS_PROBLEM: {
        id: 'search_data_quality.tab.status.problem',
        description: '',
        defaultMessage: '{blocking} Blockers | {critical} Critical',
    },
    DATA_QUALITY_TAB_STATUS_COMPLIANT: {
        id: 'search_data_quality.tab.status.compliant',
        description: '',
        defaultMessage: 'Compliant',
    },
    DATA_QUALITY_ALERT_OK: {
        id: 'search_data_quality.alert.ok',
        description: '',
        defaultMessage: 'There are no blockers or critical items in this tier.',
    },
    DATA_QUALITY_ALERT_ERROR: {
        id: 'search_data_quality.alert.error',
        description: '',
        defaultMessage: 'There is {blocking} Blockers preventing you from the next tier and {critical} Critical item that puts the tier level at risk.',
    },
    DATA_QUALITY_ALMOST_COMPLIANT: {
        id: 'search_data_quality.tooltip.almost',
        description: '',
        defaultMessage: 'This value is below the threshold but it is still compliant.',
    },
    COMPLIANT: {
        id: 'compliant',
        description: '',
        defaultMessage: 'Compliant',
    },
    CRITICAL: {
        id: 'critical',
        description: '',
        defaultMessage: 'Critical',
    },
    BLOCKER: {
        id: 'blocker',
        description: '',
        defaultMessage: 'Blocker',
    },
    NOT_AVAILABLE: {
        id: 'notAvailable',
        description: '',
        defaultMessage: 'Not available',
    },
    UPLOAD_TYPE_BASELINE: {
        id: 'upload_type.baseline',
        description: '',
        defaultMessage: 'Baseline',
    },
    UPLOAD_TYPE_BASELINE_CLEAN: {
        id: 'upload_type.baseline_clean',
        description: '',
        defaultMessage: 'Baseline Clean',
    },
    UPLOAD_TYPE_BASELINE_INVENTORIES: {
        id: 'upload_type.baseline_inventories',
        description: '',
        defaultMessage: 'Baseline Inventories',
    },
    UPLOAD_TYPE_BASELINE_INVENTORIES_CLEAN: {
        id: 'upload_type.baseline_inventories_clean',
        description: '',
        defaultMessage: 'Baseline Inventories Clean',
    },
    UPLOAD_TYPE_PARTIAL: {
        id: 'upload_type.partial',
        description: '',
        defaultMessage: 'Partial',
    },
    UPLOAD_TYPE_PARTIAL_PARTIAL: {
        id: 'upload_type.partial_partial',
        description: '',
        defaultMessage: 'Partial - Partial',
    },
    UPLOAD_TYPE_PARTIAL_CATALOG: {
        id: 'upload_type.partial_catalog',
        description: '',
        defaultMessage: 'Partial Catalog',
    },
    UPLOAD_TYPE_PARTIAL_RECORD: {
        id: 'upload_type.partial_record',
        description: '',
        defaultMessage: 'Partial Record',
    },
    UPLOAD_TYPE_AUTOCOMPLETE_DENY_LIST: {
        id: 'upload_type.autocomplete_deny_list',
        description: '',
        defaultMessage: 'Autocomplete - Deny List',
    },
    UPLOAD_TYPE_LOCAL_INVENTORIES: {
        id: 'upload_type.local_inventories',
        description: '',
        defaultMessage: 'Local Inventories',
    },
    TASK_INGESTION_TASK_LOAD_ALL_ERROR: {
        id: 'task.ingestion_task.load_all.error',
        description: '',
        defaultMessage: 'Task "Load ingestion tasks" failed',
    },
    INGESTION_TASK_VIEW_METADATA: {
        id: 'ingestion_task.tooltip.view_metadata',
        description: '',
        defaultMessage: 'View Metadata',
    },
    TASK_METADATA_LOAD_ALL_ERROR: {
        id: 'task.collection_metadata_task.load_all.error',
        description: '',
        defaultMessage: 'Task "Load collection metadata" failed',
    },
    TASK_METADATA_UPDATE_ERROR: {
        id: 'task.collection_metadata_task.update.error',
        description: '',
        defaultMessage: 'Task "Update collection metadata" failed',
    },
    TASK_METADATA_UPDATE_SUCCESS: {
        id: 'task.collection_metadata_task.update.success',
        description: '',
        defaultMessage: 'Task "Update collection metadata" completed!',
    },
    FIELDS_FIELD_REQUIRED: {
        id: 'fields.field.required',
        description: '',
        defaultMessage: 'Field (required)',
    },
    FIELDS_VALUE_REQUIRED: {
        id: 'fields.value.required',
        description: '',
        defaultMessage: 'Value (required)',
    },
    COLLECTION_METADATA_BUTTON_ADD: {
        id: 'collection_metadata.button.add',
        description: '',
        defaultMessage: 'Add Metadata',
    },
    COLLECTION_METADATA_EMPTY: {
        id: 'collection_metadata.empty',
        description: '',
        defaultMessage: 'No Metadata',
    },
    DESCRIPTION: {
        id: 'description',
        description: '',
        defaultMessage: 'Description',
    },
    EVENT_TYPE: {
        id: 'event.type',
        defaultMessage: 'Event Type',
        description: '',
    },
    EVENT_TYPE_HEADING: {
        id: 'event.type.heading',
        defaultMessage: 'Select an event type',
        description: '',
    },
    EVENT_TYPE_DESCRIPTION: {
        id: 'event.type.description',
        defaultMessage: 'Choose an event type.',
        description: '',
    },
    EVENT_TYPE_REQUIRED: {
        id: 'event.type.required',
        defaultMessage: 'Event Type (required)',
        description: '',
    },
    BUSINESS_OBJECTIVE: {
        id: 'business.objective',
        defaultMessage: 'Business Objective',
        description: '',
    },
    NO_BUSINESS_OBJECTIVE: {
        id: 'no.business.objective',
        defaultMessage: 'No Business Objective available',
        description: '',
    },
    MODEL_TYPE: {
        id: 'model.type',
        defaultMessage: 'Model Type',
        description: '',
    },
    NO_MODEL_TYPE: {
        id: 'no.model.type',
        defaultMessage: 'No Model Type available',
        description: '',
    },
    MODEL_NAME: {
        id: 'model.name',
        defaultMessage: 'Model Name',
        description: '',
    },
    MODEL_TYPE_REQUIRED: {
        id: 'model.type.required',
        defaultMessage: 'Model Type (required)',
        description: '',
    },
    COLLECTION: {
        id: 'collection',
        defaultMessage: 'Collection',
        description: '',
    },
    COLLECTION_REQUIRED: {
        id: 'collection.required',
        defaultMessage: 'Collection (required)',
        description: '',
    },
    REVENUE: {
        id: 'revenue',
        description: '',
        defaultMessage: 'Revenue',
    },
    ADD_TO_CART: {
        id: 'placement.event.type.add_to_cart',
        description: '',
        defaultMessage: 'Add to cart',
    },
    CATEGORY_PAGE_VIEW: {
        id: 'placementr.event.type.category_page_view',
        description: '',
        defaultMessage: 'Category page view',
    },
    DETAIL_PAGE_VIEW: {
        id: 'placement.event.type.detail_page_view',
        description: '',
        defaultMessage: 'Detail page view',
    },
    HOME_PAGE_VIEW: {
        id: 'placement.event.type.home_page_view',
        description: '',
        defaultMessage: 'Home page view',
    },
    PROMOTION_OFFERED: {
        id: 'placement.event.type.promotion_offered',
        description: '',
        defaultMessage: 'Promotion offered',
    },
    PROMOTION_NOT_OFFERED: {
        id: 'placement.event.type.promotion_not_offered',
        description: '',
        defaultMessage: 'Promotion not offered',
    },
    PURCHASE_COMPLETE: {
        id: 'placement.event.type.purchase_complete',
        description: '',
        defaultMessage: 'Purchase complete',
    },
    SHOPPING_CART_PAGE_VIEW: {
        id: 'placement.event.type.shopping_cart_page_view',
        description: '',
        defaultMessage: 'Shopping cart page view',
    },
    OTHERS_YOU_MAY_LIKE: {
        id: 'placement.model.type.others_you_may_like',
        description: '',
        defaultMessage: 'Others you may like',
    },
    FREQUENTLY_BOUGHT_TOGETHER: {
        id: 'placement.model.type.frequently_bought_together',
        description: '',
        defaultMessage: 'Frequently Bought Together',
    },
    RECOMMENDED_FOR_YOU: {
        id: 'placement.model.type.recommended_for_you',
        description: '',
        defaultMessage: 'Recommended for you',
    },
    RECENTLY_VIEWED: {
        id: 'placement.model.type.recently_viewed',
        description: '',
        defaultMessage: 'Recently viewed',
    },
    CONVERSIONS: {
        id: 'conversions',
        description: '',
        defaultMessage: 'Conversions',
    },
    CONVERSION_RATE: {
        id: 'conversion_rate',
        description: '',
        defaultMessage: 'Conversion Rate',
    },
    CONVERSION_RATE_SHORT: {
        id: 'conversion_rate_short',
        description: '',
        defaultMessage: 'Conv. Rate',
    },
    AVERAGE_ORDER_VALUE: {
        id: 'average_order_value',
        description: '',
        defaultMessage: 'Average Order Value',
    },
    AVERAGE_ORDER_VALUE_SHORT: {
        id: 'average_order_value_short',
        description: '',
        defaultMessage: 'Avg. Order Value',
    },
    REVENUE_PER_VISITOR: {
        id: 'revenue_per_visitor',
        description: '',
        defaultMessage: 'Revenue/Visitor',
    },
    COMPARE_TO_PREVIOUS_PERIOD: {
        id: 'home.compare_to_previous_period',
        description: '',
        defaultMessage: 'Compare to previous period',
    },
    INSTANCE: {
        id: 'instance',
        description: '',
        defaultMessage: 'Instance',
    },
    RULE_COLUMN_INSTANCE: {
        id: 'rule.column.instance',
        description: '',
        defaultMessage: 'Instance',
    },
    RULE_COLUMN_INSTANCE_TOOLTIP: {
        id: 'rule.column.instance_tooltip',
        description: '',
        defaultMessage: 'The count of times the rule has fired in the last 7 days',
    },
    RULE_COLUMN_REVENUE: {
        id: 'rule.column.revenue',
        description: '',
        defaultMessage: 'Revenue ($)',
    },
    RULE_COLUMN_REVENUE_TOOLTIP: {
        id: 'rule.column.revenue_tooltip',
        description: '',
        defaultMessage: 'The total value of the orders combined in the last 7 days, while this rule was applied',
    },
    RULE_NOT_FIRED: {
        id: 'rule.not_fired',
        description: '',
        defaultMessage: 'Rule not fired',
    },
    RULE_COLUMN_CTR: {
        id: 'rule.column.ctr',
        description: '',
        defaultMessage: 'CTR',
    },
    RULE_COLUMN_CTR_TOOLTIP: {
        id: 'rule.column.ctr_tooltip',
        description: '',
        defaultMessage: 'The count of product detail page views, divided by the count of searches that occurred where this rule was applied',
    },
    AND: {
        id: 'and',
        description: '',
        defaultMessage: 'And',
    },
    OR: {
        id: 'or',
        description: '',
        defaultMessage: 'Or',
    },
    STARTS_WITH: {
        id: 'starts_with',
        description: '',
        defaultMessage: 'Starts With',
    },
    ENDS_WITH: {
        id: 'ends_with',
        description: '',
        defaultMessage: 'Ends With',
    },
    CONTAINS: {
        id: 'contains',
        description: '',
        defaultMessage: 'Contains',
    },
    TEXT: {
        id: 'text',
        description: '',
        defaultMessage: 'Text',
    },
    ADDITIONAL_FILTER: {
        id: 'additional_filter',
        description: '',
        defaultMessage: 'Additional Filter',
    },
    ADD_FILTER: {
        id: 'add_filter',
        description: '',
        defaultMessage: 'Add Filter',
    },
    RAW_FILTER: {
        id: 'raw.filter',
        description: '',
        defaultMessage: 'Raw Filter',
    },
    ADD_FILTERS: {
        id: 'add.filters',
        description: '',
        defaultMessage: 'Add filters',
    },
    CREATE_RAW_FILTER: {
        id: 'create.raw.filter',
        description: '',
        defaultMessage: 'Create raw filter',
    },
    ADD_FILTER_MAIN_DESCRIPTION: {
        id: 'add_filter.main.description',
        description: '',
        defaultMessage: 'System attributes are predefined attributes. The indexable and searchable properties are not editable for system attributes.',
    },
    ADD_REGULAR_FILTER_DESCRIPTION: {
        id: 'add.regular.filter.description',
        description: '',
        defaultMessage: 'Using the table input you can choose the product attributes and their values to filter from. Multiple attributes can be added using the add filter option. You can choose to include or exclude the attribute from the recommendation panel using the exclude option.',
    },
    ADD_RAW_FILTER_DESCRIPTION: {
        id: 'add.raw.filter.description',
        description: '',
        defaultMessage: 'The Raw filter option provides you the ability to type in the filter expression yourself. Using product attributes, you can create logical expressions for both system and custom attributes. The example below filters for any red or blue products set as "New-Arrival" and not set as promotional. Note: Inventory-related attributes can be used in AND-clauses, but not in OR-clauses',
    },
    PLACEMENT_EDIT: {
        id: 'placement.edit',
        description: '',
        defaultMessage: 'Edit Placement',
    },
    PRODUCT_RECOMMENDATION_EDIT: {
        id: 'product_recommendation.edit',
        description: '',
        defaultMessage: 'Edit Product Recommendation',
    },
    SELECT_A_MODEL_HEADING: {
        id: 'select_a_model.field.heading',
        description: '',
        defaultMessage: 'Select a model',
    },
    SELECT_A_MODEL_DESCRIPTION: {
        id: 'select_a_model.field.description',
        description: '',
        defaultMessage: 'Choose the recommendation type that best captures your business goals.',
    },
    FILTER_BY_SEARCH: {
        id: 'filter_by_search',
        description: '',
        defaultMessage: 'Filter by search',
    },
    PLACEMENT_NUMSELECTED: {
        id: 'placement.numselected',
        description: '',
        defaultMessage: '{numSelected} Placements Selected',
    },
    PRODUCT_RECOMMENDATION_NUMSELECTED: {
        id: 'product_recommendation.numselected',
        description: '',
        defaultMessage: '{numSelected} Product Recommendations Selected',
    },
    TASK_PLACEMENT_COPYTOAREA_SUCCESS: {
        id: 'task.placement.copytoarea.success',
        description: '',
        defaultMessage: 'Task "Copy placement to {targetArea}" completed!',
    },
    PLACEMENT_COPYTOAREA_CONFIRM: {
        id: 'task.placement.copytoarea.confirm',
        description: '',
        defaultMessage: 'A Placement with the name {modelName} exists. Would you like to overwrite it?',
    },
    PRODUCT_RECOMMENDATION_NAME_HELPERTEXT: {
        id: 'product_recommendation.name_helperText',
        description: '',
        defaultMessage: 'Ex. Search > Brands > Nike > July Promote OS',
    },
    TASK_PLACEMENT_COPYTOAREA_ERROR: {
        id: 'task.placement.copytoarea.error',
        description: '',
        defaultMessage: 'Task "Copy placement to {targetArea}" failed',
    },
    PLACEMENT_BULKCOPYTOAREA_CONFIRM: {
        id: 'task.placement.bulkcopytoarea.confirm',
        description: '',
        defaultMessage: 'Placements with the names {modelName} exists. Would you like to overwrite them?',
    },
    STATUS: {
        id: 'status',
        description: '',
        defaultMessage: 'Status',
    },
    BEFORE: {
        id: 'before',
        description: '',
        defaultMessage: 'Before',
    },
    AFTER: {
        id: 'after',
        description: '',
        defaultMessage: 'After',
    },
    BETWEEN: {
        id: 'between',
        description: '',
        defaultMessage: 'Between',
    },
    ON: {
        id: 'on',
        description: '',
        defaultMessage: 'On',
    },
    OFF: {
        id: 'off',
        description: '',
        defaultMessage: 'Off',
    },
    DATE_CREATED: {
        id: 'date_created',
        description: '',
        defaultMessage: 'Date Created',
    },
    DATE_MODIFIED: {
        id: 'date_modified',
        description: '',
        defaultMessage: 'Date Modified',
    },
    DATE: {
        id: 'date',
        description: '',
        defaultMessage: 'Date',
    },
    START_DATE: {
        id: 'start_date',
        description: '',
        defaultMessage: 'Start Date',
    },
    END_DATE: {
        id: 'end_date',
        description: '',
        defaultMessage: 'End Date',
    },
    PLACEMENT_DESCRIPTION: {
        id: 'placement.description',
        description: '',
        defaultMessage: 'Use Placements to control recommendations in your store. Each Placement represents a location where you’ll show recommended products to your customers.',
    },
    PRODUCT_RECOMMENDATIONS_DESCRIPTION: {
        id: 'product_recommendations.description',
        description: '',
        defaultMessage: 'Product Recommendations power the carousels for your store. Each product recommendation represents a location where you\'ll show recommended products to your customers. You can choose the model and apply filters based on product attributes to these recommendation carousels here.',
    },
    PRODUCT_RECOMMENDATION_INFO: {
        id: 'product_recommendation.info',
        description: '',
        defaultMessage: 'Product Recommendation Info',
    },
    PRODUCT_RECOMMENDATIONS_NAME_FIELD_LABEL: {
        id: 'product_recommendations.name.field.label',
        description: '',
        defaultMessage: 'Product Recommendation Name',
    },
    PLACEMENT_DELETE: {
        id: 'placement.delete',
        description: '',
        defaultMessage: 'Delete Placement',
    },
    PRODUCT_RECOMMENDATION_DELETE: {
        id: 'productr_recommendation.delete',
        description: '',
        defaultMessage: 'Delete Product Recommendation',
    },
    TASK_PLACEMENT_CREATE_SUCCESS: {
        id: 'task.placement.create.success',
        description: '',
        defaultMessage: 'Task "Create placement" completed!',
    },
    TASK_PRODUCT_RECOMMENDATIONS_CREATE_SUCCESS: {
        id: 'task.product_recommendations.create.success',
        description: '',
        defaultMessage: 'Task "Create product recommendation" completed!',
    },
    TASK_PLACEMENT_CREATE_ERROR: {
        id: 'task.placement.create.error',
        description: '',
        defaultMessage: 'Task "Create placement" failed',
    },
    TASK_PRODUCT_RECOMMENDATIONS_CREATE_ERROR: {
        id: 'task.product_recommendations.create.error',
        description: '',
        defaultMessage: 'Task "Create product recommendation" failed',
    },
    LOAD_PLACEMENT_MODEL_TYPES_ERROR: {
        id: 'load_placement_model_types_error',
        description: '',
        defaultMessage: 'Failed to load placement model types',
    },
    LOAD_PRODUCT_RECOMMENDATIONS_MODEL_TYPES_ERROR: {
        id: 'load_placement_model_types_error',
        description: '',
        defaultMessage: 'Failed to load placement model types',
    },
    RESTRORE_RULE: {
        id: 'audit.log.restore.rule',
        description: '',
        defaultMessage: 'Restore rule',
    },
    RESTRORE_RULE_SUCCESS: {
        id: 'audit.log.restore.rule.success',
        description: '',
        defaultMessage: 'Task "Restore rule" completed!',
    },
    RESTRORE_RULE_ERROR: {
        id: 'audit.log.restore.rule.error',
        description: '',
        defaultMessage: 'Task "Restore rule" failed!',
    },
    SELECTED_FILTER_SET_DISPLAY_HEADING_FILTERS_INFO: {
        id: 'selected.filter_sets.display.heading.filters.info',
        description: '',
        defaultMessage: 'Contains {numberOfResults} Filters',
    },
    RAW_FILTER_PARENTHESES: {
        id: 'raw.filter.parentheses',
        description: '',
        defaultMessage: '(Raw Filter)',
    },
    FILTER_SETS: {
        id: 'filter_sets',
        description: '',
        defaultMessage: 'Filter Sets',
    },
    FILTER_SET: {
        id: 'filter_set',
        description: '',
        defaultMessage: 'Filter Set',
    },
    FILTER_SET_WARNINGS: {
        id: 'filter_set.warnings',
        description: '',
        defaultMessage: 'Warnings',
    },
    FILTER_SET_VERIFICATION_DELETE: {
        id: 'filter_set.verification_delete',
        description: '',
        defaultMessage: 'Are you sure you want to delete the filter set?',
    },
    FILTER_SET_VERIFICATION_DELETE_DESCRIPTION: {
        id: 'filter_set.verification_delete.description',
        description: '',
        defaultMessage: '<b>{name}</b> is currently in use in <b>{count}</b> Placement(s)',
    },
    FILTER_SET_BULK_DELETE_TOOLTIP: {
        id: 'filter_set.bulk_delete_tooltip',
        description: '',
        defaultMessage: 'Bulk deletion is not allowed for filter set with "In Use" status',
    },
    FILTER_SET_VERIFICATION_DELETE_WARNING_PLACEMENTS: {
        id: 'filter_set.verification_delete.description_warning_placements',
        description: '',
        defaultMessage: '<b>{name}</b> will be removed from the following <b>{count}</b> Placement(s):',
    },
    FILTER_SET_VERIFICATION_DELETE_FAIL: {
        id: 'filter_set.verification_delete_fail',
        description: '',
        defaultMessage: 'Task "Delete Filter Set" failed',
    },
    NO_FILTER_SET: {
        id: 'no.filter_set',
        description: '',
        defaultMessage: 'No Filter Set available',
    },
    FILTER_NAME: {
        id: 'filter_name',
        description: '',
        defaultMessage: 'Filter Name',
    },
    MODEL_ID: {
        id: 'model.id',
        description: '',
        defaultMessage: 'Model Id',
    },
    NO_MODEL_ID: {
        id: 'no.model.id',
        description: '',
        defaultMessage: 'No Model Id available',
    },
    INCLUDE: {
        id: 'include',
        description: '',
        defaultMessage: 'Include',
    },
    INCLUDES: {
        id: 'includes',
        description: '',
        defaultMessage: 'Includes',
    },
    ALWAYS_INCLUDING: {
        id: 'always_including',
        description: '',
        defaultMessage: 'Always including',
    },
    EXCLUDE: {
        id: 'exclude',
        description: '',
        defaultMessage: 'Exclude',
    },
    EXCLUDES: {
        id: 'excludes',
        description: '',
        defaultMessage: 'Excludes',
    },
    ALWAYS_EXCLUDING: {
        id: 'always_excluding',
        description: '',
        defaultMessage: 'Always excluding',
    },
    ATTRIBUTES_SUMMARY_DISPLAY_DESCRIPTION: {
        id: 'attributes_summary_display_description',
        description: '',
        defaultMessage: 'Attributes meeting <b>any</b> of the following conditions',
    },
    DERIVED_FROM_PRODUCT: {
        id: 'derived.from.product',
        description: '',
        defaultMessage: 'Derived From Product',
    },
    FILTER_SET_LABEL: {
        id: 'filter_set.label',
        description: '',
        defaultMessage: 'Search a filter set',
    },
    FILTER_SET_EMPTY_MESSAGE: {
        id: 'filter_set.empty.message',
        description: '',
        defaultMessage: 'No filter set selected yet',
    },
    FILTER_SET_INFO: {
        id: 'filter_set.info',
        description: '',
        defaultMessage: 'Filter Set Info',
    },
    FILTER_SET_NAME: {
        id: 'filter_set.name',
        description: '',
        defaultMessage: 'Filter Set Name',
    },
    FILTER_SET_OPTIONAL: {
        id: 'filter_set.optional',
        description: '',
        defaultMessage: 'Filter Set (optional)',
    },
    FILTER_SET_FIELD_DESCRIPTION: {
        id: 'filter_set.field.description',
        description: '',
        defaultMessage: 'Search and select a filter set from the list.',
    },
    FILTER_SETS_DESCRIPTION: {
        id: 'filter_sets.description',
        description: '',
        defaultMessage: 'Filter Sets allow you to filter your Product Recommendations and narrow the results based on attrributes. There are two ways to apply filters to your Product Recommendations carousel, placement, or shelf. The first is applying the attributes and values in the table below, and the second is through the raw filter input. The list of products in the response is narrowed down to products where the expression evaluates to true. Note: A product attribute can only be used as a filter if the "filterable" product attribute is set to "Yes".',
    },
    FILTER_SET_NEW: {
        id: 'filter_set.new',
        description: '',
        defaultMessage: 'New Filter Set',
    },
    RAW_FILTER_FIELD_LABEL: {
        id: 'raw.filter.field.label',
        description: '',
        defaultMessage: 'Add raw filter here...',
    },
    RAW_FILTER_FIELD_HELPER_TEXT: {
        id: 'raw.filter.field.helper.text',
        description: '',
        defaultMessage: 'ex. colors: ANY("red", "blue") AND attributes.status: ANY("New-Arrival") AND NOT attributes.is_promotional: ANY("true")',
    },
    FILTER_SET_CREATE: {
        id: 'filter_set.create',
        description: '',
        defaultMessage: 'Create Filter Set',
    },
    FILTER_SET_CREATE_DESCRIPTION: {
        id: 'filter_set.create.description',
        description: '',
        defaultMessage: 'Create a set of product attribute filters to limit the product sets returned in product recommendations.',
    },
    FILTER_SET_FILTERS_LABEL: {
        id: 'filter_set.form.filters.label',
        description: '',
        defaultMessage: 'Filters',
    },
    FILTER_SET_FILTERS_COLUMN_FIELD: {
        id: 'filter_set.form.filters.column.field',
        description: '',
        defaultMessage: 'Attribute',
    },
    FILTER_SET_FILTERS_COLUMN_VALUE: {
        id: 'filter_set.form.filters.column.value',
        description: '',
        defaultMessage: 'Value',
    },
    FILTER_SET_FILTERS_COLUMN_REQUIRED: {
        id: 'filter_set.form.filters.column.required',
        description: '',
        defaultMessage: 'Required',
    },
    FILTER_SET_FILTERS_COLUMN_DERIVED_FROM_PRODUCT: {
        id: 'filter_set.form.filters.column.derived_from_product',
        description: '',
        defaultMessage: 'Derived From Product',
    },
    FILTER_SET_FILTERS_COLUMN_EXCLUDE: {
        id: 'filter_set.form.filters.column.exclude',
        description: '',
        defaultMessage: 'Exclude',
    },
    FILTER_SET_FILTERS_ADD_BUTTON: {
        id: 'filter_set.form.filters.add_button',
        description: '',
        defaultMessage: 'Add Filter',
    },
    FILTER_SET_FILTERS_ERROR_NOT_ALLOWED_ATTRIBUTE: {
        id: 'filter_set.form.filters.error_not_allowed_attribute',
        description: '',
        defaultMessage: 'You may use only system attributes or custom attributes with allow types "TEXTUAL" or "UNKNOWN" and with filterable option. For inventory level attributes filtering on the availability attribute is not supported',
    },
    FILTER_SET_FILTERS_ERROR_DERIVED_FROM_PRODUCT: {
        id: 'filter_set.form.filters.error_derived_from_product',
        description: '',
        defaultMessage: 'Filter with attribute marked as derived from product may be only one',
    },
    FILTER_SET_FILTERS_ERROR_INCLUDED_EXCLUDED_COUNT: {
        id: 'filter_set.form.filters.error_included_excluded_count',
        description: '',
        defaultMessage: 'Up to {countIncluded} included filters and {countExcluded} excluded filters allowed',
    },
    FILTER_SET_FILTERS_ERROR_INCLUDED_EXCLUDED_VALUE: {
        id: 'filter_set.form.filters.error_included_excluded_value',
        description: '',
        defaultMessage: 'Same value can not be used to included and excluded filters with the same attribute',
    },
    FILTER_SET_FILTERS_ERROR_COUNT_FILTERS: {
        id: 'filter_set.form.filters.error_count_filters',
        description: '',
        defaultMessage: 'You should add at least one filter for creating FilterSet',
    },
    TASK_FILTER_SET_LOAD_ALL_ERROR: {
        id: 'task.filter_set.load_all.error',
        description: '',
        defaultMessage: 'Task "Load filter sets" failed',
    },
    TASK_FILTER_SET_LOAD_ONE_ERROR: {
        id: 'task.filter_set.loadone.error',
        description: '',
        defaultMessage: 'Task "Load filter set" failed',
    },
    TASK_FILTER_SET_BULK_REMOVE_SUCCESS: {
        id: 'task.filter_set.bulk.remove.success',
        description: '',
        defaultMessage: 'Task "Remove filter set" completed!',
    },
    TASK_FILTER_SET_BULK_REMOVE_ERROR: {
        id: 'task.filter_set.bulk.remove.error',
        description: '',
        defaultMessage: 'Task "Remove filter set" failed',
    },
    TASK_FILTER_SET_REMOVE_SUCCESS: {
        id: 'task.filter_set.remove.success',
        description: '',
        defaultMessage: 'Task "Remove Filter Set" completed!',
    },
    TASK_FILTER_SET_REMOVE_ERROR: {
        id: 'task.filter_set.remove.error',
        description: '',
        defaultMessage: 'Task "Remove filter set {modelName}" failed',
    },
    TASK_FILTER_SET_UPDATE_SUCCESS: {
        id: 'task.filter_set.update.success',
        description: '',
        defaultMessage: 'Task "Update filter set" completed!',
    },
    TASK_FILTER_SET_UPDATE_ERROR: {
        id: 'task.filter_set.update.error',
        description: '',
        defaultMessage: 'Task "Update filter set" failed',
    },
    TASK_FILTER_SET_CREATE_SUCCESS: {
        id: 'task.filter_set.create.success',
        description: '',
        defaultMessage: 'Task "Create filter set" completed!',
    },
    TASK_FILTER_SET_CREATE_ERROR: {
        id: 'task.filter_set.create.error',
        description: '',
        defaultMessage: 'Task "Create filter set" failed',
    },
    SELECTED_WITH_PARENTHESES: {
        id: 'selected.with.parentheses',
        description: '',
        defaultMessage: 'Selected:',
    },
    FILTER_SET_DELETE: {
        id: 'filter_set.delete',
        description: '',
        defaultMessage: 'Delete Filter Set',
    },
    FILTER_SETS_NUMSELECTED: {
        id: 'filter_sets.numselected',
        description: '',
        defaultMessage: '{numSelected} Filter Sets Selected',
    },
    FILTER_SET_REQUIRED: {
        id: 'filter_set.label.required',
        description: '',
        defaultMessage: 'Filter Set (required)',
    },
    FILTER_SET_EDIT: {
        id: 'filter_set.edit',
        description: '',
        defaultMessage: 'Edit Filter Set',
    },
    VALIDATION_JSON: {
        id: 'validation.invalid_json_format',
        description: '',
        defaultMessage: 'The field must be in valid JSON format',
    },
    NUMBER_OF_TRIGGERS: {
        id: 'number_of_triggers',
        description: '',
        defaultMessage: 'Number of Triggers',
    },
    RESTORE_RULE_SUCCESS: {
        id: 'task.audit_log.rule.restore.success',
        description: '',
        defaultMessage: 'Task "Restore rule" completed!',
    },
    RESTORE_RULE_ERROR: {
        id: 'task.audit_log.rule.restore.error',
        description: '',
        defaultMessage: 'Task "Restore rule" failed',
    },
    VERIFY_RESTORE_RULE_ERROR: {
        id: 'task.audit_log.verify.rule.restore.error',
        description: '',
        defaultMessage: 'Task "Verify restore rule" failed',
    },
    RESTORE_WARNINGS_CONFIRM: {
        id: 'task.restore.warnings.confirm',
        description: '',
        defaultMessage: 'Task "Restore" has the following warnings, would you like to proceed?',
    },
    RULE_RESTORE_ERROR_TITLE: {
        id: 'task.rule.restore.error.title',
        description: '',
        defaultMessage: 'We can not restore this rule',
    },
    SELECT_COLLECTION_PAGE_TEXT: {
        id: 'attributes.select_collection.page.text',
        description: '',
        defaultMessage: 'Select a collection to start',
    },
    SELECT_COLLECTION: {
        id: 'select_collection',
        description: '',
        defaultMessage: 'Select a collection',
    },
    LINK_COLLECTION: {
        id: 'link_collection',
        description: '',
        defaultMessage: 'Link with Collection',
    },
    SELECT_AREA_DROPDOWN: {
        id: 'selection_dropdown.select_area',
        description: '',
        defaultMessage: 'Select an area',
    },
    NO_AREA_AVAILABLE: {
        id: 'selection_dropdown.no_area_available',
        description: '',
        defaultMessage: 'No Area Available',
    },
    SELECT_COLLECTION_AND_AREA_PAGE_TEXT: {
        id: 'select_collection_and_area.page.text',
        description: '',
        defaultMessage: 'Select a collection and area to start',
    },
    SELECT_COLLECTION_PAGE_PLACEHOLDER: {
        id: 'attributes.select_collection.page.placeholder',
        description: '',
        defaultMessage: 'Select Collection',
    },
    SELECT_CATEGORY: {
        id: 'select_category',
        description: '',
        defaultMessage: 'Select Category',
    },
    MAKE_TOP_LEVEL_CHECKBOX: {
        id: 'make_top_level',
        description: '',
        defaultMessage: 'Make Top-level',
    },
    NO_PARENT_TOP_LEVEL: {
        id: 'no_parent_top_level',
        description: '',
        defaultMessage: 'No parent (Top-level)',
    },
    EDIT_PARENT_CATEGORY: {
        id: 'edit_parent_category',
        description: '',
        defaultMessage: 'Edit parent category',
    },
    METADATA: {
        id: 'metadata',
        description: '',
        defaultMessage: 'Metadata',
    },
    ATTRIBUTES_FILTER_TITLE: {
        id: 'attributes.filter.title',
        description: '',
        defaultMessage: 'Filter',
    },
    ATTRIBUTES_FILTER_DROPDOWN_LABEL: {
        id: 'attributes.filter.dropdown.label',
        description: '',
        defaultMessage: 'Select Proprty',
    },
    ATTRIBUTES_FILTER_ADD_NEW: {
        id: 'attributes.filter.add_new',
        description: '',
        defaultMessage: 'Add Filter',
    },
    ATTRIBUTES_FILTER_CLEAR: {
        id: 'attributes.filter.clear',
        description: '',
        defaultMessage: 'Clear',
    },
    ATTRIBUTE: {
        id: 'attribute',
        description: '',
        defaultMessage: 'Attribute',
    },
    ATTRIBUTE_TYPE: {
        id: 'attribute.type',
        description: '',
        defaultMessage: 'Type',
    },
    ATTRIBUTES_FILTER_FIELDS_TYPE_LABEL: {
        id: 'attributes.filter.fields.type.label',
        description: '',
        defaultMessage: 'Select Type',
    },
    ATTRIBUTES_FILTER_NO_MATCHING: {
        id: 'attributes.filter.no_matching',
        description: '',
        defaultMessage: 'There\'\'s no matching attribute',
    },
    ATTRIBUTES_FILTER_NO_DATA: {
        id: 'attributes.filter.no_data',
        description: '',
        defaultMessage: 'There\'\'s no attribute at the moment',
    },
    CUSTOM: {
        id: 'custom',
        description: '',
        defaultMessage: 'Custom',
    },
    SYSTEM: {
        id: 'system',
        description: '',
        defaultMessage: 'System',
    },
    INVENTORY: {
        id: 'inventory',
        description: '',
        defaultMessage: 'Inventory',
    },
    STOP_EDITING: {
        id: 'stop_editing',
        description: '',
        defaultMessage: 'Stop Editing',
    },
    YES: {
        id: 'yes',
        description: '',
        defaultMessage: 'Yes',
    },
    NO: {
        id: 'no',
        description: '',
        defaultMessage: 'No',
    },
    CATALOG_CONTROLS: {
        id: 'catalog_controls',
        description: '',
        defaultMessage: 'Catalog Controls',
    },
    EXPERIMENT: {
        id: 'experiment',
        description: '',
        defaultMessage: 'Experiment',
    },
    A_B_TEST: {
        id: 'a_b_test',
        description: '',
        defaultMessage: 'A/B Test',
    },
    SHOW_A_B_TEST: {
        id: 'show_a_b_test',
        description: '',
        defaultMessage: 'Show A/B test',
    },
    RULE_EXPERIMENTS: {
        id: 'rule_experiments',
        description: '',
        defaultMessage: 'Rule Experiments',
    },
    DELETED: {
        id: 'deleted',
        description: '',
        defaultMessage: 'Deleted',
    },
    SET_UP_EXPERIMENT: {
        id: 'set_up_experiment',
        description: '',
        defaultMessage: 'Set up experiment',
    },
    REMOVE_EXPERIMENT: {
        id: 'remove_experiment',
        description: '',
        defaultMessage: 'Remove experiment',
    },
    STOP_EXPERIMENT: {
        id: 'stop_experiment',
        description: '',
        defaultMessage: 'Stop experiment',
    },
    STOP_EXPERIMENT_SUCCESS: {
        id: 'stop_experiment.success',
        description: '',
        defaultMessage: 'Experiment stopped.',
    },
    SET_UP_EXPERIMENT_INSTRUCTIONS_TITLE: {
        id: 'set_up_experiment.instructions.title',
        description: '',
        defaultMessage: 'Please follow the instruction to set up the experiment:',
    },
    SET_UP_EXPERIMENT_INSTRUCTIONS_BUTTON_RULE: {
        id: 'set_up_experiment.instructions.button_rule',
        description: '',
        defaultMessage: 'Click {buttonTitle} button',
    },
    SET_UP_EXPERIMENT_INSTRUCTIONS_VARIANTS_RULE: {
        id: 'set_up_experiment.instructions.variants_rule',
        description: '',
        defaultMessage: 'Add variants',
    },
    SET_UP_EXPERIMENT_INSTRUCTIONS_RULE_SETTINGS_RULE: {
        id: 'set_up_experiment.instructions.rule_settings_rule',
        description: '',
        defaultMessage: 'Configure rule settings',
    },
    SET_UP_EXPERIMENT_INSTRUCTIONS_TRIGGER_EXPERIMENT_RULE: {
        id: 'set_up_experiment.instructions.trigger_experiment_rule',
        description: '',
        defaultMessage: '{actionTitle} the rule to start the experiment',
    },
    CREATE_RULE_WITH_EXPERIMENT_CONFIRMATION_TITLE: {
        id: 'create_rule_with_experiment.confirmation.title',
        description: '',
        defaultMessage: 'By creating the rule, you are starting the experiment',
    },
    CREATE_RULE_WITH_EXPERIMENT_CONFIRMATION_MESSAGE: {
        id: 'create_rule_with_experiment.confirmation.message',
        description: '',
        defaultMessage: 'Once the experiment starts, you cannot make edits to the variants that are running. You can only add or remove variants.',
    },
    CREATE_RULE_WITH_EXPERIMENT_SUCCESS: {
        id: 'create_rule_with_experiment.success',
        description: '',
        defaultMessage: 'Rule "{ruleName}" created with experiment.',
    },
    STOP_EXPERIMENT_CONFIRMATION_TITLE: {
        id: 'stop_experiment.confirmation.title',
        description: '',
        defaultMessage: 'Are you sure you want to stop the experiment?',
    },
    STOP_EXPERIMENT_CONFIRMATION_DESCRIPTION: {
        id: 'stop_experiment.confirmation.description',
        description: '',
        defaultMessage: 'Please proceed with caution. You cannot undo this action. Any unsaved rule information will be lost.',
    },
    STOP_EXPERIMENT_CONFIRMATION_SUBMIT: {
        id: 'stop_experiment.confirmation.submit',
        description: '',
        defaultMessage: 'Stop experiment',
    },
    STOP_EXPERIMENT_SELECT_WINNER_TITLE: {
        id: 'stop_experiment.select_winner.title',
        description: '',
        defaultMessage: 'Select a winner',
    },
    STOP_EXPERIMENT_SELECT_WINNER_DESCRIPTION: {
        id: 'stop_experiment.select_winner.description',
        description: '',
        defaultMessage: 'Select a winner for this test. The winning variant configurations will be applied to the rule, and the other variants will be removed.',
    },
    TASK_EXPERIMENT_STATISTICS_LOAD_ERROR: {
        id: 'task.experiment.statistics.load.error',
        description: '',
        defaultMessage: 'Failed to load experiment statistics.',
    },
    EXPERIMENT_TRAFFIC_SPLIT: {
        id: 'experiment.traffic_split',
        description: '',
        defaultMessage: 'Traffic Split',
    },
    EXPERIMENT_TRAFFIC_SPLIT_DISTRIBUTE_EVENLY: {
        id: 'experiment.traffic_split.distribute_evenly',
        description: '',
        defaultMessage: 'Evenly distribute traffic',
    },
    EXPERIMENT_TRAFFIC_SPLIT_LOCK_TITLE: {
        id: 'experiment.traffic_split.lock.title',
        description: '',
        defaultMessage: 'Lock the value',
    },
    EXPERIMENT_TRAFFIC_SPLIT_UNLOCK_TITLE: {
        id: 'experiment.traffic_split.unlock.title',
        description: '',
        defaultMessage: 'Unlock to edit',
    },
    EXPERIMENT_TRAFFIC_SPLIT_COLOR_TOOLTIP: {
        id: 'experiment.traffic_split.color.tooltip',
        description: '',
        defaultMessage: 'Rule not saved yet to show data',
    },
    REMOVE_SECOND_LAST_VARIANT_DIALOG_TITLE: {
        id: 'remove_second_last_variant_dialog.title',
        description: '',
        defaultMessage: 'Remove this variant and stop the experiment?',
    },
    REMOVE_SECOND_LAST_VARIANT_DIALOG_DESCRIPTION: {
        id: 'remove_second_last_variant_dialog.description',
        description: '',
        defaultMessage: 'There must be at least two variants to continue the experiment. Removing this variant will stop the experiment and select {variantName} as the winner.',
    },
    NUMBER_OF_TRIGGERS_CHART_TITLE: {
        id: 'number_of_triggers_chart.title',
        description: '',
        defaultMessage: 'Number of Triggers',
    },
    CONVERSION_RATE_CHART_TITLE: {
        id: 'conversion_rate_chart.title',
        description: '',
        defaultMessage: 'Conversion rate',
    },
    REVENUE_PER_VISIT_CHART_TITLE: {
        id: 'revenue_per_visit_chart.title',
        description: '',
        defaultMessage: 'Revenue Per Visit',
    },
    REVENUE_CHART_TITLE: {
        id: 'revenue_chart.title',
        description: '',
        defaultMessage: 'Revenue',
    },
    RULE_GLOBAL_INFO_DESCRIPTION: {
        id: 'rule_global_info.description',
        description: '',
        defaultMessage: 'Experiment is enabled. Changes made to Rule info, tags, and triggers will be applied to all variants.',
    },
    RULE_ACTIVE_VARIANT_INFO_DESCRIPTION: {
        id: 'rule_active_variant_info.description',
        description: '',
        defaultMessage: 'Experiment is running. Active variants in a running experiment cannot be edited. You may still remove or add new variants, or change the traffic split between variants.',
    },
    EXPERIMENT_ENABLED_INFO_DESCRIPTION: {
        id: 'experiment_enabled_info.description',
        description: '',
        defaultMessage: 'The experiment has not been started yet. Click on {actionSave} to start the experiment or click {actionRemove} to discard the experiment.',
    },
    ADD_VARIANT: {
        id: 'add_variant',
        description: '',
        defaultMessage: 'Add Variant',
    },
    ADD_VARIANT_DIALOG_TITLE: {
        id: 'add_variant.dialog.title',
        description: '',
        defaultMessage: 'Please name the new variant',
    },
    ADD_VARIANT_DIALOG_HINT: {
        id: 'add_variant.dialog.hint',
        description: '',
        defaultMessage: 'You cannot change this name.',
    },
    ADD_VARIANT_DIALOG_FIELD_PLACEHOLDER: {
        id: 'add_variant.dialog.field.placeholder',
        description: '',
        defaultMessage: 'Write variant name here...',
    },
    VARIANT_LIMIT_EXCEED_DIALOG_TITLE: {
        id: 'variant_limit_exceed.dialog.title',
        description: '',
        defaultMessage: 'Please add more variant(s) or cancel the experiment.',
    },
    VARIANT_LIMIT_EXCEED_DIALOG_DESCRIPTION: {
        id: 'variant_limit_exceed.dialog.description',
        description: '',
        defaultMessage: 'There must be at least 2 variants to conduct an experiment. You can either add more variants to continue with the experiment, or stop the experiment. Ending the experiment will set the rule\'\'s configurations using the configurations of the last remaining variant.',
    },
    VARIANT_REMOVE_CONFIRMATION_DIALOG_TITLE: {
        id: 'variant_remove.confirmation_dilog.title',
        description: '',
        defaultMessage: 'Are you sure you want to remove an existing variant?',
    },
    VARIANT_REMOVE_CONFIRMATION_DIALOG_WARNING: {
        id: 'variant_remove.confirmation_dilog.warning',
        description: '',
        defaultMessage: 'You cannot undo the action.',
    },
    VARIANT_REMOVE_CONFIRMATION_DIALOG_DESCRIPTION: {
        id: 'variant_remove.confirmation_dilog.description',
        description: '',
        defaultMessage: 'The experiment will continue to run unless you stop it. Please go to the EXPERIMENT tab to adjust the traffic split across the remaining variants.',
    },
    REMOVE: {
        id: 'remove',
        description: '',
        defaultMessage: 'Remove',
    },
    METADATA_EMPTY_TITLE: {
        id: 'metadata.empty_title',
        description: '',
        defaultMessage: 'No metadata exists. Please add new',
    },
    HOME_DASHBOARDS_WELCOME: {
        id: 'home_dashboards.welcome',
        description: '',
        defaultMessage: 'Hi {name}, welcome back. Here\'s what\'s been happening.',
    },
    VISITORS: {
        id: 'visitors',
        description: '',
        defaultMessage: 'Visitors',
    },
    TOP_SEARCHES: {
        id: 'top_searches',
        description: '',
        defaultMessage: 'Top Searches',
    },
    TOP_PERFORMING_RULES: {
        id: 'top_performing_rules',
        description: '',
        defaultMessage: 'Top Performing Rules',
    },
    TODAY: {
        id: 'today',
        description: '',
        defaultMessage: 'Today',
    },
    TOP_NULL_SEARCHES: {
        id: 'top_null_searches',
        description: '',
        defaultMessage: 'Top Null Searches',
    },
    BULK_ACTIONS: {
        id: 'bulk_actions',
        description: '',
        defaultMessage: 'Bulk actions',
    },
    APPLY: {
        id: 'apply',
        description: '',
        defaultMessage: 'Apply',
    },
    APPLIED: {
        id: 'applied',
        description: '',
        defaultMessage: 'Applied',
    },
    REMOVED: {
        id: 'removed',
        description: '',
        defaultMessage: 'Removed',
    },
    NO_CHANGE: {
        id: 'no_change',
        description: '',
        defaultMessage: 'No Change',
    },
    ATTRIBUTE_BULK_UPDATE_CONFIRMATION_TITLE: {
        id: 'attribute.bulk_update.confirmation.title',
        description: '',
        defaultMessage: 'Dynamic Faceting Cannot Be Changed',
    },
    ATTRIBUTE_BULK_UPDATE_CONFIRMATION_TEXT: {
        id: 'attribute.bulk_update.confirmation.text',
        description: '',
        defaultMessage: 'When indexable is set to NO, dynamic faceting cannot be set to YES. If you\'\'d like to change dynamic faceting, please check the status of indexable.',
    },
    ATTRIBUTE_BULK_UPDATE_CONFIRMATION_CONFIRM: {
        id: 'attribute.bulk_update.confirmation.confirm',
        description: '',
        defaultMessage: 'Ok, go back',
    },
    INDEXABLE_SYSTEM_NUMERIC_ALERT_TITLE: {
        id: 'indexable.alert.title',
        description: '',
        defaultMessage: 'Indexable Cannot Be Changed',
    },
    INDEXABLE_SYSTEM_INVENTORY_ALERT_TEXT: {
        id: 'indexable_system_inventory.alert.text',
        description: '',
        defaultMessage: 'Numeric system attributes are predefined by the Retail which can\'t be changed.',
    },
    INDEXABLE_SYSTEM_INVENTORY_BULK_ALERT_TEXT: {
        id: 'indexable_system_inventory_bulk.alert.text',
        description: '',
        defaultMessage: 'There is <accent>inventories.price</accent> or <accent>inventories.original_price</accent> selected in multi-selection. Those are numeric system attributes predefined by the Retail which can\'t be changed.',
    },
    FILTERABLE_ALERT_TITLE: {
        id: 'filterable.alert.title',
        description: '',
        defaultMessage: 'Filterable Cannot Be Changed',
    },
    FILTERABLE_ALERT_TEXT: {
        id: 'filterable.alert.text',
        description: '',
        defaultMessage: 'The filterable can be set only when the type is “Textual” and the attribute name has prefix of “attributes.”. If you\'\'d like to change filterable, please check the type and attribute\'\'s name.',
    },
    EXACT_MATCH_ALERT_TITLE: {
        id: 'exact_match.alert.title',
        description: '',
        defaultMessage: 'Exact Match Cannot Be Changed',
    },
    EXACT_MATCH_ALERT_TEXT: {
        id: 'exact_match.alert.text',
        description: '',
        defaultMessage: 'The exact match can be set only when the type is “Textual” or “Unknown” of the custom attribute and requires indexable set to YES to enable exact-searchable. If you\'\'d like to change the exact match, please check the type and the status of indexable.',
    },
    PUBLISHED: {
        id: 'rule_table.status.published',
        description: '',
        defaultMessage: 'Published',
    },
    EXPIRED: {
        id: 'rule_table.status.expired',
        description: '',
        defaultMessage: 'Expired',
    },
    SCHEDULED: {
        id: 'rule_table.status.scheduled',
        description: '',
        defaultMessage: 'Scheduled',
    },
    DRAFT: {
        id: 'rule_table.status.draft',
        description: '',
        defaultMessage: 'Draft',
    },
    ALL: {
        id: 'rule_table.status.all',
        description: '',
        defaultMessage: 'All',
    },
    REORDER_DISABLED: {
        id: 'rule_table.reorder_disabled',
        description: '',
        defaultMessage: 'Sort by Priority to reorder rules',
    },
    RULE_TABLE_CONTENT_EXIST: {
        id: 'rule_table.content_exist',
        description: '',
        defaultMessage: 'Existing content',
    },
    RULE_TABLE_IN_EXPERIMENT: {
        id: 'rule_table.in_experiment',
        description: '',
        defaultMessage: 'In experiment',
    },
    AUTH0_LOGIN_FORM_ALERT: {
        id: 'auth0.logon.form.alert',
        description: '',
        defaultMessage: 'Access denied. Please check if information provided is correct.',
    },
    ATTRIBUTE_DESCRIPTION_BOX_TITLE: {
        id: 'attributes.description_box.title',
        description: '',
        defaultMessage: '{attributeGroup} Attribute',
    },
    SYSTEM_ATTRIBUTE_DESCRIPTION_BOX_TEXT: {
        id: 'attributes.system.description_box.text',
        description: '',
        defaultMessage: 'System attributes are predefined attributes. The indexable, searchable, exact match and filterable properties are not editable for system attributes',
    },
    CUSTOM_ATTRIBUTE_DESCRIPTION_BOX_TEXT: {
        id: 'attributes.custom.description_box.text',
        description: '',
        defaultMessage: 'For custom attributes, dynamic faceting can only be set to yes if indexable is set to yes. The filterable can be set only when the type is “Textual” and the attribute name has prefix of “attributes.”. Exact match can only be changed for custom attributes of textual or unknown type and requires indexable set to yes to enable exact-searchable',
    },
    INVENTORY_ATTRIBUTE_DESCRIPTION_BOX_TEXT: {
        id: 'attributes.inventory.description_box.text',
        description: '',
        defaultMessage: 'Inventory attributes are system or custom attributes that provide store-level information about the product. You might use inventory-level attributes for products whose properties vary significantly between stores and regions, such as grocery items, or products that have store-specific promotions.',
    },
    INVENTORY_ATTRIBUTE_DESCRIPTION_BOX_TEXT_NOTE: {
        id: 'attributes.inventory.description_box.text.note',
        description: '',
        defaultMessage: '<b>Note:</b> You can create custom inventory-level attributes, which can be textual or numeric. Inventory-level attributes are indexable by default. Setting searchable and dynamic faceting is not supported for inventory-level attributes.',
    },
    ATTRIBUTE_MANAGEMENT_PAGE_DESCRIPTION_COMMON: {
        id: 'attributes.description.common',
        description: '',
        defaultMessage: 'Use this table to configure behavior pertaining to product attributes for search and recommendations. Changes to indexable and searchable will take effect after 12 hours or longer, or immediately on your next ingestion. Changes to dynamic faceting and exact match will take effect within 2 days.',
    },
    ATTRIBUTE_MANAGEMENT_PAGE_DESCRIPTION_IN_USE: {
        id: 'attributes.description.in_use',
        description: '',
        defaultMessage: '“In Use” attributes are active attributes and “In Use” = No are the attributes that no product in your catalog is currently using. You can still view them over here and make changes to their properties. If these attributes are in any new products after a new ingestion, then they will maintain the state that is specified here.',
    },
    INFLUENCE: {
        id: 'influence',
        description: '',
        defaultMessage: 'Influence',
    },
    HOME_DASHBOARDS_TIMEFRAME_AMOUNT_OF_DAYS: {
        id: 'home_dashboards.timeframe.amount_of_days',
        description: '',
        defaultMessage: '{amountOfDays} days',
    },
    HOME_DASHBOARDS_TIMEFRAME_1_YEAR: {
        id: 'home_dashboards.timeframe.1_year',
        description: '',
        defaultMessage: '1 year',
    },
    HOME_DASHBOARDS_TIMEFRAME_ALL: {
        id: 'home_dashboards.timeframe.all',
        description: '',
        defaultMessage: 'All',
    },
    HOME_DASHBOARDS_TOP_SEARCHES_REVENUE: {
        id: 'home_dashboards.top_searches.revenue',
        description: '',
        defaultMessage: 'Revenue',
    },
    HOME_DASHBOARDS_TOP_SEARCHES_SEARCHES: {
        id: 'home_dashboards.top_searches.searches',
        description: '',
        defaultMessage: 'Searches',
    },
    HOME_DASHBOARDS_TOP_SEARCHES_ORDERS: {
        id: 'home_dashboards.top_searches.orders',
        description: '',
        defaultMessage: 'Orders',
    },
    HOME_DASHBOARDS_TOP_SEARCHES_CTR: {
        id: 'home_dashboards.top_searches.ctr',
        description: '',
        defaultMessage: 'CTR',
    },
    LOAD_TOP_SEARCHES_ERROR: {
        id: 'home_dashboards.top_searches.load_error',
        description: '',
        defaultMessage: 'Failed to load "Top Searches" list.',
    },
    LOAD_TOP_NULL_SEARCHES_ERROR: {
        id: 'home_dashboards.top_null_searches.load_error',
        description: '',
        defaultMessage: 'Failed to load "Top Null Searches" list.',
    },
    LOAD_SEARCH_PERFORMANCE_ERROR: {
        id: 'home_dashboards.search_performance.load_error',
        description: '',
        defaultMessage: 'Failed to load "Search Performance" dashboard.',
    },
    LOAD_SEARCH_REVENUE_ERROR: {
        id: 'home_dashboards.search_revenue.load_error',
        description: '',
        defaultMessage: 'Failed to load "Search Revenue" dashboard.',
    },
    LOAD_AVERAGE_ORDER_VALUE_ERROR: {
        id: 'home_dashboards.average_order_value.load_error',
        description: '',
        defaultMessage: 'Failed to load "Average Order Value" dashboard.',
    },
    LOAD_VISITORS_VALUE_ERROR: {
        id: 'home_dashboards.visitors.load_error',
        description: '',
        defaultMessage: 'Failed to load "Visitors" dashboard.',
    },
    GERMAN: {
        id: 'languages.german',
        description: '',
        defaultMessage: 'German',
    },
    ITALIAN: {
        id: 'languages.italian',
        description: '',
        defaultMessage: 'Italian',
    },
    DUTCH: {
        id: 'languages.dutch',
        description: '',
        defaultMessage: 'Dutch',
    },
    JAPANESE: {
        id: 'languages.japanese',
        description: '',
        defaultMessage: 'Japanese',
    },
    PENDING_TOOLTIP: {
        id: 'pending_tooltip',
        description: '',
        defaultMessage: 'Pending changes to be effective within {hours} hours from {date}',
    },
    SEARCH_PERFORMANCE_LEGEND_CR: {
        id: 'search_performance.legend.cr',
        description: '',
        defaultMessage: 'Conversion Rate',
    },
    SEARCH_PERFORMANCE_LEGEND_CTR: {
        id: 'search_performance.legend.ctr',
        description: '',
        defaultMessage: 'Clickthrough Rate',
    },
    SEARCH_PERFORMANCE_LEGEND_ATCR: {
        id: 'search_performance.legend.atcr',
        description: '',
        defaultMessage: 'Add to Cart Rate',
    },
    PREVIEW_PANEL_SEE_RESULT: {
        id: 'preview_panel.see_result',
        description: '',
        defaultMessage: 'See Result',
    },
    PREVIEW_PANEL_RELOAD: {
        id: 'preview_panel.reload',
        description: '',
        defaultMessage: 'Reload',
    },
    PREVIEW_PANEL_INSTRUCTIONS_DESCRIPTION: {
        id: 'preview_panel.instructions_description',
        description: '',
        defaultMessage: 'Please follow the instructions to set up the preview:',
    },
    PREVIEW_PANEL_INSTRUCTIONS_BIASING: {
        id: 'preview_panel.instructions.biasing',
        description: '',
        defaultMessage: 'Configure biasing information',
    },
    PREVIEW_PANEL_INSTRUCTIONS_SEARCH_QUERY: {
        id: 'preview_panel.instructions.search_query',
        description: '',
        defaultMessage: 'Enter a search query',
    },
    PREVIEW_PANEL_INSTRUCTIONS_SEE_RESULTS: {
        id: 'preview_panel.instructions.see_results',
        description: '',
        defaultMessage: 'Click <b>See Results</b>',
    },
    PREVIEW_PANEL_INSTRUCTIONS_RULE_ONE: {
        id: 'preview_panel.instructions.rule_one',
        description: '',
        defaultMessage: 'Fill in the Rule info',
    },
    PREVIEW_PANEL_INSTRUCTIONS_QUERY_RULE_TWO: {
        id: 'preview_panel.instructions.query.rule_two',
        description: '',
        defaultMessage: 'Set up the Trigger Sets for search query',
    },
    PREVIEW_PANEL_INSTRUCTIONS_QUERY_RULE_THREE: {
        id: 'preview_panel.instructions.query.rule_three',
        description: '',
        defaultMessage: 'Search query',
    },
    PREVIEW_PANEL_INSTRUCTIONS_BROWSE_RULE_TWO: {
        id: 'preview_panel.instructions.browse.rule_two',
        description: '',
        defaultMessage: 'Set up the Trigger Sets for browse',
    },
    PREVIEW_PANEL_INSTRUCTIONS_BROWSE_RULE_THREE: {
        id: 'preview_panel.instructions.browse.rule_three',
        description: '',
        defaultMessage: 'Search a browse path',
    },
    PREVIEW_PANEL_TRIGGERED_RULE: {
        id: 'preview_panel.triggered_rule',
        description: '',
        defaultMessage: 'Rule {rule} was triggered.',
    },
    PREVIEW_PANEL_NO_RULE_TRIGGERED: {
        id: 'preview_panel.no_rule_triggered',
        description: '',
        defaultMessage: 'No rule was triggered.',
    },
    PREVIEW_PANEL_ORIGINAL_RULE: {
        id: 'preview_panel.original_rule',
        description: '',
        defaultMessage: 'Another rule with the higher priority exists with the same trigger set:',
    },
    PREVIEW_PANEL_ORIGINAL_RULE_APPLIED: {
        id: 'preview_panel.original_rule_applied',
        description: '',
        defaultMessage: 'Rule {rule} with a priority of {priority}',
    },
    PREVIEW_PANEL_BIAS_APPLIED: {
        id: 'preview_panel.bias_applied',
        description: '',
        defaultMessage: 'Biasing {biasingProfile} is applied.',
    },
    PREVIEW_PANEL_NO_BIAS_APPLIED: {
        id: 'preview_panel.no_bias_applied',
        description: '',
        defaultMessage: '{biasingProfile} is applied.',
    },
    INFO_AND_TRIGGERS: {
        id: 'rule.tabs.info_and_triggers',
        description: '',
        defaultMessage: 'Info & Triggers',
    },
    RESULT_MANAGEMENT: {
        id: 'rule.tabs.result_management',
        description: '',
        defaultMessage: 'Result Management',
    },
    CURATE_RESULTS: {
        id: 'rule.tabs.curate_results',
        description: '',
        defaultMessage: 'Curate Results',
    },
    RECALL: {
        id: 'rule.tabs.recall',
        description: '',
        defaultMessage: 'Recall',
    },
    RECALL_TOOLTIP: {
        id: 'rule.tabs.recall_tooltip',
        description: '',
        defaultMessage: 'Customize what comes back in your results set using product IDs or attributes from your catalog title',
    },
    CREATE_CURATE_RESULTS_BY_ID_EDIT: {
        id: 'rule.curate_results.create_by_id.edit',
        description: '',
        defaultMessage: 'Products',
    },
    CREATE_CURATE_RESULTS_BY_ID_EDIT_OLD: {
        id: 'rule.curate_results.create_by_id.edit_old',
        description: '',
        defaultMessage: 'Result by product',
    },
    CREATE_CURATE_RESULTS_BY_ID: {
        id: 'rule.curate_results.create_by_id',
        description: '',
        defaultMessage: 'Products',
    },
    CREATE_CURATE_RESULTS_BY_ID_OLD: {
        id: 'rule.curate_results.create_by_id_old',
        description: '',
        defaultMessage: 'Add product by product ID',
    },
    CREATE_CURATE_RESULTS_BY_ID_INSTRUCTION: {
        id: 'rule.curate_results.create_by_id.instruction',
        description: '',
        defaultMessage: 'Include or exclude products based on product IDs, overriding attribute configurations',
    },
    CREATE_CURATE_RESULTS_BY_ID_INSTRUCTION_OLD: {
        id: 'rule.curate_results.create_by_id.instruction_old',
        description: '',
        defaultMessage: 'Select the products you want to include or exclude in your result set.',
    },
    CURATE_RESULTS_PLUS: {
        id: 'rule.curate_results.plus',
        description: '',
        defaultMessage: 'PLUS',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_EDIT: {
        id: 'rule.curate_results.create_by_attribute.edit',
        description: '',
        defaultMessage: 'Attributes',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_EDIT_OLD: {
        id: 'rule.curate_results.create_by_attribute.edit_old',
        description: '',
        defaultMessage: 'Result by attribute',
    },
    CREATE_CURATE_RESULTS_ATTRIBUTE_EDIT_CONDITION: {
        id: 'rule.curate_results.attribute_edit_condition',
        description: '',
        defaultMessage: 'Condition',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE: {
        id: 'rule.curate_results.create_by_attribute',
        description: '',
        defaultMessage: 'Attributes',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_NEW: {
        id: 'rule.curate_results.create_by_attribute_new',
        description: '',
        defaultMessage: 'Add product by attribute',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_OLD: {
        id: 'rule.curate_results.create_by_attribute_old',
        description: '',
        defaultMessage: 'Add product by product attribute',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_INSTRUCTION: {
        id: 'rule.curate_results.create_by_attribute.instruction',
        description: '',
        defaultMessage: 'Include or exclude attributes and values',
    },
    CREATE_CURATE_RESULTS_BY_ATTRIBUTE_INSTRUCTION_OLD: {
        id: 'rule.curate_results.create_by_attribute.instruction_old',
        description: '',
        defaultMessage: 'Select the attributes you want to include or exclude in your result set.',
    },
    CURATE_RESULTS_ADD_ATTRIBUTE: {
        id: 'rule.curate_results.add_attribute',
        description: '',
        defaultMessage: 'Add attribute',
    },
    CURATE_RESULTS_ADD_CONDITION_GROUP: {
        id: 'rule.curate_results.add_condition_group',
        description: '',
        defaultMessage: 'Add condition group',
    },
    CURATE_RESULTS_SELECT_ATTRIBUTE: {
        id: 'rule.curate_results.select_attribute',
        description: '',
        defaultMessage: 'Select attribute',
    },
    CONTENT_MANAGEMENT: {
        id: 'rule.tabs.content_management',
        description: '',
        defaultMessage: 'Content Management',
    },
    RULE_TAB_CONTENT: {
        id: 'rule.tabs.content',
        description: '',
        defaultMessage: 'Content',
    },
    ADD_TAGS: {
        id: 'rule.fields.add_tags',
        description: '',
        defaultMessage: 'Add tags',
    },
    CUSTOMIZE: {
        id: 'customize',
        description: '',
        defaultMessage: 'Customize',
    },
    CUSTOMIZE_VIEW: {
        id: 'customize.view',
        description: '',
        defaultMessage: 'Customize View',
    },
    CUSTOMIZE_VIEW_MAX_CHECKED_ATTRIBUTES: {
        id: 'customize.view.max_checked_attributes',
        description: '',
        defaultMessage: 'Max {count} attributes can be added to the product tile.',
    },
    EMBEDDED_ANALYTICS: {
        id: 'embedded.analytics',
        description: '',
        defaultMessage: 'Embedded Analytics',
    },
    CONFIGURE_FIELDS: {
        id: 'configure.fields',
        description: '',
        defaultMessage: 'Configure Fields',
    },
    CONFIGURE_MODULES: {
        id: 'configure.modules',
        description: '',
        defaultMessage: 'Configure Modules',
    },
    RULE_ID: {
        id: 'rule_id',
        description: '',
        defaultMessage: 'Rule ID',
    },
    RULE_INFO: {
        id: 'rule_info',
        description: '',
        defaultMessage: 'Rule Info',
    },
    TRIGGER_SETS: {
        id: 'trigger_sets',
        description: '',
        defaultMessage: 'Trigger Sets',
    },
    RULE_TEMPLATE_DESCRIPTION: {
        id: 'rule.template.description',
        description: '',
        defaultMessage: 'A <b>template</b> is a pre-made framework that brings additional content to the results page. It contains sections with zone requirements.',
    },
    RULE_TEMPLATE_HINT: {
        id: 'rule.template.hint',
        description: '',
        defaultMessage: 'Inside a <b>section</b>, you can add <b>zones</b> which is a pre-made content. You can create a zone from here to customize the content.',
    },
    TRIGGERS_EMPTY: {
        id: 'triggers_empty',
        description: '',
        defaultMessage: 'No triggers added yet',
    },
    FILTERS_EMPTY: {
        id: 'filters_empty',
        description: '',
        defaultMessage: 'No filter type added yet',
    },
    RULE_ID_COPIED: {
        id: 'rule_id.copied',
        description: '',
        defaultMessage: 'Successfully copied the Rule ID',
    },
    RULE_ANALYTICS_PERIOD_TITLE: {
        id: 'rule.anylytics.period_title',
        description: '',
        defaultMessage: 'Data shown for the past',
    },
    EXPAND_PREVIEW: {
        id: 'expand_Preview',
        description: '',
        defaultMessage: 'Expand Preview',
    },
    MORE: {
        id: 'more',
        description: '',
        defaultMessage: 'More',
    },
    EXPAND: {
        id: 'expand',
        description: '',
        defaultMessage: 'Expand',
    },
    EXPAND_ALL: {
        id: 'expand_all',
        description: '',
        defaultMessage: 'Expand All',
    },
    COLLAPSE: {
        id: 'collapse',
        description: '',
        defaultMessage: 'Collapse',
    },
    COLLAPSE_ALL: {
        id: 'collapse_all',
        description: '',
        defaultMessage: 'Collapse All',
    },
    REF_APP_TOTAL_RESULTS: {
        id: 'reference_application.total_results',
        description: '',
        defaultMessage: 'Showing <highlight>{total}</highlight> results.',
    },
    VIEW_RECORD: {
        id: 'view_record',
        description: '',
        defaultMessage: 'Record',
    },
    PLP: {
        id: 'plp',
        description: '',
        defaultMessage: 'PLP',
    },
    PDP: {
        id: 'pdp',
        description: '',
        defaultMessage: 'PDP',
    },
    CLOSE: {
        id: 'close',
        description: '',
        defaultMessage: 'Close',
    },
    RANKING_PIN_PRODUCT: {
        id: 'ranking.pin_product',
        description: '',
        defaultMessage: 'Pin Products',
    },
    RANKING_PIN_PRODUCT_POSITION: {
        id: 'ranking.pin_product.position',
        description: '',
        defaultMessage: 'Position',
    },
    RANKING_PIN_PRODUCT_DESCRIPTION: {
        id: 'ranking.pin_product_description',
        description: '',
        defaultMessage: 'Guarantee a product placement to a designated slot',
    },
    RANKING_PIN_PRODUCT_EDIT_DESCRIPTION: {
        id: 'ranking.pin_product_edit_description',
        description: '',
        defaultMessage: 'Pinning products guarantees the position you set and ensures this products inclusion in the recall. Input the product ID and specify the desired slot number from 1 to 24. When activating a rule utilizing Pin Product, a second query is fired, and this additional query is counted against your quota.',
    },
    RANKING_BIAS_PRODUCT: {
        id: 'ranking.bias',
        description: '',
        defaultMessage: 'Bias Products',
    },
    RANKING_BIAS_DESCRIPTION: {
        id: 'ranking.bias_description',
        description: '',
        defaultMessage: 'Influence product rankings with attributes.',
    },
    RANKING_BIAS_EDIT_DESCRIPTION: {
        id: 'ranking.bias_edit_description',
        description: '',
        defaultMessage: 'Choose a pre-existing biasing profile from a list or create a new one.',
    },
    RANKING_BOOST_PRODUCT: {
        id: 'ranking.boost',
        description: '',
        defaultMessage: 'Boost Products',
    },
    RANKING_BOOST_DESCRIPTION: {
        id: 'ranking.boost_description',
        description: '',
        defaultMessage: 'Increase product placement by applying a multiplier to product IDs.',
    },
    RANKING_BOOST_EDIT_DESCRIPTION: {
        id: 'ranking.boost_edit_description',
        description: '',
        defaultMessage: 'Products are boosted based on their tier as shown below (8 tiers). Product IDs in the first tier will receive the highest boost. To add a product ID, you can either search the product ID in the tier or drag a product from the preview panel into the tier. You can add 20 product IDs per tier.',
    },
    RANKING_BURY_PRODUCT: {
        id: 'ranking.bury',
        description: '',
        defaultMessage: 'Bury Products',
    },
    RANKING_BURY_DESCRIPTION: {
        id: 'ranking.bury_description',
        description: '',
        defaultMessage: 'Decrease product placement by applying a negative multiplier to product IDs.',
    },
    RANKING_BURY_EDIT_DESCRIPTION: {
        id: 'ranking.bury_edit_description',
        description: '',
        defaultMessage: 'To add a product ID, you can either search the product ID or drag a product from the preview panel into the input field below.',
    },
    RANKING_BOOSTED_SEE_ALL_TIERS: {
        id: 'ranking.boosted_see_all_tiers',
        description: '',
        defaultMessage: 'See all tiers',
    },
    RANKING_BOOSTED_PRODUCTS_ON_OTHER_TIERS: {
        id: 'ranking.boosted_products_on_other_tiers',
        description: '',
        defaultMessage: '... {count} products in other tiers',
    },
    RULE_PINNED_NAVIGATIONS_DELETE_WARNING: {
        id: 'rule.pinned_navigations.delete_warning',
        description: '',
        defaultMessage: 'You are about to delete a pinned navigation.',
    },
    RULE_PINNED_NAVIGATIONS_DELETE_PROMPT: {
        id: 'rule.pinned_navigations.delete_prompt',
        description: '',
        defaultMessage: 'Are you sure you want to remove the pinned navigation? This action will remove all refinements in the navigation as well. This action cannot be reversed.',
    },
    FACET_NAV_NO_MATCHES: {
        id: 'facet_nav.no_matches',
        description: '',
        defaultMessage: 'No matching results. Try again.',
    },
    FACET_NAV_BROWSE_REQUIRED: {
        id: 'facet_nav.browse.required',
        description: '',
        defaultMessage: 'Navigations are required to use browse search.',
    },
    PERMALINK: {
        id: 'permalink',
        description: '',
        defaultMessage: 'Permalink',
    },
    PERMALINK_TOOLTIP: {
        id: 'permalink_tooltip',
        description: '',
        defaultMessage: 'Copy permalink to share current page state with other users. This does not include visitor ID',
    },
    COPY_URL: {
        id: 'copy_url',
        description: '',
        defaultMessage: 'Copy URL',
    },
    BETA: {
        id: 'beta',
        description: '',
        defaultMessage: 'Beta',
    },
    SERVING_CONFIG_NAME: {
        id: 'serving_config_name',
        description: '',
        defaultMessage: 'Serving Config Name',
    },
    PINNED_NAVIGATION: {
        id: 'pinned_navigation',
        description: '',
        defaultMessage: 'This is pinned navigation',
    },
    PINNED_REFINEMENT: {
        id: 'pinned_refinement',
        description: '',
        defaultMessage: 'This is pinned refinement',
    },
    MANAGE_PRODUCTS: {
        id: 'nav.category.manage_products',
        description: '',
        defaultMessage: 'Manage Products',
    },
    PRODUCT_EDITOR: {
        id: 'nav.product_editor',
        description: '',
        defaultMessage: 'Product Editor',
    },
    VIEWER: {
        id: 'nav.viewer',
        description: '',
        defaultMessage: 'Viewer',
    },
    ENRICH_AI: {
        id: 'nav.enrich_ai',
        description: '',
        defaultMessage: 'Enrich AI',
    },
    ENRICH: {
        id: 'nav.enrich',
        defaultMessage: 'Enrich',
        description: '',
    },
    DRAWER_CATEGORY_BUILD_CAMPAIGNS: {
        id: 'drawer.category.build_campaigns',
        description: '',
        defaultMessage: 'Build Campaigns',
    },
    DRAWER_CATEGORY_MANAGE_PRODUCT_CATALOG: {
        id: 'drawer.category.manage_product_catalog',
        description: '',
        defaultMessage: 'Manage Product Catalog',
    },
    DRAWER_CATEGORY_TUNE_THE_ENGINE: {
        id: 'drawer.category.tune_the_engine',
        description: '',
        defaultMessage: 'Tune the Engine',
    },
    DRAWER_CATEGORY_CREATE_CONTENT: {
        id: 'drawer.category.create_content',
        description: '',
        defaultMessage: 'Create Content',
    },
    DRAWER_CATEGORY_CONFIGURE_PLATFORM: {
        id: 'drawer.category.configure_platform',
        description: '',
        defaultMessage: 'Configure Platform',
    },
    DRAWER_CATEGORY_CHECK_SITE_HEALTH: {
        id: 'drawer.category.check_site_health',
        description: '',
        defaultMessage: 'Check Site Health',
    },
    DRAWER_CATEGORY_VIEW_DASHBOARDS: {
        id: 'drawer.category.view_dashboards',
        description: '',
        defaultMessage: 'View Dashboards',
    },
    DRAWER_CATEGORY_ENHANCE_PERFORMANCE: {
        id: 'drawer.category.enhance_performance',
        description: '',
        defaultMessage: 'Enhance Performance',
    },
    DRAWER_CATEGORY_TEST_IDEAS: {
        id: 'drawer.category.test_ideas',
        description: '',
        defaultMessage: 'Test Ideas',
    },
    DRAWER_CATEGORY_RECEIVE_USAGE_REPORTS: {
        id: 'drawer.category.receive usage reports',
        description: '',
        defaultMessage: 'Receive Usage Reports',
    },
    DRAWER_CATEGORY_VIEW_DASHBOARD: {
        id: 'drawer.category.view_dashboard',
        description: '',
        defaultMessage: 'View Dashboard',
    },
    DRAWER_CATEGORY_BROWSE_LIBRARIES: {
        id: 'drawer.category.browse_libraries',
        description: '',
        defaultMessage: 'Browse Libraries',
    },
    DRAWER_CATEGORY_MANAGE_PRODUCTS: {
        id: 'drawer.category.manage_products',
        description: '',
        defaultMessage: 'Manage Products',
    },
    DRAWER_CATEGORY_SETUP_STRATEGY: {
        id: 'drawer.category.setup_strategy',
        description: '',
        defaultMessage: 'Setup Strategy',
    },
    DRAWER_CATEGORY_MANAGE_CURATION: {
        id: 'drawer.category.manage_curation',
        description: '',
        defaultMessage: 'Manage Curation',
    },
    DRAWER_CATEGORY_MANAGE_PEOPLE: {
        id: 'drawer.category.manage_people',
        description: '',
        defaultMessage: 'Manage People',
    },
    DRAWER_CATEGORY_APPS_AND_INTEGRATIONS: {
        id: 'drawer.category.apps_and_integrations',
        description: '',
        defaultMessage: 'Apps and Integrations',
    },
    DRAWER_CATEGORY_MANAGE_UPDATES: {
        id: 'drawer.category.manage_updates',
        description: 'Manage Updates',
        defaultMessage: 'Manage Updates',
    },
    DRAWER_ITEM_MODEL_MANAGEMENT: {
        id: 'drawer.item.model_management',
        description: '',
        defaultMessage: 'Model Management',
    },
    DO_NOT_ASSOCIATE_TITLE_EDIT: {
        id: 'do_not_associate.title.edit',
        description: '',
        defaultMessage: 'Edit Do Not Associate Term',
    },
    DO_NOT_ASSOCIATE_TITLE_CREATE: {
        id: 'do_not_associate.title.create',
        description: '',
        defaultMessage: 'Create Do Not Associate Term',
    },
    DO_NOT_ASSOCIATE_DESCRIPTION: {
        id: 'do_not_associate.description',
        description: '',
        defaultMessage: 'Enables you to prevent a query from being associated with specific terms during search. <i>Example: Do not associate "Rolex" and "Cheap".</i>',
    },
    ONE_WAY_SYNONYM_TITLE_EDIT: {
        id: 'one_way_synonym.title.edit',
        description: '',
        defaultMessage: 'Edit One Way Synonym Term',
    },
    ONE_WAY_SYNONYM_TITLE_CREATE: {
        id: 'one_way_synonym.title.create',
        description: '',
        defaultMessage: 'Create One Way Synonym Term',
    },
    ONE_WAY_SYNONYM_DESCRIPTION: {
        id: 'one_way_synonym.description',
        description: '',
        defaultMessage: 'Expands a search to include additional terms but not the other way around. <i>Example: If the term "Jackets" is searched, products related to "Blazers" can be found in the results, but if the term "Blazers" is searched, products related to "Jackets" will not be found.</i>',
    },
    TWO_WAY_SYNONYM_TITLE_EDIT: {
        id: 'two_way_synonym.title.edit',
        description: '',
        defaultMessage: 'Edit Two Way Synonym Term',
    },
    TWO_WAY_SYNONYM_TITLE_CREATE: {
        id: 'two_way_synonym.title.create',
        description: '',
        defaultMessage: 'Create Two Way Synonym Term',
    },
    TWO_WAY_SYNONYM_DESCRIPTION: {
        id: 'two_way_synonym.description',
        description: '',
        defaultMessage: 'Associates search terms with their product sets both ways. <i>Example: If either term "Jackets" or "Blazers" is searched, products related to both terms can be found in the results.</i>',
    },
    IGNORE_TITLE_EDIT: {
        id: 'ignore.title.edit',
        description: '',
        defaultMessage: 'Edit Ignore Action Term',
    },
    IGNORE_TITLE_CREATE: {
        id: 'ignore.title.create',
        description: '',
        defaultMessage: 'Create Ignore Action Term',
    },
    IGNORE_DESCRIPTION: {
        id: 'ignore.description',
        description: '',
        defaultMessage: 'Ignore terms from the query, unless a term is in double quotes. <i>Example: Create a control that ignores query terms that use offensive language.</i>',
    },
    REPLACEMENT_TITLE_EDIT: {
        id: 'replacement.title.edit',
        description: '',
        defaultMessage: 'Edit Replacement Term',
    },
    REPLACEMENT_TITLE_CREATE: {
        id: 'replacement.title.create',
        description: '',
        defaultMessage: 'Create Replacement Term',
    },
    REPLACEMENT_DESCRIPTION: {
        id: 'replacement.description',
        description: '',
        defaultMessage: 'Replace a term with one or more terms, but not the other way around. <i>Example: If the term "Jackets" is replaced with "Blazers", products related to "Blazers" will be found in the results instead of "Jackets". If "Blazers" is searched, "Jackets" will not show up in the results.</i>',
    },
    ROLEX: {
        id: 'rolex.bling',
        description: '',
        defaultMessage: 'Ex. Rolex',
    },
    CHEAP: {
        id: 'cheap',
        description: '',
        defaultMessage: 'Ex. Cheap',
    },
    TWO_WAY_SYNONYMS_SYNONYM_HELPER_TEXT: {
        id: 'two_way_synonyms.synonym.helper_text',
        description: '',
        defaultMessage: 'Ex. Dish Towel, Kitchen Towel',
    },
    MAC_HELPER_TEXT: {
        id: 'mac.helper_text',
        description: '',
        defaultMessage: 'Ex. Mac',
    },
    MACBOOK_HELPER_TEXT: {
        id: 'macbook.helper_text',
        description: '',
        defaultMessage: 'Ex. Macbook',
    },
    JACKETS_HELPER_TEXT: {
        id: 'jackets.helper_text',
        description: '',
        defaultMessage: 'Ex. jackets',
    },
    TASK_PRODUCT_ANALYTICS_LOAD_ERROR: {
        id: 'task.product_analytics.load_error',
        description: '',
        defaultMessage: 'Failed to load product analytics',
    },
    PRODUCT_ANALYTICS_SHOW_ANALYTICS: {
        id: 'product_analytics.show_analytics',
        description: '',
        defaultMessage: 'Show Analytics',
    },
    PRODUCT_ANALYTICS_SHOW_ANALYTICS_TOOLTIP: {
        id: 'product_analytics.show_analytics.tooltip',
        description: '',
        defaultMessage: 'Comparing the last 30 days',
    },
    PRODUCT_ANALYTICS_CTR_TITLE: {
        id: 'product_analytics.ctr.title',
        description: '',
        defaultMessage: 'CTR',
    },
    PRODUCT_ANALYTICS_CTR_TOOLTIP: {
        id: 'product_analytics.ctr.tooltip',
        description: '',
        defaultMessage: 'Click through rate',
    },
    PRODUCT_ANALYTICS_ATC_TITLE: {
        id: 'product_analytics.atc.title',
        description: '',
        defaultMessage: 'ATC Rate',
    },
    PRODUCT_ANALYTICS_ATC_TITLE_SHORT: {
        id: 'product_analytics.atc.title.short',
        description: '',
        defaultMessage: 'ATC',
    },
    PRODUCT_ANALYTICS_ATC_TOOLTIP: {
        id: 'product_analytics.atc.tooltip',
        description: '',
        defaultMessage: 'Add to cart rate',
    },
    PRODUCT_ANALYTICS_CONVERSION_TITLE: {
        id: 'product_analytics.conversion.title',
        description: '',
        defaultMessage: 'Conv. Rate',
    },
    PRODUCT_ANALYTICS_CONVERSION_TITLE_SHORT: {
        id: 'product_analytics.conversion.title.short',
        description: '',
        defaultMessage: 'CONV',
    },
    PRODUCT_ANALYTICS_CONVERSION_TOOLTIP: {
        id: 'product_analytics.conversion.tooltip',
        description: '',
        defaultMessage: 'Conversion rate',
    },
    PRODUCT_ANALYTICS_REVENUE_TITLE: {
        id: 'product_analytics.revenue.title',
        description: '',
        defaultMessage: 'Rev ($)',
    },
    PRODUCT_ANALYTICS_REVENUE_TOOLTIP: {
        id: 'product_analytics.revenue.tooltip',
        description: '',
        defaultMessage: 'Revenue',
    },
    AUTOCOMPLETE_CONTROLS: {
        id: 'autocomplete_controls',
        description: '',
        defaultMessage: 'Autocomplete Controls',
    },
    AUTOCOMPLETE: {
        id: 'autocomplete',
        description: '',
        defaultMessage: 'Autocomplete',
    },
    AUTOCOMPLETE_EDIT_DESCRIPTION: {
        id: 'autocomplete.edit.description',
        description: '',
        defaultMessage: 'Autocomplete anticipates and provides query suggestions as shoppers type. Fine-tune the settings in this section to tailor the experience to shoppers’ preferences.',
    },
    AUTOCOMPLETE_DESCRIPTION: {
        id: 'autocomplete.description',
        description: '',
        defaultMessage: 'Anticipate and provide query suggestions as shoppers type. Fine-tune matching order, trigger-length and deny lists to expedite product findability..',
    },
    GLOBAL_SETTINGS: {
        id: 'global_settings',
        description: '',
        defaultMessage: 'Global Settings',
    },
    AUTOCOMPLETE_COLLECTION_DESCRIPTION: {
        id: 'autocomplete.collection.description',
        description: '',
        defaultMessage: 'Configure your auto-complete settings per collection.',
    },
    AUTOCOMPLETE_GLOBAL_SETTINGS_DESCRIPTION: {
        id: 'autocomplete.global_settings.description',
        description: '',
        defaultMessage: 'Set up the method for generating and initiating the autocomplete functionality.',
    },
    AUTOCOMPLETE_MINIMUM_TRIGGER_LENGTH: {
        id: 'autocomplete.minimum_trigger_length',
        description: '',
        defaultMessage: 'Minimum Trigger Length',
    },
    AUTOCOMPLETE_MINIMUM_TRIGGER_LENGTH_HELPER: {
        id: 'autocomplete.minimum_trigger_length.helper',
        description: '',
        defaultMessage: 'The number of characters required before autocompletion queries will return results.  GroupBy recommends 1-3 characters.',
    },
    AUTOCOMPLETE_MATCHING_STARTS: {
        id: 'autocomplete.matching.starts',
        description: '',
        defaultMessage: 'Suggestion starts with the term',
    },
    AUTOCOMPLETE_MATCHING_ANYWHERE: {
        id: 'autocomplete.matching.anywhere',
        description: '',
        defaultMessage: 'Suggestion can start from anywhere in the term',
    },
    AUTOCOMPLETE_MATCHING_ORDER: {
        id: 'autocomplete.matching_order',
        description: '',
        defaultMessage: 'Matching Order',
    },
    TASK_AUTOCOMPLETE_CONFIG_UPDATE_SUCCESS: {
        id: 'task.autocomplete.update.success',
        description: '',
        defaultMessage: 'Task "Update autocomplete controls" completed!',
    },
    TASK_AUTOCOMPLETE_CONFIG_UPDATE_FAIL: {
        id: 'task.autocomplete.update.fail',
        description: '',
        defaultMessage: 'Task "Update autocomplete controls" failed!',
    },
    TASK_AUTOCOMPLETE_LOAD_ONE_ERROR: {
        id: 'task.autocomplete.load.one',
        description: '',
        defaultMessage: 'Task "Load autocomplete configurations" failed!',
    },
    TASK_AUTOCOMPLETE_DENY_LIST_UPLOAD_ERROR: {
        id: 'task.autocomplete.deny_list.upload.error',
        description: '',
        defaultMessage: 'Task "Upload Deny List" failed!',
    },
    TASK_AUTOCOMPLETE_DENY_LIST_LOAD_ERROR: {
        id: 'task.autocomplete.deny_list.load.error',
        description: '',
        defaultMessage: 'Task "Load deny list job status" failed!',
    },
    DISABLED: {
        id: 'disabled',
        description: '',
        defaultMessage: 'Disabled',
    },
    TERM_LIST: {
        id: 'term_list',
        description: '',
        defaultMessage: 'Term list',
    },
    AUTOCOMPLETE_TERM_LIST_DESCRIPTION: {
        id: 'autocomplete.term_list.description',
        description: '',
        defaultMessage: 'Upload your terms and configure your auto complete settings.',
    },
    DENY_LIST: {
        id: 'deny_list',
        description: '',
        defaultMessage: 'Deny list',
    },
    AUTOCOMPLETE_DENY_LIST_DESCRIPTION: {
        id: 'autocomplete.deny_list.description',
        description: '',
        defaultMessage: 'Any queries listed here will not be shown in autocomplete, regardless of their customer usage. It might take additional <b>1~2 days</b> for data to be indexed and be used in production serving.',
    },
    AUTOCOMPLETE_UPLOAD_FILE: {
        id: 'autocomplete.upload_file',
        description: '',
        defaultMessage: 'Upload a CSV file',
    },
    RECS_MANAGEMENT: {
        id: 'recs_management',
        description: '',
        defaultMessage: 'Model Management',
    },
    RECS_MANAGEMENT_DESCRIPTION: {
        id: 'recs_management.description',
        description: '',
        defaultMessage: 'Create, oversee, and fine-tune your recommendation models to cater to your specific use cases. Placement and Filters can be adjusted once the model is trained.',
    },
    RECS_MODELS: {
        id: 'recs_model',
        description: '',
        defaultMessage: 'Models',
    },
    RECS_MODEL_CREATE: {
        id: 'recs_model.create',
        description: '',
        defaultMessage: 'Create a Model',
    },
    RECS_MODEL_CREATE_DESCRIPTION: {
        id: 'recs_model.create.description',
        description: '',
        defaultMessage: '<b>There are costs associated with training a recommendation model, please contact a GroupBy representative for details.</b> Select which of the available recommendations models you would like to train, and optimize their performance for your business goals.',
    },
    RECS_MODEL_DELETE: {
        id: 'recs_model.delete',
        description: '',
        defaultMessage: 'Delete Model',
    },
    RECS_MODEL_NEW: {
        id: 'recs_model.new',
        description: '',
        defaultMessage: 'Create Model',
    },
    RECS_MODEL_EDIT: {
        id: 'recs_model.edit',
        description: '',
        defaultMessage: 'Edit Model',
    },
    RECS_MODEL_VIEW: {
        id: 'recs_model.view',
        description: '',
        defaultMessage: 'View Model',
    },
    TASK_RECS_MODEL_CREATE_ERROR: {
        id: 'task.recs_model.create.error',
        description: '',
        defaultMessage: 'Task "Create recs model" failed',
    },
    TASK_RECS_MODEL_CREATE_SUCCESS: {
        id: 'task.recs_model.create.success',
        description: '',
        defaultMessage: 'Task "Create recs model" completed!',
    },
    TASK_RECS_MODEL_REMOVE_ERROR: {
        id: 'task.recs_model.remove.error',
        description: '',
        defaultMessage: 'Task "Remove recs model" failed',
    },
    TASK_RECS_MODEL_REMOVE_SUCCESS: {
        id: 'task.recs_model.remove.success',
        description: '',
        defaultMessage: 'Task "Remove recs model {modelName}" completed!',
    },
    TASK_RECS_MODEL_LOAD_ALL_ERROR: {
        id: 'task.recs_model.load_all.error',
        description: '',
        defaultMessage: 'Task "Load recs model" failed',
    },
    TASK_RECS_MODEL_LOAD_ONE_ERROR: {
        id: 'task.recs_model.loadone.error',
        description: '',
        defaultMessage: 'Task "Load recs model" failed',
    },
    TASK_RECS_MODEL_UPDATE_SUCCESS: {
        id: 'task.recs_model.update.success',
        description: '',
        defaultMessage: 'Task "Update recs model" completed!',
    },
    TASK_RECS_MODEL_UPDATE_ERROR: {
        id: 'task.recs_model.update.error',
        description: '',
        defaultMessage: 'Task "Update recs model" failed',
    },
    RECS_MODEL_COLUMN_MODEL_ID: {
        id: 'recs_model.column.model_id',
        description: '',
        defaultMessage: 'Model ID',
    },
    RECS_MODEL_COLUMN_MODEL_NAME: {
        id: 'recs_model.column.model_name',
        description: '',
        defaultMessage: 'Model Name',
    },
    RECS_MODEL_COLUMN_MODEL_TYPE: {
        id: 'recs_model.column.model_type',
        description: '',
        defaultMessage: 'Model Type',
    },
    RECS_MODEL_COLUMN_READY_TO_QUERY: {
        id: 'recs_model.column.ready_to_query',
        description: '',
        defaultMessage: 'Ready to Query',
    },
    RECS_MODEL_COLUMN_READY_TO_QUERY_TOOLTIP: {
        id: 'recs_model.column.ready_to_query_tooltip',
        description: '',
        defaultMessage: 'Whether a version of the recommendation model is served and be queried. To query a model, create a placement associated with the recommendation ID and call the recommendation API.',
    },
    RECS_MODEL_COLUMN_DATA_STATE: {
        id: 'recs_model.column.data_state',
        description: '',
        defaultMessage: 'Data State',
    },
    RECS_MODEL_COLUMN_DATA_STATE_TOOLTIP: {
        id: 'recs_model.column.data_state_tooltip',
        description: '',
        defaultMessage: 'Whether the data meets training requirement.',
    },
    RECS_MODEL_COLUMN_BUSINESS_OBJECTIVE: {
        id: 'recs_model.column.business_objective',
        description: '',
        defaultMessage: 'Business objective',
    },
    RECS_MODEL_COLUMN_CREATED_DATE: {
        id: 'recs_model.column.created_date',
        description: '',
        defaultMessage: 'Created Date',
    },
    RECS_MODEL_NO_DATA: {
        id: 'recs_model.no_data',
        description: '',
        defaultMessage: 'There are no data models at the moment. Create a model.',
    },
    RECS_MODEL_NO_MATCHING: {
        id: 'recs_model.no_matching',
        description: '',
        defaultMessage: 'There are no matching models.',
    },
    SEARCH_BROWSE_PATH: {
        id: 'rule_preview.browse_path_search.placeholder',
        description: '',
        defaultMessage: 'Search browse path',
    },
    SEARCH_RULE_PREVIEW_BROWSE: {
        id: 'rule_preview.browse_search.placeholder',
        description: '',
        defaultMessage: 'Search category name and select a browse path',
    },
    BROWSE: {
        id: 'browse',
        description: '',
        defaultMessage: 'Browse',
    },
    RULE_PREVIEW_QUERY: {
        id: 'rule_preview.query',
        description: '',
        defaultMessage: 'Search',
    },
    TASK_BROWSE_CATEGORIES_LOAD_ERROR: {
        id: 'task.browse_categories.load.error',
        description: '',
        defaultMessage: 'Task "Load browse categories" failed',
    },
    NAVIGATION_AUTOCOMPLETE_SEARCH_INPUT_LOAD_ERROR: {
        id: 'navigation_autocomplete_search_input.load.error',
        description: '',
        defaultMessage: 'Navigation autocomplete failed',
    },
    COLLECTION_NAME: {
        id: 'collection_name',
        description: '',
        defaultMessage: 'Collection Name',
    },
    COLLECTION_NAME_NOT_AVAILABLE: {
        id: 'collection_name_not_available',
        description: '',
        defaultMessage: 'Collection Name Not Available',
    },
    TASK_AUTOCOMPLETE_LOAD_ALL_ERROR: {
        id: 'task.autocomplete.load_all.error',
        description: '',
        defaultMessage: 'Task "Load autocomplete controls" failed',
    },
    AUTOCOMPLETE_DENY_LIST_CSV_EXAMPLE: {
        id: 'autocomplete.deny_list.csv_example',
        description: '',
        defaultMessage: 'Csv Schema Example',
    },
    AUTOCOMPLETE_AUTO_LEARNING: {
        id: 'autocomplete.auto_learning',
        description: '',
        defaultMessage: 'Auto Learning',
    },
    AUTOCOMPLETE_AUTO_LEARNING_DESCRIPTION: {
        id: 'autocomplete.auto_learning.description',
        description: '',
        defaultMessage: 'Auto-learning generates suggestions from the past 180 days of user events. The auto-learning dataset is generated daily, then pushed to indexing and release. The full cycle takes around two days.',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_IN_PROGRESS: {
        id: 'autocomplete.deny_list.upload.in_progress',
        description: '',
        defaultMessage: 'Uploading in progress. It could take up to an hour. You may still save and exit this page.',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_SUCCESS: {
        id: 'autocomplete.deny_list.upload.suceess',
        description: '',
        defaultMessage: 'Upload successful.',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_SUCCESS_PENDING: {
        id: 'autocomplete.deny_list.upload.suceess.pending',
        description: '',
        defaultMessage: 'Upload successful. Changes will take an additional 1~2 days to take into effect.',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_MOST_RECENT: {
        id: 'autocomplete.deny_list.upload.most_recent',
        description: '',
        defaultMessage: '<b>Most recent upload</b>: {date}',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_LAST_CHECKED: {
        id: 'autocomplete.deny_list.upload.last_checked',
        description: '',
        defaultMessage: 'Status last checked on {date}',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_FAILED: {
        id: 'autocomplete.deny_list.upload.failed',
        description: '',
        defaultMessage: 'Upload failed. {reason}',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_CANCELLED: {
        id: 'autocomplete.deny_list.upload.cancelled',
        description: '',
        defaultMessage: 'Upload cancelled.',
    },
    AUTOCOMPLETE_DENY_LIST_UPLOAD_LAST_SUCCESS: {
        id: 'autocomplete.deny_list.upload.last_success',
        description: '',
        defaultMessage: 'Last successful upload: {date}',
    },
    REPLACE: {
        id: 'replace',
        description: '',
        defaultMessage: 'Replace',
    },
    PREVIEW_PANEL_SETUP_BROWSE_TRIGGERS: {
        id: 'preview_panel.browse.triggers.set_up',
        description: '',
        defaultMessage: "Please set up the Trigger Sets for browse to see the results. There must be at least one 'navigation value' trigger included in the trigger sets.",
    },
    CATEGORIES: {
        id: 'categories',
        description: '',
        defaultMessage: 'Categories',
    },
    MEGA_MENUS: {
        id: 'categories.mega_menus',
        description: '',
        defaultMessage: 'Megamenus',
    },
    RECS_MODEL_STEP_NAME: {
        id: 'recs_model.step.name',
        description: '',
        defaultMessage: 'Name',
    },
    RECS_MODEL_STEP_NAME_TITLE: {
        id: 'recs_model.step.name.title',
        description: '',
        defaultMessage: 'Name the model',
    },
    RECS_MODEL_STEP_NAME_SUBTITLE: {
        id: 'recs_model.step.name.subtitle',
        description: '',
        defaultMessage: 'Choose a memorable name that will appear in the model dropdown menus.',
    },
    RECS_MODEL_STEP_MODEL_TYPE: {
        id: 'recs_model.step.model_type',
        description: '',
        defaultMessage: 'Model Type',
    },
    RECS_MODEL_STEP_MODEL_TYPE_TITLE: {
        id: 'recs_model.step.model_type.title',
        description: '',
        defaultMessage: 'Choose a model type',
    },
    RECS_MODEL_STEP_MODEL_TYPE_SUBTITLE: {
        id: 'recs_model.step.model_type.subtitle',
        description: '',
        defaultMessage: 'Choose the recommendation type that best captures your business goal. <b>To guarantee the model is available for training, please validate with your GroupBy representative.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_AVAILABLE_MODELS: {
        id: 'recs_model.step.model_type.available_models',
        description: '',
        defaultMessage: 'Available models',
    },
    RECS_MODEL_STEP_MODEL_TYPE_RECOMMENDED_FOR_YOU: {
        id: 'recs_model.step.model_type.recommended_for_you',
        description: '',
        defaultMessage: 'Recommended for you',
    },
    RECS_MODEL_STEP_MODEL_TYPE_RECOMMENDED_FOR_YOU_DESCRIPTION: {
        id: 'recs_model.step.model_type.recommended_for_you_description',
        description: '',
        defaultMessage: 'Predicts the next product that a user will most likely engage with or purchase based on the shopping or viewing history of the specified user Id or visitor Id. <b>Typically used on home page.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_FREQUENTLY_BOUGHT_TOGETHER: {
        id: 'recs_model.step.model_type.frequently_bought_together',
        description: '',
        defaultMessage: 'Frequently bought together',
    },
    RECS_MODEL_STEP_MODEL_TYPE_FREQUENTLY_BOUGHT_TOGETHER_DESCRIPTION: {
        id: 'recs_model.step.model_type.frequently_bought_together_description',
        description: '',
        defaultMessage: 'Predicts items frequently bought together with one or more catalog items in the same shopping session. <b>Commonly displayed after an add-to-cart event, on product detail pages, or on the shopping cart page.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_BUY_IT_AGAIN: {
        id: 'recs_model.step.model_type.buy_it_again',
        description: '',
        defaultMessage: 'Buy it again',
    },
    RECS_MODEL_STEP_MODEL_TYPE_BUY_IT_AGAIN_DESCRIPTION: {
        id: 'recs_model.step.model_type.buy_it_again_description',
        description: '',
        defaultMessage: 'Predicts items for a user to buy again based on their purchase history. <b>Typically used on detail page view, add to cart, shopping cart, category page view, and home page view.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_OTHERS_YOU_MAY_LIKE: {
        id: 'recs_model.step.model_type.others_you_may_like',
        description: '',
        defaultMessage: 'Others you may like',
    },
    RECS_MODEL_STEP_MODEL_TYPE_OTHERS_YOU_MAY_LIKE_DESCRIPTION: {
        id: 'recs_model.step.model_type.others_you_may_like_description',
        description: '',
        defaultMessage: 'Predicts the next item that a user will most likely engage with or purchase. The prediction is based on the shopping or viewing history of the specified userId or visitorId and its relevance to a specified catalog item. <b>Typically used on product detail pages.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_SIMILAR_ITEMS: {
        id: 'recs_model.step.model_type.similar_items',
        description: '',
        defaultMessage: 'Similar Items',
    },
    RECS_MODEL_STEP_MODEL_TYPE_SIMILAR_ITEMS_DESCRIPTION: {
        id: 'recs_model.step.model_type.similar_items_description',
        description: '',
        defaultMessage: 'Predicts other catalog items that have mostly similar attributes to the current item being considered. <b>Typically used on a product detail page or when an item being viewed is out of stock.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_ON_SALE: {
        id: 'recs_model.step.model_type.on_sale',
        description: '',
        defaultMessage: 'On Sale',
    },
    RECS_MODEL_STEP_MODEL_TYPE_ON_SALE_DESCRIPTION: {
        id: 'recs_model.step.model_type.on_sale_description',
        description: '',
        defaultMessage: 'Recommends products that are on sale. <b>Typically used on home page view, add to cart, shopping cart, category page view, and detail page view.</b>',
    },
    RECS_MODEL_STEP_MODEL_TYPE_RECENTLY_VIEWED: {
        id: 'recs_model.step.model_type.recently_viewed',
        description: '',
        defaultMessage: 'Recently Viewed',
    },
    RECS_MODEL_STEP_SIMILAR_ITEMS_FILTERING: {
        id: 'recs_model.step.similar_items.filtering',
        description: '',
        defaultMessage: 'Filtering by Attribute Values',
    },
    RECS_MODEL_STEP_SIMILAR_ITEMS_FILTERING_TITLE: {
        id: 'recs_model.step.similar_items.filtering.title',
        description: '',
        defaultMessage: 'Filtering by attribute values',
    },
    RECS_MODEL_STEP_SIMILAR_ITEMS_FILTERING_SUBTITLE: {
        id: 'recs_model.step.similar_items.filtering.subtitle',
        description: '',
        defaultMessage: 'This will involve auto generating filter tags in the background, which will make filtering possible, but can increase training time and may increase serving latency.',
    },
    RECS_MODEL_STEP_SIMILAR_ITEMS_FILTERING_AUTOGENERATE_TAG: {
        id: 'recs_model.step.similar_items.filtering.autogenerate_tag',
        description: '',
        defaultMessage: 'Auto generate tags',
    },
    RECS_MODEL_STEP_SIMILAR_ITEMS_FILTERING_DO_NOT_AUTOGENERATE_TAG: {
        id: 'recs_model.step.similar_items.filtering.do_not_autogenerate_tag',
        description: '',
        defaultMessage: 'Do not generate tags',
    },
    RECS_MODEL_STEP_SUMMARY: {
        id: 'recs_model.step.summary',
        description: '',
        defaultMessage: 'Finish and Create',
    },
    RECS_MODEL_STEP_SUMMARY_TITLE: {
        id: 'recs_model.step.summary.title',
        description: '',
        defaultMessage: 'Summary',
    },
    RECS_MODEL_STEP_SUMMARY_SUBTITLE: {
        id: 'recs_model.step.summary.subtitle',
        description: '',
        defaultMessage: 'If you have uploaded sufficient user event data of the required type, the initial model training and tuning begins. Initial model training and tuning takes 2-5 days to complete, but can take longer for large datasets.',
    },
    RECS_MODEL_STEP_SUMMARY_MODEL_TYPE: {
        id: 'recs_model.step.summary.model_type',
        description: '',
        defaultMessage: 'Model Type',
    },
    RECS_MODEL_STEP_FREQUENTLY_BOUGHT_TOGETHER_BUSINESS_OBJECTIVE: {
        id: 'recs_model.step.frequently_bought_together.business_objective',
        description: '',
        defaultMessage: 'Business Objective',
    },
    RECS_MODEL_STEP_FREQUENTLY_BOUGHT_TOGETHER_BUSINESS_OBJECTIVE_DESCRIPTION: {
        id: 'recs_model.step.frequently_bought_together.business_objective_description',
        description: '',
        defaultMessage: 'Machine learning models are created to optimize for a particular business objective, which determines how the model is built. Each model has a default optimization objective, but you can request a different optimization objective to support your business goals by contacting your support representative. After you have trained a model, you <b>cannot change</b> the optimization objective. You must train a new model to use a different optimization objective.',
    },
    RECS_MODEL_STEP_FREQUENTLY_BOUGHT_TOGETHER_REVENUE_PER_SESSION: {
        id: 'recs_model.step.frequently_bought_together.revenue_per_session',
        description: '',
        defaultMessage: 'Revenue per session',
    },
    RECS_MODEL_STEP_FREQUENTLY_BOUGHT_TOGETHER_REVENUE_PER_SESSION_DESCRIPTION: {
        id: 'recs_model.step.frequently_bought_together.revenue_per_session_description',
        description: '',
        defaultMessage: 'Optimizes for maximizing the revenue generated by a recommendation. To optimize for revenue, make sure as many catalog items as possible that were uploaded in the last 90 days included price info at upload time.',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_CLICK_THROUGH_RATE: {
        id: 'recs_model.step.others_you_may_like.click_through_rate',
        description: '',
        defaultMessage: 'Click-through rate (CTR)',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_CLICK_THROUGH_RATE_DESCRIPTION: {
        id: 'recs_model.step.others_you_may_like.click_through_rate_description',
        description: '',
        defaultMessage: 'Optimizes for increasing user engagement with a recommendation. CTR is calculated by the number of product detail views from a recommendation divided by the total number of impressions for the placement type.',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_CONVERSION_RATE: {
        id: 'recs_model.step.others_you_may_like.conversion_rate',
        description: '',
        defaultMessage: 'Conversion rate (CVR)',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_CONVERSION_RATE_DESCRIPTION: {
        id: 'recs_model.step.others_you_may_like.conversion_rate_description',
        description: '',
        defaultMessage: 'Optimizes for increasing the likelihood that a user adds recommended items to their cart. CVR is calculated by the number of add-to-cart events from a recommendation panel divided by the total number of impressions for the placement type.',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_REVENUE_PER_SESSION: {
        id: 'recs_model.step.others_you_may_like.revenue_per_session',
        description: '',
        defaultMessage: 'Revenue per session',
    },
    RECS_MODEL_STEP_OTHERS_YOU_MAY_LIKE_REVENUE_PER_SESSION_DESCRIPTION: {
        id: 'recs_model.step.others_you_may_like.revenue_per_session_description',
        description: '',
        defaultMessage: 'Optimizes for maximizing the revenue generated by a recommendation. To optimize for revenue, make sure as many catalog items as possible that were uploaded in the last 90 days included price info at upload time.',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE: {
        id: 'recs_model.business_objective',
        description: '',
        defaultMessage: 'Business Objective',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_DESCRIPTION: {
        id: 'recs_model.business_objective_description',
        description: '',
        defaultMessage: 'Machine learning models are created to optimize for a particular business objective, which determines how the model is built. Each model has a default optimization objective, but you can request a different optimization objective to support your business goals by contacting your support representative. After you have trained a model, you <b>cannot change</b> the optimization objective. You must train a new model to use a different optimization objective.',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_CLICK_THROUGH_RATE: {
        id: 'recs_model.business_objective.click_through_rate',
        description: '',
        defaultMessage: 'Click-through rate (CTR)',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_CLICK_THROUGH_RATE_DESCRIPTION: {
        id: 'recs_model.business_objective.click_through_rate_description',
        description: '',
        defaultMessage: 'Optimizes for increasing user engagement with a recommendation. CTR is calculated by the number of product detail views from a recommendation divided by the total number of impressions for the placement type.',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_CONVERSION_RATE: {
        id: 'recs_model.business_objective.conversion_rate',
        description: '',
        defaultMessage: 'Conversion rate (CVR)',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_CONVERSION_RATE_DESCRIPTION: {
        id: 'recs_model.business_objective.conversion_rate_description',
        description: '',
        defaultMessage: 'Optimizes for increasing the likelihood that a user adds recommended items to their cart. CVR is calculated by the number of add-to-cart events from a recommendation panel divided by the total number of impressions for the placement type.',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_REVENUE_PER_SESSION: {
        id: 'recs_model.business_objective.revenue_per_session',
        description: '',
        defaultMessage: 'Revenue per session',
    },
    RECS_MODEL_BUSINESS_OBJECTIVE_REVENUE_PER_SESSION_DESCRIPTION: {
        id: 'recs_model.business_objective.revenue_per_session_description',
        description: '',
        defaultMessage: 'Optimizes for maximizing the revenue generated by a recommendation. To optimize for revenue, make sure as many catalog items as possible that were uploaded in the last 90 days included price info at upload time.',
    },
    PRODUCT_TILE_COLLAPSE_ATTRIBUTES: {
        id: 'product_tile.collapse_attributes',
        description: '',
        defaultMessage: 'Collapse {number} attributes',
    },
    PRODUCT_TILE_SHOW_ATTRIBUTES: {
        id: 'product_tile.show_attributes',
        description: '',
        defaultMessage: 'Show {number} attributes',
    },
    PRODUCT_TILE_NO_IMAGE_AVAILABLE: {
        id: 'product_tile.no_image_available',
        description: '',
        defaultMessage: 'No Image Available',
    },
    PRODUCT_TILE_MORE_ATTRIBUTES: {
        id: 'product_tile.more_attriubtes',
        description: '',
        defaultMessage: 'more',
    },
    RECS_MODEL_DATA_REQUIREMENTS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS',
        description: '',
        defaultMessage: 'Data Requirements',
    },
    RECS_MODEL_DATA_REQUIREMENTS_EMPTY: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_EMPTY',
        description: '',
        defaultMessage: 'Data requirements will be shown here',
    },
    RECS_MODEL_DATA_REQUIREMENTS_NOT_SELECTED: {
        id: 'recs_model.requirements_not_selected',
        description: '',
        defaultMessage: 'Select a model to see data requirements',
    },
    RECS_MODEL_DATA_REQUIREMENTS_DAYS_OCCURRENCES: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_DAYS_OCCURRENCES',
        description: '',
        defaultMessage: `At least 7 days of Detail page view events in the last 90 days
      {AND}
      At least 10 occurrences per catalog item on average (with a 90 days window) of Detail page view events
      {OR}
      at least 60 days of Detail page view events in the last 90 days`,
    },
    RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 100 unique catalog items for the Detail page view event in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_FOR_PURCHASE_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_FOR_PURCHASE_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 100 unique catalog items for the Purchase event in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_DETAIL_VIEWS_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_DETAIL_VIEWS_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 10,000 Detail page view events in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_7_DAYS_OF_VIEWS_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_7_DAYS_OF_VIEWS_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 7 days of Home page view events in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_10_OCCURENCES_OR_90_DAYS_PURCHASES: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_10_OCCURENCES_OR_90_DAYS_PURCHASES',
        description: '',
        defaultMessage: `At least 10 occurrences per catalog item on average (with a 1 year window of Purchase events)
    {OR}
    At least 90 days of Purchase events in the last 1 year`,
    },
    RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_PURCHASED_IN_1_YEAR: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_PURCHASED_IN_1_YEAR',
        description: '',
        defaultMessage: 'At least 100 unique catalog items for the Purchase event in the last 1 year',
    },
    RECS_MODEL_DATA_REQUIREMENTS_1000_PURCHASES_IN_1_YEAR: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_1000_PURCHASES_IN_1_YEAR',
        description: '',
        defaultMessage: 'At least 1,000 Purchase events in the last 1 year',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_100_SKUS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_100_SKUS',
        description: '',
        defaultMessage: 'At least 100 product SKUs must be in some branch',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_HOME_VIEWS_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_HOME_VIEWS_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 10,000 Home page view events in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_10_OCCURENCES_OR_90_DAYS_PURCHASES_OR_60_DAYS_PURCHASE: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_10_OCCURENCES_OR_90_DAYS_PURCHASES_OR_60_DAYS_PURCHASE',
        description: '',
        defaultMessage: `At least 10 occurrences per catalog item on average (with a 90 days window of Purchase events)
    {OR}
    At least 60 days of Purchase events in the last 90 days`,
    },
    RECS_MODEL_DATA_REQUIREMENTS_1000_PURCHASES_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_1000_PURCHASES_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 1,000 Purchase events in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_7_DAYS_OF_ADD_TO_CARD_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_7_DAYS_OF_ADD_TO_CARD_IN_90_DAYS',
        description: '',
        defaultMessage: `At least 7 days of Add to cart events in the last 90 days
      {AND}
      at least 10 occurrences per catalog item on average (with a 90 days window) of Add to cart events
      {OR}
      at least 60 days of Add to cart events in the last 90 days`,
    },
    RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_ADD_TO_CARD_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_UNIQUE_100_CATALOG_ITEMS_ADD_TO_CARD_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 100 unique catalog items for the Add to cart event in the last 90 days',
    },
    RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_ADD_TO_CARD_IN_90_DAYS: {
        id: 'RECS_MODEL_DATA_REQUIREMENTS_MINIMUM_10_000_ADD_TO_CARD_IN_90_DAYS',
        description: '',
        defaultMessage: 'At least 10,000 Add to cart events in the last 90 days',
    },
    ADD: {
        id: 'add',
        description: '',
        defaultMessage: 'Add',
    },
    ADDED: {
        id: 'added',
        description: '',
        defaultMessage: 'Added',
    },
    EVALUATE_BROWSE_PATH_HELPER: {
        id: 'evaluate.browse.helper_text',
        description: '',
        defaultMessage: 'Please choose at least one navigation value and search browse path',
    },
    CATEGORY_VALUES: {
        id: 'categories.display_values',
        description: '',
        defaultMessage: 'Category Values',
    },
    CANONICAL_CATEGORY_VALUES: {
        id: 'categories.canonical.values',
        description: '',
        defaultMessage: 'Base Category Values',
    },
    CUSTOM_CATEGORY_VALUES: {
        id: 'categories.custom.values',
        description: '',
        defaultMessage: 'Custom Category Value',
    },
    SELECT_CATEGORY_VALUES: {
        id: 'categories.select.values',
        description: '',
        defaultMessage: 'Select a category to see details.',
    },
    URL_ADDRESS: {
        id: 'url_address',
        description: '',
        defaultMessage: 'URL address',
    },
    CATEGORIES_INVALID_FILE: {
        id: 'categories.invalid_file',
        description: '',
        defaultMessage: 'The category hierarchy file uploaded did not pass validation. Please upload a revised file that adheres to the category hierarchy schema and save the form.',
    },
    CATEGORY_HIERARCHIES: {
        id: 'category_hierarchies',
        description: '',
        defaultMessage: 'Category Hierarchies',
    },
    EDIT_CATEGORY_HIERARCHY: {
        id: 'edit.category_hierarchies',
        description: '',
        defaultMessage: 'Edit Category Hierarchy',
    },
    CREATE_CATEGORY_HIERARCHY: {
        id: 'create.category_hierarchies',
        description: '',
        defaultMessage: 'Create Category Hierarchy',
    },
    DELETE_CATEGORY_HIERARCHY: {
        id: 'delete.category_hierarchies',
        description: '',
        defaultMessage: 'Delete Category Hierarchy',
    },
    CUSTOM_CATEGORY: {
        id: 'custom_category',
        description: '',
        defaultMessage: 'Custom Category',
    },
    CUSTOM_CATEGORY_DESCRIPTION: {
        id: 'custom_category.description',
        description: '',
        defaultMessage: 'The details below are the customized values that will replace the base values.',
    },
    EMPTY_PAGE_TITLE: {
        id: 'empty_page.title',
        description: '',
        defaultMessage: 'Enrich',
    },
    EMPTY_PAGE_DESCRIPTION: {
        id: 'empty_page.description',
        description: '',
        defaultMessage: 'Learn more about Groupby\'s Data Enrichment to improve the quality of your product catalog.',
    },
    EMPTY_PAGE_BOX_TITLE: {
        id: 'empty_page.box.title',
        description: '',
        defaultMessage: 'With Enrich, get rapid attribution at scale with augmented, corrected, and normalized eCommerce product data and review your data before customers see it to ensure accuracy and relevance.',
    },
    EMPTY_PAGE_BOX_DESCRIPTION: {
        id: 'empty_page.box.description',
        description: '',
        defaultMessage: 'Increasing the quality of attributes associated with a single product leads to improved customer experience, lower bounce rates and higher conversion rates, better SEO ranking, product findability, filters & navigation, search metrics, null search reduction and overall a more accurate search experience for your customers and higher revenues for you. Get started with Enrich today!',
    },
    EMPTY_PAGE_BOX_ACTION: {
        id: 'empty_page.box.action',
        description: '',
        defaultMessage: 'Speak to our team',
    },
    IMAGE_PATH: {
        id: 'image_path',
        description: '',
        defaultMessage: 'Image Path Settings',
    },
    IMAGE_PATH_DESCRIPTION: {
        id: 'image_path.description',
        description: '',
        defaultMessage: 'Set up a image path per collection to populate images on the Command Center.',
    },
    IMAGE_PREFIX: {
        id: 'image_prefix',
        description: '',
        defaultMessage: 'Image Prefix',
    },
    IMAGE_ATTRIBUTE: {
        id: 'image_attribute',
        description: '',
        defaultMessage: 'Image Attribute',
    },
    IMAGE_SUFFIX: {
        id: 'image_suffix',
        description: '',
        defaultMessage: 'Image Suffix',
    },
    PREFIX: {
        id: 'prefix',
        description: '',
        defaultMessage: 'Prefix',
    },
    SUFFIX: {
        id: 'suffix',
        description: '',
        defaultMessage: 'Suffix',
    },
    ATTRIBUTE_HELPER_TEXT: {
        id: 'attribute.helper_text',
        description: '',
        defaultMessage: 'eg. variants[0].productImage OR imageURL',
    },
    PREFIX_HELPER_TEXT: {
        id: 'prefix.helper_text',
        description: '',
        defaultMessage: 'eg. http://www.groupbyinc.com/imgs/',
    },
    SUFFIX_HELPER_TEXT: {
        id: 'suffix.helper_text',
        description: '',
        defaultMessage: 'eg. .jpg',
    },
    TASK_UPDATE_IMAGE_PATH_SUCCESS: {
        id: 'task.image_path.update.succes',
        description: '',
        defaultMessage: 'Task "Update image path settings" success',
    },
    TASK_UPDATE_IMAGE_PATH_ERROR: {
        id: 'task.image_path.update.error',
        description: '',
        defaultMessage: 'Task "Update image path settings" failed',
    },
    TASK_IMAGE_PATH_LOAD_ALL_ERROR: {
        id: 'task.image_path.loadall.error',
        description: '',
        defaultMessage: 'Task "Load image path settings" failed',
    },
    IN_STOCK: {
        id: 'in_stock',
        description: '',
        defaultMessage: 'In Stock',
    },
    OUT_OF_STOCK: {
        id: 'out_of_stock',
        description: '',
        defaultMessage: 'Out of Stock',
    },
    HIDE_CATEGORY: {
        id: 'category.hide',
        description: '',
        defaultMessage: 'Hide Category',
    },
    URL: {
        id: 'url',
        description: '',
        defaultMessage: 'Category URL',
    },
    IMAGE_URL: {
        id: 'IMAGE_URL',
        description: '',
        defaultMessage: 'Image URL',
    },
    HIDDEN: {
        id: 'hidden',
        description: '',
        defaultMessage: 'Hidden',
    },
    CATEGORIES_UPLOAD_NEW_HIERARCHY: {
        id: 'categories.upload_hierarchy.new',
        description: '',
        defaultMessage: 'Upload a new category hierarchy',
    },
    CATEGORIES_UPLOAD_HIERARCHY: {
        id: 'categories.upload_hierarchy',
        description: '',
        defaultMessage: 'Upload a category hierarchy',
    },
    CATEGORIES_HIERARCHY_VIEW_EDIT: {
        id: 'categories.view_edit',
        description: '',
        defaultMessage: 'View and edit category hierarchy',
    },
    SET_AS_DEFAULT: {
        id: 'set_as_default',
        description: '',
        defaultMessage: 'Set As Default',
    },
    SET_AS_DEFAULT_TOOLTIP: {
        id: 'set_as_default_tooltip',
        description: '',
        defaultMessage: 'Apply selection and set as the default',
    },
    USER_SETTINGS_UPDATE_SUCCESS: {
        id: 'user_settings_update_success',
        description: '',
        defaultMessage: 'Settings updated successfully',
    },
    USER_SETTINGS_UPDATE_ERROR: {
        id: 'user_settings_update_error',
        description: '',
        defaultMessage: 'Error updating settings',
    },
    DEFAULT: {
        id: 'default',
        description: '',
        defaultMessage: 'Default',
    },
    FIX_ERROR_TO_CONTINUE: {
        id: 'fix_error_to_continue',
        description: '',
        defaultMessage: 'Please fix error to continue',
    },
    DYNAMIC_PREVIEW: {
        id: 'dynamic_preview',
        description: '',
        defaultMessage: 'Dynamic Preview',
    },
    DYNAMIC_PREVIEW_APPLY: {
        id: 'dynamic_preview.apply',
        description: '',
        defaultMessage: 'Apply to preview',
    },
    DYNAMIC_PREVIEW_TRIGGER_TYPE_CANNOT_BE_PREVIEWED: {
        id: 'dynamic_preview.trigger_not_previewed',
        description: '',
        defaultMessage: 'This trigger type cannot be previewed',
    },
    DYNAMIC_PREVIEW_RULE_APPLIED: {
        id: 'dynamic_preview.rule_applied',
        description: '',
        defaultMessage: 'Rule <b>{name}</b> is applied: <highlight>Trigger Set {trigger}</highlight>',
    },
    DYNAMIC_PREVIEW_RULE_RANKING_APPLIED: {
        id: 'dynamic_preview.rule_ranking_applied',
        description: '',
        defaultMessage: 'Rule <b>{name}</b> is applied: <highlight>Ranking</highlight>',
    },
    DYNAMIC_PREVIEW_RULE_RECALL_APPLIED: {
        id: 'dynamic_preview.rule_recall_applied',
        description: '',
        defaultMessage: 'Rule <b>{name}</b> is applied: <highlight>Recall</highlight>',
    },
    DYNAMIC_PREVIEW_TRIGGER_SET_CHANGED: {
        id: 'dynamic_preview.trigger_set_changed',
        description: '',
        defaultMessage: 'Changed: <highlight>Trigger Set {trigger}</highlight>',
    },
    DYNAMIC_PREVIEW_TRIGGER_SET_REMOVED: {
        id: 'dynamic_preview.trigger_set_removed',
        description: '',
        defaultMessage: 'Removed: <highlight>Trigger Set {trigger}</highlight>',
    },
    DYNAMIC_PREVIEW_HISTORY_RANKING: {
        id: 'dynamic_preview.history.ranking',
        description: '',
        defaultMessage: 'Changed: <highlight>Ranking</highlight>',
    },
    DYNAMIC_PREVIEW_HISTORY_RANKING_PINNED_PRODUCT: {
        id: 'dynamic_preview.history.ranking_pinned_product',
        description: '',
        defaultMessage: 'Pinned Product (<subdued>{value}</subdued>) to Position (<subdued>{tier}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_RANKING_PINNED_PRODUCT_REMOVED: {
        id: 'dynamic_preview.history.ranking_pinned_product_removed',
        description: '',
        defaultMessage: 'Pinned Product (<subdued>{value}</subdued>) Removed from Position (<subdued>{tier}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_RANKING_PINNED_PRODUCT_OOS_ON: {
        id: 'dynamic_preview.history.ranking_pinned_product_oos_on',
        description: '',
        defaultMessage: 'Pinned Product (<subdued>{value}</subdued>) remains pinned when out of stock.',
    },
    DYNAMIC_PREVIEW_HISTORY_RANKING_PINNED_PRODUCT_OOS_OFF: {
        id: 'dynamic_preview.history.ranking_pinned_product_oss_off',
        description: '',
        defaultMessage: 'Pinned Product (<subdued>{value}</subdued>) unpinned when out of stock',
    },
    DYNAMIC_PREVIEW_HISTORY_PINNED_PRODUCT_POSITION_TITLE: {
        id: 'dynamic_preview.history.pinned_products_position_title',
        description: '',
        defaultMessage: 'Position (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_BIASING: {
        id: 'dynamic_preview.history.biasing',
        description: '',
        defaultMessage: 'Bias, Biasing strategy (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_PINNED_PRODUCTS_CLEARED: {
        id: 'dynamic_preview.history.pinned_products_cleared',
        description: '',
        defaultMessage: 'Pinned Product Cleared',
    },
    DYNAMIC_PREVIEW_HISTORY_BIAS_CLEARED: {
        id: 'dynamic_preview.history.bias_cleared',
        description: '',
        defaultMessage: 'Bias Cleared',
    },
    DYNAMIC_PREVIEW_HISTORY_TIER: {
        id: 'dynamic_preview.history.tier',
        description: '',
        defaultMessage: 'Product Boosted (<subdued>{value}</subdued>) to Tier (<subdued>{tier}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_BOOSTED_CLEARED: {
        id: 'dynamic_preview.history.product_boosted_cleared',
        description: '',
        defaultMessage: 'Product Boosted Cleared',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_BURIED: {
        id: 'dynamic_preview.history.product_buried',
        description: '',
        defaultMessage: 'Product Buried, Product ID (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_BURIED_CLEARED: {
        id: 'dynamic_preview.history.product_buried_cleared',
        description: '',
        defaultMessage: 'Product Buried Cleared',
    },
    DYNAMIC_PREVIEW_HISTORY_RECALL_CHANGED: {
        id: 'dynamic_preview.history.recall_changed',
        description: '',
        defaultMessage: 'Changed: <highlight>Recall</highlight>',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_INCLUDED: {
        id: 'dynamic_preview.history.product_included',
        description: '',
        defaultMessage: 'Products Included (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_EXCLUDED: {
        id: 'dynamic_preview.history.product_excluded',
        description: '',
        defaultMessage: 'Products Excluded (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_PRODUCT_CLEARED: {
        id: 'dynamic_preview.history.product_cleared',
        description: '',
        defaultMessage: 'Products Filter Cleared',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_INCLUDED: {
        id: 'dynamic_preview.history.attribute_included',
        description: '',
        defaultMessage: 'Attribute Included (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_EXCLUDED: {
        id: 'dynamic_preview.history.attribute_excluded',
        description: '',
        defaultMessage: 'Attribute Excluded (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_INCLUDED_RANGE: {
        id: 'dynamic_preview.history.attribute_included_range',
        description: '',
        defaultMessage: 'Attribute Included, Range (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_EXCLUDED_RANGE: {
        id: 'dynamic_preview.history.attribute_excluded_range',
        description: '',
        defaultMessage: 'Attribute Excluded, Range (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_INCLUDED_VALUE: {
        id: 'dynamic_preview.history.attribute_included_value',
        description: '',
        defaultMessage: 'Attribute Included, Value (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_EXCLUDED_VALUE: {
        id: 'dynamic_preview.history.attribute_excluded_value',
        description: '',
        defaultMessage: 'Attribute Excluded, Value (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_ATTRIBUTE_REMOVED: {
        id: 'dynamic_preview.history.attribute_removed',
        description: '',
        defaultMessage: 'Attribute Filter Removed',
    },
    DYNAMIC_PREVIEW_HISTORY_NAVIGATION_VALUE: {
        id: 'dynamic_preview.history.navigation_value',
        description: '',
        defaultMessage: 'Navigation Value (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_NAVIGATION_RANGE: {
        id: 'dynamic_preview.history.navigation_range',
        description: '',
        defaultMessage: 'Navigation Range (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_SEARCH_CONTAINS: {
        id: 'dynamic_preview.history.search_contains',
        description: '',
        defaultMessage: 'Search Contains (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_SEARCH_STARTS_WITH: {
        id: 'dynamic_preview.history.search_starts_with',
        description: '',
        defaultMessage: 'Search Starts With (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_SEARCH_ENDS_WITH: {
        id: 'dynamic_preview.history.search_ends_with',
        description: '',
        defaultMessage: 'Search Ends With (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_CUSTOM_PARAMETER: {
        id: 'dynamic_preview.history.custom_parameter',
        description: '',
        defaultMessage: 'Custom Parameter (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_SEARCH_MATCHES: {
        id: 'dynamic_preview.history.search_matches',
        description: '',
        defaultMessage: 'Search Matches (<subdued>{value}</subdued>)',
    },
    DYNAMIC_PREVIEW_HISTORY_TITLE: {
        id: 'dynamic_preview.history.title',
        description: '',
        defaultMessage: 'Rule Update History',
    },
    DYNAMIC_PREVIEW_HISTORY_LAST_UPDATED: {
        id: 'dynamic_preview.history.last_updated',
        description: '',
        defaultMessage: 'Last updated, {timeDiff} seconds ago',
    },
    PLACEMENT: {
        id: 'placement',
        description: '',
        defaultMessage: 'Recommendation',
    },
    SHOW_ATTRIBUTES: {
        id: 'show_attributes',
        description: '',
        defaultMessage: 'Show attributes',
    },
    METRICS: {
        id: 'metrics',
        description: '',
        defaultMessage: 'Metrics',
    },
    REVENUE_METRICS: {
        id: 'revenue_metrics',
        description: '',
        defaultMessage: 'Revenue Metrics',
    },
    REVENUE_METRICS_TOOLTIP: {
        id: 'revenue_metrics.tooltip',
        description: '',
        defaultMessage: 'Metrics related to review, revenue, revenue per search and average order value.',
    },
    METRIC_RATES: {
        id: 'metric_rates',
        description: '',
        defaultMessage: 'Metric Rates',
    },
    METRIC_RATES_TOOLTIP: {
        id: 'metric_rates.tooltip',
        description: '',
        defaultMessage: 'Metrics that are a division of other metrics, click through rate, conversion rate and add-to-cart rate.',
    },
    SEARCH_METRICS: {
        id: 'search_metrics',
        description: '',
        defaultMessage: 'Search Metrics',
    },
    SEARCH_JOURNEY: {
        id: 'search_journey',
        description: '',
        defaultMessage: 'Search Journey',
    },
    SEARCH_METRICS_TOOLTIP: {
        id: 'search_metrics.tooltip',
        description: '',
        defaultMessage: 'Sum metrics, Searches, Product Page Views, Add to Carts and Conversions',
    },
    TIME_SERIES_CTR: {
        id: 'time_series_ctr',
        description: '',
        defaultMessage: 'Click Through Rate',
    },
    TIME_SERIES_RPS: {
        id: 'time_series_rps',
        description: '',
        defaultMessage: 'Revenue Per Search',
    },
    TIME_SERIES_AOV: {
        id: 'time_series_aov',
        description: '',
        defaultMessage: 'Average Order Value',
    },
    TIME_SERIES_CVR: {
        id: 'time_series_cvr',
        description: '',
        defaultMessage: 'Conversion Rate',
    },
    TIME_SERIES_CONV: {
        id: 'time_series_conv',
        description: '',
        defaultMessage: 'Conversion',
    },
    TIME_SERIES_ATC: {
        id: 'time_series_atc',
        description: '',
        defaultMessage: 'Add To Cart',
    },
    TIME_SERIES_ATCR: {
        id: 'time_series_atcr',
        description: '',
        defaultMessage: 'Add To Cart Rate',
    },
});
